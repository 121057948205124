/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import CheckboxCell from "../../Checkbox";
import { Button, Small } from "../../Typography";
import { Help } from "@carbon/icons-react";
import styled from "styled-components";

export const RowContainer = styled.tr`
	vertical-align: top;
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-collapse: collapse;

	border-spacing: 0;
	&:first-child {
		border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
	}
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
`;

export const RowCell = styled.td`
	text-align: start;
	padding: 0.25rem;
	&:last-child {
		padding-inline-end: 1.5rem;
	}
`;
export const RowCellTh = styled.th`
	text-align: start;
	&:last-child {
		padding-inline-end: 1.5rem;
	}
`;

export const Checkbox = styled(CheckboxCell)`
	margin-inline-end: 1rem;
	flex-shrink: 0;
`;

export const Unseen = styled.div`
	display: flex;
	width: 0.5rem;
	color: ${({ theme }) => theme.colors.supportError200};
	margin-inline-start: 1rem;
	> svg {
		padding-block-start: 0.3125rem;
	}
`;

export const Text = styled(Small)`
	flex: 1;
`;

export const Wrapper = styled.div`
	display: flex;
	align-content: flex-start;
	padding-block: 0.5rem;
	${Text} {
		margin-inline-start: 0.5rem;
		max-width: 12.0625rem;
		padding-inline-end: 0.5rem;
	}
`;

export const ItemNameRowCell = styled(RowCell)<{ width: number }>`
	width: ${(p) => (p.width ? p.width : 8.375)}rem;
	text-align: start;
	padding-inline-start: 1rem;
	vertical-align: middle;
`;

export const SecondaryInfoRowCell = styled(RowCell)`
	width: 8.375rem;
	color: ${({ theme }) => theme.colors.neutral600};
	${Text} {
		max-width: 8.375rem;
		font-size: 10px;
	}
`;

export const ActionRowCell = styled(RowCell)<{ width: number }>`
	width: ${(p) => (p.width ? p.width : 8.375)}rem;
	vertical-align: middle;
	padding-inline-start: 1rem;
	& > Button {
		padding-inline: 0;
		padding-block: 0;
	}
	${Button} {
		width: ${(p) => (p.width ? p.width - 1 : 8.375)}rem;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: start;
	}
`;

export const HelpRowCell = styled(RowCell)`
	width: 5rem;
	padding-block: 0.6875rem;
	${Text} {
		max-width: 5rem;
	}
`;

export const IconRowCell = styled(RowCell)`
	width: 4.375rem;
	text-align: center;
	padding-block-start: 1.125rem;
	${Text} {
		max-width: 4.375rem;
	}
`;

export const DateRowCell = styled(RowCell)`
	padding-block-start: 1rem;
	width: 10.5625rem;
	text-align: end;
	${Text} {
		max-width: 10.5625rem;
	}
`;
export const SettingsRowCell = styled(RowCell)`
	text-align: end;
	padding-block-start: 0.9375rem;
`;

export const DropdownButtonWrapper = styled.div`
	margin-inline-start: 0.6rem;
`;

export const HelpIcon = styled(Help)`
	color: ${({ theme }) => theme.colors.neutral600};
`;
