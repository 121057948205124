import { Body, Caption, Overline, Small } from "../Typography";
import styled, { css } from "styled-components";

import { $StyledContainerProps } from "./types";

export const Container = styled.div`
	position: relative;
	inset-block-start: 0.625rem;
	padding-block-end: 0;
`;

export const StepContent = styled.div<$StyledContainerProps>`
	display: flex;
	padding-block-end: 0.75rem;

	:before {
		position: absolute;
		z-index: -1;
		height: 100%;
		border-inline-start: 2px solid ${({ theme }) => theme.colors.neutral200};
		content: "";
		inset-inline-start: 0.5625rem;
		inset-block-start: 0.75rem;

		${({ $checked }) =>
			$checked &&
			css`
				height: calc(100% - 0.75rem);
				border-inline-start: 2px solid ${({ theme }) => theme.colors.yellowBee200};
			`}
	}
`;

export const StepWrapper = styled.li<$StyledContainerProps>`
	position: relative;
	:last-of-type {
		${StepContent} {
			:before {
				border: transparent;
			}
		}
	}
`;

export const Status = styled(Small)<$StyledContainerProps>`
	padding-block-end: 0.75rem;
	display: flex;
	align-items: center;
`;

export const Point = styled.div`
	position: relative;
	inset-inline-start: 0.1875rem;
	min-width: 0.875rem;
	height: 0.875rem;
	background-color: ${({ theme }) => theme.colors.yellowBee400};
	border: 4px solid ${({ theme }) => theme.colors.white};
	border-radius: 1.5625rem;
`;

export const StatusInfo = styled.div<$StyledContainerProps>`
	margin-inline-start: 1.25rem;
	padding-inline: 0.75rem;
	padding-block: 0.25rem;
	color: ${({ theme }) => theme.colors.yellowBee800};
	background-color: ${({ theme }) => theme.colors.yellowBee50};
	border-radius: 0.375rem;

	${({ $variant }) => {
		switch ($variant) {
			case "small":
				return css`
					font-size: 0.75rem;
				`;
		}
	}}
`;

export const Circle = styled(Overline)<$StyledContainerProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 1.25rem;
	height: 1.25rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 1.5625rem;
	user-select: none;

	${({ $variant }) => {
		switch ($variant) {
			case "small":
				return css`
					:before {
						inset-block-start: -0.375rem;
						width: 0.125rem;
					}
					inset-inline-start: 0.1875rem;
					min-width: 0.875rem;
					height: 0.875rem;
					color: transparent;
				`;
			case "default-numbers":
				return css`
					color: ${({ theme }) => theme.colors.neutral600};
				`;
			case "default":
			default:
				return css`
					color: transparent;
				`;
		}
	}}

	${({ $checked }) =>
		$checked &&
		css`
			color: ${({ theme }) => theme.colors.yellowBee800};
			background-color: ${({ theme }) => theme.colors.yellowBee200};
			border: 2px solid ${({ theme }) => theme.colors.yellowBee200};

			:before {
				position: absolute;
				inset-block-start: -0.375rem;
				width: 0.125rem;
				border-top: 4px solid ${({ theme }) => theme.colors.white};
				border-bottom: 0;
				content: "";
			}
		`}
`;

export const Step = styled.div<$StyledContainerProps>`
	margin-inline-start: 1.25rem;
	${({ $variant }) => {
		switch ($variant) {
			case "small":
				return css`
					font-size: 0.875rem;
				`;
		}
	}}
`;

export const Title = styled(Body)<$StyledContainerProps>``;

export const Description = styled(Caption)<$StyledContainerProps>`
	margin-block-start: 0.125rem;
	color: ${({ theme }) => theme.colors.neutral500};

	${({ $variant }) => {
		switch ($variant) {
			case "small":
				return css`
					font-size: 0.75rem;
				`;
		}
	}}
`;
