import IconButton from "../../IconButton";
import { Small } from "../../Typography";
import { TextEllipsis } from "../../../styles/mixins";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	width: 100%;
	padding-block: 0.6875rem;
	padding-inline: 0.5rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.375rem;
	transition: background-color 0.2s ease-in-out;

	:hover,
	:active,
	:focus {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
`;

export const IconContainer = styled.div`
	display: flex;
	width: 1rem;
	height: 1rem;
	color: ${({ theme }) => theme.colors.neutral700};
`;

export const Text = styled(Small)`
	flex: 1;
	${TextEllipsis}
`;

export const DeleteButton = styled(IconButton).attrs({ variant: "no-bg" })`
	color: ${({ theme }) => theme.colors.neutral700};
`;
