import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Container, IconContainer } from "./styles";
import type { IconButtonProps } from "./types";
export default function IconButton({
	ariaLabel,
	className,
	disabled,
	icon,
	size,
	variant,
	onClick
}: IconButtonProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container
				aria-label={ariaLabel}
				className={className}
				$disabled={disabled}
				$size={size}
				$variant={variant}
				as="button"
				onClick={onClick}
			>
				<IconContainer>{icon}</IconContainer>
			</Container>
		</ThemeProvider>
	);
}
