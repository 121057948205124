import { Caption, Small } from "../../Typography";
import styled from "styled-components";

export const Container = styled.div`
	width: 15rem;
	height: 6.125rem;
`;

export const Labels = styled(Caption).attrs({
	as: "ul"
})`
	position: absolute;
	li {
		position: relative;
		z-index: 1;
		height: 1.25rem;
		color: ${({ theme }) => theme.colors.neutral600};
		cursor: default;
		margin-block-end: 0.4375rem;
	}
`;

export const WrapperTooltip = styled.div`
	position: absolute;
	margin-block-end: 0.9375rem;
	min-width: 6.25rem;
`;

export const Card = styled.div`
	min-width: 1rem;
	min-height: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const Item = styled(Caption)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Value = styled(Small)``;
