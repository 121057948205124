import styled from "styled-components";
import Button from "../Button";
import { Title } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";

export const Container = styled.div`
	width: 100%;
	min-width: 50.25rem;
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	border-radius: 0.5rem;
`;
export const Content = styled.div`
	${prettyScrollbar({ vertical: false })}
	overflow-x: auto;
`;
export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: start;
	margin-block-end: 1rem;
	padding-inline: 1.5rem;
	margin-top: 1.5rem;
	gap: 0.75rem;
`;
export const MainTitle = styled(Title)`
	font: normal normal 600 16px/24px HK Grotesk;
	letter-spacing: 0px;
	color: #100f16;
`;
export const MainActions = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding-inline-start: 0.85rem;
`;
export const Toolbar = styled.div`
	margin-left: auto; // Pushes the Toolbar to the right
	// display: flex;
	// gap: 0.5rem; // Add some gap between toolbar buttons
	& MuiIconButton-root {
		&:last-child {
			padding-right: 0;
		}
	}
`;

export const AllFiltersButton = styled(Button)`
	flex-shrink: 0;
	margin-inline-start: 0.25rem;
	margin-inline-end: 0.25rem;
	&:first-child {
		margin-inline-start: 0;
	}
	&:last-child {
		margin-inline-end: 0;
	}
`;
export const TableContainer = styled.div`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	.MuiPaper-rounded {
		background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
		box-shadow: none;
		border-radius: 0.5rem;
	}
	.MuiTableRow-head {
		box-shadow: none;
		border-bottom: 1px solid ${({ theme }) => theme.colors.neutral100};
		// border-bottom: 0;
		.MuiTableCell-head {
			border-bottom: 0;
			&:first-child {
				padding-inline-start: 1.5rem;
			}
			&:last-child {
				padding-inline-end: 1.5rem;
			}
			.Mui-TableHeadCell-Content-Wrapper {
				font-family: ${({ theme }) => theme.fonts.main};
				color: ${({ theme }) => theme.colors.neutral900};
			}
			.Mui-TableHeadCell-ResizeHandle-Divider {
				border-width: 1px;
			}
			.MuiInput-underline {
				font-family: ${({ theme }) => theme.fonts.main};
				color: ${({ theme }) => theme.colors.neutral900};
				font: normal normal normal 14px/22px HK Grotesk;
			}
		}
	}
	.MuiTableRow-root {
		&:hover {
			td::after {
				background-color: ${({ theme }) => theme.colors.neutral100} !important;
			}
		}
		.MuiTableCell-body {
			&:first-child {
				padding-inline-start: 1.5rem;
			}
			&:last-child {
				padding-inline-end: 1.5rem;
			}
			font-family: ${({ theme }) => theme.fonts.main};
			color: ${({ theme }) => theme.colors.neutral900};
			border-bottom: 1px solid ${({ theme }) => theme.colors.neutral100};
		}
	}
	.MuiTablePagination-root {
		.MuiInputLabel-root,
		.MuiInputBase-root,
		.MuiPaginationItem-root {
			font-family: ${({ theme }) => theme.fonts.main};
			font: normal normal normal 14px/22px HK Grotesk;
			letter-spacing: 0px;
			color: ${({ theme }) => theme.colors.neutral600};
			.MuiPagination-ul {
				&.Mui-selected,
				&:hover {
					background-color: ${({ theme }) => theme.colors.neutral100};
					border-radius: 6px;
				}
			}
		}
		@media (max-width: 800px) {
			flex-direction: column;
		}
	}
`;

export const PaginationWrapper = styled.div`
	display: flex;
	justify-content: end;
	flex-direction: row;
	padding-inline-start: 1.5rem;
	padding-inline-end: 1.5rem;
	padding: 0.5rem;
	.MuiInputLabel-root,
	.MuiInputBase-root,
	.MuiPaginationItem-root {
		font-family: ${({ theme }) => theme.fonts.main};
		font: normal normal normal 14px/22px HK Grotesk;
		letter-spacing: 0px;
		color: ${({ theme }) => theme.colors.neutral600};
		.MuiPagination-ul {
			&.Mui-selected,
			&:hover {
				background-color: ${({ theme }) => theme.colors.neutral100};
				border-radius: 6px;
			}
		}
	}
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;
