import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Caption, Overline } from "../Typography";

import LineChart from "./LineChart";
import {
	ChartWrapper,
	Container,
	FilterButton,
	FilterRow,
	FilterWrapper,
	Header,
	SelectMulti,
	TimeFilter,
	Title,
	TitleRow
} from "./styles";
import type { SensorDataProps } from "./types";

export default function SensorData({ className, options, current, currentTime, data }: SensorDataProps): JSX.Element {
	const timeFilter = ["1d", "5d", "1m", "6m", "1y", "5y", "max"];

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Header>
					<TitleRow>
						<Title $bold>Sensor Data</Title>
						<SelectMulti small placeholder="Bees" name="bees" options={options} />
					</TitleRow>
					<FilterRow>
						<FilterWrapper>
							<FilterButton $current={current === "overview"}>
								<Caption>Overview</Caption>
							</FilterButton>
							<FilterButton $current={current === "temperature"}>
								<Caption>Temperature</Caption>
							</FilterButton>
							<FilterButton $current={current === "humidity"}>
								<Caption>Humidity</Caption>
							</FilterButton>
						</FilterWrapper>
						<FilterWrapper>
							{timeFilter.map((currentTimeFilter: string, index) => (
								<TimeFilter key={index} $currentTime={currentTime === currentTimeFilter}>
									<Overline>{currentTimeFilter}</Overline>
								</TimeFilter>
							))}
						</FilterWrapper>
					</FilterRow>
				</Header>
				<ChartWrapper>
					<LineChart data={data} />
				</ChartWrapper>
			</Container>
		</ThemeProvider>
	);
}
