import InfoDetail from "../Infobox/InfoDetail";
import { ArrowUpRight, Checkmark, ChevronUp, InformationFilled } from "@carbon/icons-react";

import { useLogic } from "./logic";
import {
	AccordionIconButton,
	ArrowIconButton,
	Circle,
	Container,
	Date,
	IconWrapper,
	InfoDetailWrapper,
	InfoHidden,
	InfoTag,
	LinkButton,
	List,
	Step,
	StepContent,
	StepWrapper,
	TagWrapper,
	Title,
	TitleWrapper
} from "./styles";
import type { HistoryProps, StepProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function History({ steps, iconAriaLabel }: HistoryProps): JSX.Element {
	const { onClickAccordion, getAccordionHidden } = useLogic(steps ? [...steps].map((step) => step.id) : []);
	const stepItem = steps
		? [...steps].reverse().map(({ id, active, title, checked, statusColor, status, color, hasLink }: StepProps) => (
				<StepWrapper key={`step-${id}`} $active={active}>
					<StepContent $checked={checked}>
						<Circle $checked={checked}>{checked ? <Checkmark /> : id}</Circle>
						<Step $active={!getAccordionHidden(id)}>
							<TagWrapper>
								<InfoTag backgroundColor={statusColor} color={color} label={status} small />
								{active ? (
									<IconWrapper $hiddenAccordion={getAccordionHidden(id)}>
										<ArrowIconButton
											size="tiny"
											variant="no-bg"
											// eslint-disable-next-line @typescript-eslint/no-empty-function
											onClick={() => {}}
											ariaLabel="More information"
											icon={<ArrowUpRight size={20} />}
										/>
									</IconWrapper>
								) : null}
							</TagWrapper>
							<TitleWrapper $active={!getAccordionHidden(id)}>
								<Title $bold>{title}</Title>
								<AccordionIconButton
									ariaLabel={iconAriaLabel}
									icon={<ChevronUp />}
									onClick={onClickAccordion(id)}
									size="tiny"
									variant="no-bg"
									$hiddenAccordion={getAccordionHidden(id)}
									id={id.toString()}
								/>
							</TitleWrapper>
							{active ? (
								<InfoHidden hidden={getAccordionHidden(id)}>
									<InfoDetailWrapper>
										<InfoDetail data="origin" detail="Schaffhausen," />
										<Date>08.12.2021 / 15:00</Date>
									</InfoDetailWrapper>
									<InfoDetailWrapper>
										<InfoDetail data="destination" detail="Dortmund, DE" />
										<Date>09.12.2021 / 09:00</Date>
									</InfoDetailWrapper>
									<InfoDetailWrapper>
										<InfoDetail
											data="ETA"
											detail="Dortmund, DE"
											titleIcon={<InformationFilled />}
											tooltipText="More information"
										/>
										<Date>09.12.2021 / 09:00</Date>
									</InfoDetailWrapper>

									{hasLink ? (
										// eslint-disable-next-line @typescript-eslint/no-empty-function
										<LinkButton size="small" variant="no-bg" href="/" label="Force Stop Shipment" onClick={() => {}} />
									) : null}
								</InfoHidden>
							) : null}
						</Step>
					</StepContent>
				</StepWrapper>
		  ))
		: null;

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<List reversed>{stepItem}</List>
			</Container>
		</ThemeProvider>
	);
}
