import { Close, Draggable } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";

import { Container, DeleteButton, IconContainer, Text } from "./styles";
import type { ListItemProps } from "./types";

export default function ListItem({ className, text, onDelete }: ListItemProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<IconContainer>
					<Draggable />
				</IconContainer>
				<Text>{text}</Text>
				<DeleteButton onClick={onDelete} icon={<Close />} size="tiny" ariaLabel="delete element" />
			</Container>
		</ThemeProvider>
	);
}
