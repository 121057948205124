import clamp from "lodash/clamp";

import type { PaginationProps } from "./types";

export default function useLogic({ current, total, showShortcuts = true }: PaginationProps) {
	// 2 3 4 [5] 6 7 8 <--- MAX_PAGES = 7
	//        A
	//        └----- DEFAULT_ACTIVE_POSITION = 3

	const MAX_PAGES = 3;
	const DEFAULT_ACTIVE_POSITION = 1;

	// The number of pages that will be shown. This is typically MAX_PAGES, unless
	// there are fewer, in which case we show every page.
	const pagesToShow = Math.min(MAX_PAGES, total);

	// The number of pages that will be shown after DEFAULT_ACTIVE_POSITION
	// For the example values that would be 3:
	// 2 3 4 [5] 6 7 8 --> 6, 7, 8
	const pagesAfterDefaultPosition = pagesToShow - DEFAULT_ACTIVE_POSITION - 1;

	// The number of pages until we reach the end. 0 means the current page is the
	// last page.
	const pagesToEnd = total - current;

	// The number of pages we need to bring the default position forward.
	// This happens whenever we are getting close to the end. For the example
	// values, assuming that the total is 8, it would vary depending on the
	// current page:
	//
	// representation       | current | total | pagesForward
	// 2  3  4 [5] 6  7  8  |    5    |   8   |      0
	// 2  3  4  5 [6] 7  8  |    6    |   8   |      1
	// 2  3  4  5  6 [7] 8  |    7    |   8   |      2
	// 2  3  4  5  6  7 [8] |    8    |   8   |      3
	const pagesForward = pagesAfterDefaultPosition - clamp(pagesToEnd, 0, pagesAfterDefaultPosition);

	// The position where we will show the current page. This is the
	// DEFAULT_ACTIVE_POSITION, unless we're getting close to the end and need to
	// bring the position forward.
	const showCurrentAt = DEFAULT_ACTIVE_POSITION + pagesForward;

	// The value we will show at the leftmost page.
	// For the example values that would be 2:
	// 2 3 4 [5] 6 7 8
	const initial = Math.max(current - showCurrentAt, 1);

	// The value we will show at the rightmost page.
	// For the example values that would be 8:
	// 2 3 4 [5] 6 7 8
	const last = Math.min(initial + MAX_PAGES - 1, total);

	const showFirstShortcut = showShortcuts && initial > 1;
	const showFirstShortcutEllipsis = initial > 2;

	const showLastShortcut = showShortcuts && last < total;
	const showLastShortcutEllipsis = total - last > 1;

	return {
		showFirstShortcut,
		showFirstShortcutEllipsis,
		showLastShortcut,
		showLastShortcutEllipsis,
		pagesToShow,
		initial
	};
}
