import IconButton from "../IconButton";
import DefaultInputSearchGlobal from "../InputSearchGlobal";
import { H4, Small } from "../Typography";
import { from } from "../../styles/responsive";
import styled from "styled-components";

export const Container = styled.div`
	position: relative;
	display: flex;
	gap: 1.25rem;
	align-items: center;
	width: 100%;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colors.neutral100};

	${from["tabletPortrait"]} {
		gap: 1.5rem;
		height: 64px;
		// padding: 2rem;
		// border-radius: 1rem;
	}
`;

export const Title = styled(H4)`
	display: none;
	padding-inline: 0.5rem;

	${from["mobile"]} {
		display: inline;
		flex: 1;
	}
`;

export const SearchButton = styled(IconButton)`
	margin-inline-end: auto;
`;

export const InputSearchGlobal = styled(DefaultInputSearchGlobal)`
	width: 17.5rem;
`;

export const Notifications = styled.div`
	padding: 0.5rem;
	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
`;

export const ProfileContainer = styled.div`
	display: flex;
	gap: 0.5625rem;
	align-items: center;
`;

export const Name = styled(Small)`
	display: none;

	${from["tabletPortrait"]} {
		display: inline;
	}
`;

export const Avatar = styled.div`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	background-color: ${({ theme }) => theme.colors.yellowBee200};
	border-radius: 50%;
`;

export const Initial = styled(Small)`
	text-transform: capitalize;
`;

export const MenuButton = styled(IconButton).attrs({
	size: "small",
	variant: "no-bg"
})``;
