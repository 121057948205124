import { Warning } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, Error, Helper, IconWrapper, Label, StyledInput } from "./styles";
import type { InputTextProps } from "./types";

export default function InputText({
	autoComplete,
	className,
	disabled,
	error,
	help,
	helpMessage,
	id,
	label,
	name,
	defaultValue,
	onBlur,
	onChange,
	onFocus,
	pattern,
	placeholder,
	readOnly,
	required,
	errorMessage
}: InputTextProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name}>
					{label}
				</Label>
				<StyledInput
					$hasError={!!error}
					id={id}
					name={name}
					defaultValue={defaultValue}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
					pattern={pattern}
					placeholder={placeholder}
					required={required}
					type="text"
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={disabled}
				/>
				{error ? (
					<IconWrapper $hasError={!!error}>
						<Warning />
					</IconWrapper>
				) : null}
				{error ? <Error role="alert">{errorMessage}</Error> : null}
				{help && !error ? <Helper>{helpMessage}</Helper> : null}
			</Container>
		</ThemeProvider>
	);
}
