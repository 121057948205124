import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { FileUploader } from "./FileUploader";
import useLogic from "./logic";
import { Container } from "./styles";
import type { InputFileProps } from "./types";
import { UploadedFilesList } from "./UploadedFilesList";

export default function InputFile({ className, handleFilesUpload, maxFiles, onChange }: InputFileProps) {
	const { currentFiles, isUploading, loadingProgress, onDrop, onDelete } = useLogic({
		handleFilesUpload,
		maxFiles,
		onChange
	});

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{currentFiles.length > 0 ? (
					<UploadedFilesList
						onDrop={onDrop}
						onDelete={onDelete}
						loading={isUploading}
						progress={loadingProgress}
						currentFiles={currentFiles}
					/>
				) : (
					<FileUploader onDrop={onDrop} loading={isUploading} progress={loadingProgress} />
				)}
			</Container>
		</ThemeProvider>
	);
}
