import DefaultButton from "../Button";
import DefaultIconButton from "../IconButton";
import { Small } from "../Typography";
import styled, { css } from "styled-components";

import { PaginationStyleProps } from "./types";

export const Container = styled.nav`
	display: flex;
	gap: 0.75rem;
	align-items: center;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const PageWrapper = styled.div`
	display: flex;
	gap: 0.125rem;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	@media (max-width: 800px) {
		margin-top: -15px;
	}
`;

export const PrevPageButton = styled(DefaultIconButton).attrs({
	variant: "no-bg",
	size: "small"
})``;
export const NextPageButton = styled(DefaultIconButton).attrs({
	variant: "no-bg",
	size: "small"
})``;

const ButtonStyles = css`
	justify-content: center;
	width: 2.25rem;
	color: ${({ theme }) => theme.colors.neutral600};
	background-color: transparent;
	:hover {
		color: ${({ theme }) => theme.colors.neutral900};
	}
	:focus-visible {
		color: ${({ theme }) => theme.colors.neutral900};
		background-color: ${({ theme }) => theme.colors.neutral100};
		outline-color: ${({ theme }) => theme.colors.neutral200};
	}
`;

export const Page = styled(DefaultButton).attrs({
	variant: "secondary",
	size: "small"
})<PaginationStyleProps>`
	${ButtonStyles}
	${({ $active }) =>
		$active &&
		css`
			color: ${({ theme }) => theme.colors.neutral900};
			background-color: ${({ theme }) => theme.colors.neutral100};
		`}
    :hover {
		color: ${({ theme }) => theme.colors.neutral900};
		background-color: ${({ theme }) => theme.colors.white};
	}
`;

export const Ellipsis = styled(Small)`
	min-width: 2.25rem;
	padding-inline: 0.75rem;
	padding-block-start: 0.4375rem;
	color: ${({ theme }) => theme.colors.neutral600};
	background-color: transparent;
`;
