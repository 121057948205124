import styled, { css } from "styled-components";

import { StyledContainerProps } from "./types";

export const TabLabel = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.neutral600};
	white-space: nowrap;
	margin-inline: 0.5rem;
`;

export const Container = styled.button<StyledContainerProps>`
	position: relative;
	display: flex;
	align-items: center;
	width: auto;
	height: 100%;
	padding-block: 0.5rem 1rem;
	background-color: none;
	border-block-end: 2px solid transparent;
	cursor: pointer;
	transition: border-color 200ms ease-out;

	&:hover,
	&:focus {
		${TabLabel} {
			color: ${({ theme }) => theme.colors.neutral900};
		}
	}
	${({ $active }) =>
		$active &&
		css`
			border-color: ${({ theme }) => theme.colors.neutral900};
			${TabLabel} {
				color: ${({ theme }) => theme.colors.neutral900};
			}
		`}
`;
