import DefaultButton from "../../Button";
import { Small } from "../../Typography";
import { visuallyHidden } from "../../../styles/mixins";
import styled, { css } from "styled-components";

import { AlertColorProps } from "./types";

export const IconContainer = styled.div<AlertColorProps>`
	display: flex;
	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
	${({ $iconColor }) =>
		$iconColor &&
		css`
			color: ${$iconColor};
		`}
`;

export const DismissButton = styled(DefaultButton).attrs({
	variant: "no-bg",
	size: "small"
})`
	max-height: 1.25rem;
	padding-block: 0;
	padding-inline: 0;
`;

export const Container = styled.li`
	position: relative;
	padding-inline: 1rem;
	padding-block: 0.75rem;
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	&:focus-within,
	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral100};
		${IconContainer} {
			display: none;
		}
	}

	&:not(:hover) {
		${DismissButton} {
			&:not(:focus-visible) {
				${visuallyHidden}
			}
		}
	}
`;

export const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Title = styled(Small)``;

export const Info = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
	:first-of-type {
		color: ${({ theme }) => theme.colors.neutral900};
	}
`;
