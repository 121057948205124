import { CheckmarkFilled } from "@carbon/icons-react";

import { CheckContainer, Container, Detail, IconContainer, Link, Title, TitleIconButton } from "./styles";
import type { InfoDetailProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import Tooltip from "../../Tooltip";

export default function InfoDetail({
	className,
	data,
	detail,
	icon,
	titleIcon,
	href,
	isChecked,
	iconColor,
	tooltipText
}: InfoDetailProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Title $bold>
					{data}
					{titleIcon ? (
						<Tooltip trigger="click" offset="-1rem" text={tooltipText}>
							<IconContainer $iconColor={iconColor}>
								<TitleIconButton
									size="small"
									variant="no-bg"
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onClick={() => {}}
									ariaLabel="More information"
									icon={titleIcon}
								/>
							</IconContainer>
						</Tooltip>
					) : null}
				</Title>

				<Detail $isChecked={isChecked}>
					{href ? <Link href={href} label={detail} /> : <>{detail}</>}
					{icon ? (
						<IconContainer $iconColor={iconColor} aria-hidden>
							{icon}
						</IconContainer>
					) : null}
					{isChecked ? <CheckContainer>{<CheckmarkFilled aria-label="Checked" />}</CheckContainer> : null}
				</Detail>
			</Container>
		</ThemeProvider>
	);
}
