import Button from "../../Button";
import { Body, Small } from "../../Typography";
import { Add, TrashCan } from "@carbon/icons-react";
import { useDropzone } from "react-dropzone";

import { Container, DropContent, IconButton, LoadingContainer, ProgressBar, Row, Wrapper } from "./styles";
import type { UploadedFilesListProps } from "./types";

export function UploadedFilesList({
	className,
	onDrop,
	onDelete,
	loading,
	progress = 0,
	currentFiles = []
}: UploadedFilesListProps) {
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop
	});
	return (
		<Container className={className}>
			<Wrapper isDragAccept={isDragAccept} isDragActive={isDragActive} isDragReject={isDragReject}>
				{currentFiles.map((file) => (
					<Row key={file}>
						<Body>{file}</Body>
						<IconButton
							icon={<TrashCan />}
							ariaLabel="delete"
							variant="no-bg"
							size="default"
							onClick={() => {
								onDelete(file);
							}}
						/>
					</Row>
				))}
				{loading ? (
					<LoadingContainer>
						<Small>Uploading...</Small>
						<ProgressBar value={progress} />
					</LoadingContainer>
				) : (
					<DropContent {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
						<input {...getInputProps()} />
						<Button label="Add new file" variant="no-bg" icon={<Add />} />
					</DropContent>
				)}
			</Wrapper>
		</Container>
	);
}
