import { ThemeProvider } from "styled-components";
import { useEffect, useRef } from "react";
import * as mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { MapWrapper } from "./styles";
import { MapProps } from "./types";
import { addCircleGeoMarker } from "./logic";

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;
const defaultCenter: [number, number] = [-74.0, 40.75]; // default center

export default function Map({ center = defaultCenter, zoom = 12 }: MapProps): JSX.Element {
	const mapContainer = useRef(null);
	const map = useRef<mapboxgl.Map | null>(null);

	useEffect(() => {
		if (map.current) return;

		try {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: process.env.MAPBOX_STYLE,
				center: center,
				zoom: zoom,
				attributionControl: false
			});

			map.current.on("load", () => {
				addCircleGeoMarker({
					map: map.current,
					coordinates: center
				});
			});
		} catch (error) {
			// Handle map initialization errors here
			console.error("Error initializing Mapbox GL:", error);
		}

		// Cleanup on component unmount
		return () => {
			if (map.current) {
				map.current.remove(); // Remove the map instance
			}
		};
	}, [zoom, center]);

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<MapWrapper ref={mapContainer}></MapWrapper>
		</ThemeProvider>
	);
}
