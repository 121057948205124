import { Checkmark } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Circle, Container, Description, Point, Status, StatusInfo, Step, StepContent, StepWrapper } from "./styles";
import type { ProgressIndicatorProps, StepProps } from "./types";
export default function ProgressIndicator({ className, steps, variant }: ProgressIndicatorProps): JSX.Element {
	const stepItem = steps
		? [...steps].reverse().map(({ id, description, active, status, checked }: StepProps) => (
				<StepWrapper key={`step-${id}`} $active={active}>
					{active && (
						<Status>
							<Point />
							<StatusInfo $variant={variant}>{status}</StatusInfo>
						</Status>
					)}

					<StepContent $checked={checked}>
						<Circle $checked={checked} $variant={variant}>
							{checked ? variant !== "small" && <Checkmark /> : id}
						</Circle>
						<Step $variant={variant}>
							{`Step ${id}`}
							<Description $variant={variant}>{description}</Description>
						</Step>
					</StepContent>
				</StepWrapper>
		  ))
		: null;

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<ol reversed>{stepItem}</ol>
			</Container>
		</ThemeProvider>
	);
}
