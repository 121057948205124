import Checkbox from "../../assets/icons/Checkbox.svg";
import CheckboxChecked from "../../assets/icons/CheckboxChecked.svg";
import { useState } from "react";

import { Chevron, Container, Count, Label, Option, RenderArea, StyledCheckbox, StyledSelect, Text } from "./styles";
import type { SelectMultiProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function SelectMulti({
	className,
	disabled,
	name,
	options,
	placeholder,
	label,
	small
}: SelectMultiProps): JSX.Element {
	const [arrayValue, setArrayValue] = useState<string[]>([]);
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label ? (
					<Label $bold as="label" htmlFor={name} $isDisabled={!!disabled}>
						{label}
					</Label>
				) : null}
				<StyledSelect
					$isSmall={small}
					defaultValue={placeholder}
					value={arrayValue}
					id={name}
					multiple
					onChange={(e) => {
						setArrayValue(e.target.value as string[]);
					}}
					disabled={disabled}
					IconComponent={Chevron}
					MenuProps={{
						disablePortal: true,
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						},
						children: false
					}}
					renderValue={() =>
						arrayValue.length > 1 ? (
							<RenderArea $isSmall={small}>
								<Text $isSmall={small}>{name}</Text>
								<Count $isSmall={small}>{arrayValue.length}</Count>
							</RenderArea>
						) : (
							arrayValue[0]
						)
					}
				>
					{options?.map(({ label: optionLabel, value: optionValue, disabled: disabledOption }) => (
						<Option $isSmall={small} key={optionValue} value={optionValue} disabled={disabledOption}>
							<StyledCheckbox
								icon={<Checkbox />}
								checkedIcon={<CheckboxChecked />}
								checked={arrayValue.includes(optionValue)}
							/>
							{optionLabel}
						</Option>
					))}
				</StyledSelect>
			</Container>
		</ThemeProvider>
	);
}
