/* eslint-disable @typescript-eslint/no-unused-vars */
import Checkbox from "../../assets/icons/Checkbox.svg";
import CheckboxChecked from "../../assets/icons/CheckboxChecked.svg";
import { Close } from "@carbon/icons-react";

import useLogic from "./logic";
import { Chevron, ClearAllButton, Container, EllipseTag, InputSearch, InputSearchContainer, Label, NoResults, Option, RenderArea, SearchIcon, StyledCheckbox, StyledSelect, Tag, TagList, Text } from "./styles";
import type { SelectCheckboxesSearchProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function SelectCheckboxesSearch({ className, disabled, name, options, placeholder, label, value, handleOptionSelection }: SelectCheckboxesSearchProps): JSX.Element {
	const { arrayValue, setArrayValue, filteredOptions, setFilteredOptions, shownTags, tagsListRef, clearAll } = useLogic(options, value);

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name} $isDisabled={!!disabled}>
					{label}
				</Label>
				<StyledSelect
					id={name}
					multiple
					defaultValue={placeholder}
					value={arrayValue}
					renderValue={() => (
						<RenderArea>
							<TagList ref={tagsListRef}>
								{(shownTags ? arrayValue.slice(0, shownTags) : arrayValue).map((v) => (
									<Tag
										containerAs="li"
										key={v}
										label={v}
										hasCloseButton
										onClose={() => {
											const newArrayValue = arrayValue.filter((val) => val !== v);
											setArrayValue(newArrayValue);
										}}
									/>
								))}
								{shownTags ? <EllipseTag label="..." /> : null}
							</TagList>
							{arrayValue.length > shownTags ? (
								<ClearAllButton onClick={clearAll}>
									<Close />
								</ClearAllButton>
							) : null}
						</RenderArea>
					)}
					onChange={(e) => {
						if (!(e.target.value as string[]).includes("searchinputfield")) {
							setArrayValue(e.target.value as string[]);
							const value = e.target.value as string[];
							handleOptionSelection(value);
						}
					}}
					disabled={disabled}
					IconComponent={Chevron}
					MenuProps={{
						disablePortal: true,
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						}
					}}
				>
					<InputSearchContainer
						value="searchinputfield"
						//keeps focus on search input field
						onKeyDown={(e) => e.stopPropagation()}
					>
						<SearchIcon />
						<InputSearch
							placeholder="Search..."
							onChange={(e) => {
								setFilteredOptions(options.filter((o) => o.label.toLowerCase().includes(e.target.value.toLowerCase())));
							}}
						/>
					</InputSearchContainer>
					{filteredOptions.length > 0 ? (
						filteredOptions.map(({ label: optionLabel, value: optionValue, disabled: disabledOption }) => (
							<Option key={optionValue} value={optionValue} disabled={disabledOption}>
								{/* <StyledCheckbox icon={<Checkbox />} checkedIcon={<CheckboxChecked />} checked={arrayValue.includes(optionValue)} /> */}
								<StyledCheckbox checked={arrayValue.includes(optionValue)} />
								<Text primary={optionLabel} />
							</Option>
						))
					) : (
						<Option disabled>
							<NoResults>No options available</NoResults>
						</Option>
					)}
				</StyledSelect>
			</Container>
		</ThemeProvider>
	);
}
