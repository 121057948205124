import { forwardRef } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, Label, Slider, Toggle, Wrapper } from "./styles";
import type { Props } from "./types";

export const Switch = forwardRef<HTMLInputElement, Props>(function Switch(
	{ className, checked, disabled, name, id, label, hiddenLabel, onChange },
	ref
) {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Wrapper>
				<Container className={className}>
					<Toggle
						type="checkbox"
						defaultChecked={checked}
						disabled={disabled}
						name={name}
						id={id}
						onChange={onChange}
						ref={ref}
					/>
					<Slider aria-checked={checked} aria-label={label} aria-disabled={disabled} role="switch" />
				</Container>
				<Label $visuallyHidden={!!hiddenLabel} htmlFor={name}>
					{label}
				</Label>
			</Wrapper>
		</ThemeProvider>
	);
});

export default Switch;
