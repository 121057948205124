import styled from "styled-components";

import DefaultButton from "../../Button";

export const InfoWindowHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block-end: 1rem;
`;

export const InfoWindowActionButton = styled(DefaultButton).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	gap: 0;
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: transparent;
	border: none;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
`;

export const InfoWindowFooter = styled.div``;
