import { Small } from "../Typography";
import styled, { css } from "styled-components";

import { StylesProps } from "./types";

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
	// min-height: 6.5rem;
`;

export const Label = styled(Small)``;

export const StyledInput = styled.input<StylesProps>`
	width: 100%;
	font-size: 1rem;
	// line-height: 1.5rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;
	padding-block: 0.5rem;
	padding-inline: 0.75rem 2.5rem;
	::-ms-reveal {
		display: none;
	}

	::placeholder {
		color: ${({ theme }) => theme.colors.neutral600};
	}

	:focus {
		border-color: ${({ theme }) => theme.colors.neutral900};
		outline: none;
	}

	:disabled {
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-color: ${({ theme }) => theme.colors.neutral300};
		cursor: not-allowed;
		::placeholder {
			color: ${({ theme }) => theme.colors.neutral400};
		}
	}

	${({ theme, $hasError }) =>
		$hasError &&
		css`
			border-color: ${theme.colors.supportError100} !important;
			padding-inline-end: 2.5rem;
		`}
`;

export const ToggleButton = styled.button<StylesProps>`
	position: absolute;
	width: 1rem;
	height: 1rem;
	inset-inline-end: 0.75rem;
	inset-block-start: 2.45rem;
	:disabled {
		color: ${({ theme }) => theme.colors.neutral400};
		cursor: not-allowed;
	}
	${({ theme, $hasError }) =>
		$hasError &&
		css`
			svg {
				color: ${theme.colors.supportError100};
			}
		`}
`;

export const Error = styled(Small)`
	color: ${({ theme }) => theme.colors.supportError200};
`;

export const Helper = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;
