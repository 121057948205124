import DefaultSelectMulti from "../SelectMulti";
import { Body } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { from } from "../../styles/responsive";
import { addAlpha } from "../../utils/addAlpha";
import styled, { css } from "styled-components";

import { $FilterProps, $TimeFilterProps } from "./types";

export const Container = styled.div`
	padding-inline: 1rem;
	padding-block: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const ChartWrapper = styled.div`
	width: 100%;
	${prettyScrollbar({ vertical: false })}
	overflow-x: auto;
	overflow-y: hidden;
`;

export const Header = styled.div`
	margin-block-end: 1.5rem;
`;

export const TitleRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-block-end: 1.5rem;
	width: 100%;

	${from["tabletPortrait"]} {
		flex-direction: row;
		align-items: center;
	}
`;

export const Title = styled(Body)`
	min-width: 5.6875rem;
`;

export const SelectMulti = styled(DefaultSelectMulti)`
	width: 100%;
	min-width: 10rem;
`;

export const FilterRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	${from["tabletPortrait"]} {
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
		justify-content: space-between;
	}
`;

export const FilterWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	align-items: center;
`;

export const FilterButton = styled.button<$FilterProps>`
	padding-inline: 0.625rem;
	padding-block: 0.125rem;
	background-color: ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.25rem;
	transition: background-color 0.2s ease-in-out;

	&:focus-within,
	&:hover {
		background-color: ${({ theme }) => addAlpha(theme.colors.neutral200, 0.5)};
	}

	${({ $current }) =>
		$current &&
		css`
			color: ${({ theme }) => theme.colors.white};
			background-color: ${({ theme }) => theme.colors.neutral900};

			&:focus-within,
			&:hover {
				background-color: ${({ theme }) => addAlpha(theme.colors.neutral900, 0.8)};
			}
		`}
`;

export const TimeFilter = styled.button<$TimeFilterProps>`
	color: ${({ theme }) => theme.colors.neutral600};
	text-transform: captions;

	&:focus-within,
	&:hover {
		color: ${({ theme }) => theme.colors.neutral900};
	}

	${({ $currentTime }) =>
		$currentTime &&
		css`
			color: ${({ theme }) => theme.colors.neutral900};
			font-weight: bold;
		`}
`;
