import { visuallyHidden } from "../../styles/mixins";
import styled from "styled-components";

export const Wrapper = styled.div`
	display: inline-flex;
	align-items: center;
	column-gap: 1ch;
`;

export const Container = styled.label`
	position: relative;
	display: inline-grid;
	width: 2.25rem;
	height: 1.25rem;
`;

export const Toggle = styled.input`
	inset-block-start: -624.9375rem;
	inset-inline-start: -624.9375rem;
	position: absolute;
`;

export const Slider = styled.span`
	position: relative;
	display: flex;
	max-width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.75rem;
	cursor: pointer;
	transition: background-color 0.2s;

	@media screen and (prefers-reduced-motion: reduce) {
		&:before {
			position: absolute;
			background: ${({ theme }) => theme.colors.white};
			border-radius: 50%;
			transform: translateY(-50%);
			transition: none;
			content: "";
			inset-block-start: 50%;
			inset-inline-start: 0.1875rem;
			padding-block-start: 38.89%;
			padding-inline-start: 38.89%;
		}
	}

	&:before {
		position: absolute;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 50%;
		transform: translateY(-50%);
		transition: transform 0.2s ease;
		content: "";
		inset-block-start: 50%;
		inset-inline-start: 0.1875rem;
		padding-block-start: 38.89%;
		padding-inline-start: 38.89%;
	}

	${Toggle}:checked + & {
		background-color: ${({ theme }) => theme.colors.neutral900};
		&:before {
			transform: translate(calc(100% + 0.125rem), -50%);
		}
	}

	${Toggle}:not(:disabled):hover + & {
		background-color: ${({ theme }) => theme.colors.neutral500};
	}

	${Toggle}:disabled + & {
		cursor: default;
		opacity: 0.36;
	}

	@media (prefers-reduced-motion) {
		transition: none;
	}
`;

export const Label = styled.label<{ $visuallyHidden: boolean }>`
	${({ $visuallyHidden }) => ($visuallyHidden ? visuallyHidden : ``)}
`;
