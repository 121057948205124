import Button from "../Button";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine, DatumValue } from "@nivo/line";

import {
	Bold,
	ButtonNoBg,
	Card,
	ChartWrapper,
	Container,
	Date,
	Header,
	Highlight,
	Point,
	Title,
	Value,
	WrapperToolTip
} from "./styles";
import type { LineChartProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function LineChart({ className, data }: LineChartProps): JSX.Element {
	const dataTicks: {
		x?: DatumValue;
		y?: DatumValue;
	}[] = data?.[0].data;

	const firstDate: DatumValue = dataTicks[0].x;
	const lastDate: DatumValue = dataTicks[dataTicks.length - 1].x;
	const dateRange: DatumValue[] = [firstDate, lastDate];

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Header>
					<Title>
						The number of assets with <Highlight>temperature deviations</Highlight> at the location{" "}
						<Bold $bold>“Munich, DE”</Bold> has increased drastically.
					</Title>
				</Header>
				<ChartWrapper>
					<ResponsiveLine
						data={data}
						margin={{
							top: 10,
							right: 30,
							bottom: 52,
							left: 55
						}}
						yScale={{
							type: "linear",
							stacked: false,
							min: 0,
							max: "auto"
						}}
						xScale={{
							type: "point"
						}}
						enableGridX={false}
						isInteractive={true}
						gridYValues={2}
						axisLeft={{
							tickValues: 2,
							tickSize: 25,
							tickPadding: 12,
							format: (value: number) => `${value}ºC`
						}}
						axisRight={{
							tickValues: 2,
							tickSize: 25,
							tickPadding: 12
						}}
						axisBottom={{
							tickValues: dateRange,
							tickSize: 0,
							tickPadding: 15
						}}
						colors={"#6245D9"}
						tooltip={({
							point: {
								data: { xFormatted, yFormatted }
							}
						}) => (
							<WrapperToolTip>
								<Card>
									<Value $bold>{`${yFormatted as string}ºC`}</Value>
									<Date>{xFormatted as string}</Date>
								</Card>
								<Point />
							</WrapperToolTip>
						)}
						animate={false}
						enableArea={true}
						defs={[
							linearGradientDef("gradientA", [
								{ offset: 0, color: "inherit" },
								{ offset: 100, color: "inherit", opacity: 0 }
							])
						]}
						fill={[{ match: "*", id: "gradientA" }]}
						theme={{
							textColor: "#908E9A",
							fontSize: 10,
							grid: {
								line: {
									stroke: "#E8E7ED",
									strokeWidth: 1
								}
							},
							axis: {
								ticks: {
									line: {
										stroke: "#E8E7ED",
										strokeWidth: 1
									}
								}
							}
						}}
						enableCrosshair={false}
						enablePoints={false}
						pointSize={10}
						pointColor={"#6245D9"}
						pointBorderWidth={2}
						pointBorderColor={"white"}
						useMesh={true}
					/>
				</ChartWrapper>
				<ButtonNoBg>
					<Button label="View location" variant="no-bg" size="small" />
				</ButtonNoBg>
			</Container>
		</ThemeProvider>
	);
}
