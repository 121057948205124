import { Marker, Popup } from "mapbox-gl";
import ReactDOM from "react-dom";

import { CircleGeoMarkerProps, MarkerProps } from "./types";
import InfoWindow from "./InfoWindow";

export const addGeoMarker = ({ map, coordinates, label }: MarkerProps) => {
	const markerOptions = {};

	// Create and add the marker directly without assigning it to a variable
	new Marker(markerOptions).setLngLat(coordinates).addTo(map);

	// Add a popup with a label to the marker
	new Popup()
		.setLngLat(coordinates)
		.setHTML(label || "")
		.addTo(map);
};

export function addCircleGeoMarker({ map, coordinates }: CircleGeoMarkerProps): void {
	map.addLayer({
		id: "circle-marker-hover",
		type: "circle",
		source: {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: coordinates
						},
						properties: {
							id: "hover-marker"
						}
					}
				]
			}
		},
		paint: {
			"circle-radius": 14,
			"circle-color": "#FEC048",
			"circle-opacity": 0.3
		},
		filter: ["==", "id", ""]
	});

	map.addLayer({
		id: "circle-marker-default",
		type: "circle",
		source: {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: coordinates
						},
						properties: {
							id: "default-marker",
							name: "Marker Name",
							description: "This is the circle marker."
						}
					}
				]
			}
		},
		paint: {
			"circle-radius": 5,
			"circle-color": "#FEC048",
			"circle-stroke-width": 1.5,
			"circle-stroke-color": "#FFFFFF"
		}
	});

	let animationFrameId: number | null = null;
	let hoverCircleRadius = 6.5;

	function animateHoverCircle() {
		if (!animationFrameId) return;
		if (hoverCircleRadius < 14) {
			hoverCircleRadius += 1;
			map.setPaintProperty("circle-marker-hover", "circle-radius", hoverCircleRadius);
			animationFrameId = requestAnimationFrame(animateHoverCircle);
		}
	}

	map.on("mouseenter", "circle-marker-default", () => {
		map.setFilter("circle-marker-hover", ["==", "id", "hover-marker"]);
		map.getCanvas().style.cursor = "pointer";
		hoverCircleRadius = 6.5;
		animationFrameId = requestAnimationFrame(animateHoverCircle);
	});

	map.on("mouseleave", "circle-marker-default", () => {
		if (animationFrameId) {
			cancelAnimationFrame(animationFrameId);
			animationFrameId = null;
		}
		map.setFilter("circle-marker-hover", ["==", "id", ""]);
		map.setPaintProperty("circle-marker-hover", "circle-radius", 6.5);
		map.getCanvas().style.cursor = "";
	});

	const popup = new Popup({
		closeButton: false,
		closeOnClick: false
	});

	map.on("click", "circle-marker-default", (e) => {
		const clickedFeature = e.features[0];
		if (clickedFeature && clickedFeature.properties) {
			// const id = clickedFeature.properties.id;
			// console.log(`Circle with ID ${id} was clicked.`);

			const description = clickedFeature.properties.description;
			const name = clickedFeature.properties.name;

			const popupContainer = document.createElement("div");
			ReactDOM.render(
				<InfoWindow
					name={name}
					description={description}
					onClose={() => {
						popup.remove();
					}}
				/>,
				popupContainer
			);

			popup.setLngLat(coordinates).setDOMContent(popupContainer).addTo(map);
		}
	});
}
