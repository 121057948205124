import { memo, useCallback } from "react";
import { toast } from "react-hot-toast";

import { CloseButton, CloseIcon, Container, IconContainer, Text, Wrapper } from "./styles";
import type { Props } from "./types";

function Toast({ ariaLabel, className, id, leftIcon = false, message, onClick, icon, variant = "successful" }: Props): JSX.Element {
	const onClickClose = useCallback(
		(onClickDismiss?: () => void) => {
			if (onClickDismiss) {
				onClickDismiss();
				toast.dismiss(id);
			}
		},
		[id]
	);
	return (
		<Container className={className} $variant={variant} id={id} $leftIcon={leftIcon}>
			<Wrapper>
				{leftIcon ? <IconContainer>{icon}</IconContainer> : null}
				<Text>{message}</Text>
			</Wrapper>
			<CloseButton onClick={() => onClickClose(onClick)} aria-label={ariaLabel}>
				<CloseIcon size={20} onClick={() => toast.dismiss(id)} />
			</CloseButton>
		</Container>
	);
}
export default memo(Toast);
