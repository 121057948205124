import { Small } from "../Typography";
import styled, { css } from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const Text = styled(Small)`
	margin-inline-end: 1rem;
	width: auto;
`;

export const DefaultProgressStyle = css`
	background-color: ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.3125rem;
`;

export const WebkitMozProgressStyle = css`
	background-color: ${({ theme }) => theme.colors.yellowBee200};
	border-radius: 0.3125rem;
`;

export const Progress = styled.progress`
	height: 0.25rem;
	border: none;
	appearance: none;
	${DefaultProgressStyle}

	&::-webkit-progress-bar {
		${DefaultProgressStyle}
		-webkit-appearance: none;
	}
	&::-ms-fill {
		${DefaultProgressStyle}
	}

	&::-moz-progress-bar {
		${WebkitMozProgressStyle}
		-moz-appearance: none;
	}

	&::-webkit-progress-value {
		${WebkitMozProgressStyle}
		transition: width 0.6s ease-in-out;
	}
`;
