import Button from "../Button";
import { Body, H3, Small } from "../Typography";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 17rem;
	padding-inline: 1rem;
	padding-block: 1rem;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	margin-block-end: 1rem;
`;

export const Heading = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-block-end: 1rem;
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const Title = styled(Body)``;

export const Percentage = styled(H3).attrs({ as: "h3" })``;

export const InfoWrapper = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 1.375rem;
`;

export const Info = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const ButtonLink = styled(Button).attrs({ size: "small" })`
	padding: 0;
`;

export const SecondColumn = styled(Small)`
	align-self: flex-start;
	padding-block-start: 0;
`;
