import { useCallback, useState } from "react";

import type { InputFileProps } from "./types";

function* load() {
	yield new Promise<number>((resolve) => {
		setTimeout(() => {
			resolve(20);
		}, 500);
	});
	yield new Promise<number>((resolve) => {
		setTimeout(() => {
			resolve(75);
		}, 500);
	});
	yield new Promise<number>((resolve) => {
		setTimeout(() => {
			resolve(100);
		}, 500);
	});
}

export default function useLogic({ handleFilesUpload, maxFiles, onChange }: InputFileProps) {
	const [currentFiles, setCurrentFiles] = useState<string[]>([]);
	const [isUploading, setIsUploading] = useState(false);
	const [loadingProgress, setLoadingProgress] = useState(0);

	const handleAcceptedFiles = useCallback(
		async (acceptedFiles: File[]) => {
			try {
				setIsUploading(true);

				const loadSequence = load();
				for await (const progress of loadSequence) {
					setLoadingProgress(progress);
				}
				const fileUrls = await handleFilesUpload(acceptedFiles);

				let files: string[] = [];

				if (currentFiles.length > 0) {
					let newFileUrlsCollection = [...currentFiles, ...fileUrls];
					if (maxFiles !== undefined && newFileUrlsCollection.length > maxFiles) {
						newFileUrlsCollection = newFileUrlsCollection.slice(-maxFiles);
					}
					files = newFileUrlsCollection;
				} else {
					files = maxFiles !== undefined ? fileUrls.slice(0, maxFiles) : fileUrls;
				}

				setCurrentFiles(files);

				if (onChange) {
					onChange(files);
				}
			} finally {
				setIsUploading(false);
			}
		},
		[currentFiles, handleFilesUpload, maxFiles, onChange]
	);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			handleAcceptedFiles(acceptedFiles);
		},
		[handleAcceptedFiles]
	);

	const onDelete = useCallback((file: string) => {
		setCurrentFiles((previousCurrentFiles) =>
			previousCurrentFiles.filter((previousCurrentFile) => previousCurrentFile !== file)
		);
	}, []);

	return {
		currentFiles,
		isUploading,
		loadingProgress,
		onDrop,
		onDelete
	};
}
