import { ProgressCircle } from "../../ProgressCircle";
import { Body } from "../../Typography";
import { DocumentAttachment } from "@carbon/icons-react";
import { useDropzone } from "react-dropzone";

import {
	Container,
	DropContent,
	ErrorMessage,
	IconWrapper,
	LoadingContainer,
	TextWrapper,
	Underlined,
	Wrapper
} from "./styles";
import type { FileUploaderProps } from "./types";

export function FileUploader({ className, onDrop, loading, progress }: FileUploaderProps) {
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop
	});

	return (
		<Container className={className}>
			<DropContent {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
				<input {...getInputProps()} />
				{loading ? (
					<LoadingContainer>
						<ProgressCircle progress={progress} />
					</LoadingContainer>
				) : (
					<Wrapper>
						<IconWrapper>
							<DocumentAttachment />
						</IconWrapper>
						<TextWrapper>
							<Body>
								Drag files here or
								<Underlined>browse</Underlined>
							</Body>
						</TextWrapper>
					</Wrapper>
				)}
			</DropContent>
			{isDragReject ? <ErrorMessage>Invalid file extension</ErrorMessage> : null}
		</Container>
	);
}
