import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { AutocompleteElement, Chevron, Container, Dropdown, Input, Label } from "./styles";
import type { SelectCategoryProps } from "./types";

export default function SelectCategory({
	className,
	label,
	id,
	selectOptions,
	disabled
}: SelectCategoryProps): JSX.Element {
	const options = selectOptions.map((option) => {
		const firstLetter = option.charAt(0).toUpperCase();
		return {
			category: `Category ${firstLetter}`,
			option
		};
	});

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={id} $isDisabled={disabled}>
					{label}
				</Label>
				<AutocompleteElement
					disableClearable
					disabled={disabled}
					$isDisabled={disabled}
					id={id}
					popupIcon={<Chevron />}
					noOptionsText={"No options available"}
					renderInput={(params) => <Input placeholder="Select" {...params} />}
					PaperComponent={({ children }) => <Dropdown>{children}</Dropdown>}
					options={options.sort((a, b) => -b.category.localeCompare(a.category))}
					groupBy={(option) =>
						(
							option as {
								category: string;
								option: string;
							}
						).category
					}
					getOptionLabel={(option) =>
						(
							option as {
								category: string;
								option: string;
							}
						).option
					}
				/>
			</Container>
		</ThemeProvider>
	);
}
