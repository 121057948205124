import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import Button from "../Button";

import ColumnBars from "./ColumnBars";
import useLogic from "./logic";
import {
	Bold,
	ButtonNoBg,
	ChartWrapper,
	ColorLabel,
	Container,
	Description,
	Header,
	Highlight,
	Legend,
	Title,
	ValueItem
} from "./styles";
import type { CardChartProps } from "./types";
export default function CardChart({ className, data }: CardChartProps): JSX.Element {
	const { dataTruncate, dataKeys, isSimple, colorPalette } = useLogic({ data });

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Header>
					{!isSimple ? (
						<>
							<Title $bold>Lane Wise Average Moving, Stoppage and Threshold Stoppage</Title>
							<Description>Help to identify and reduce stoppage time.</Description>
						</>
					) : (
						<>
							<Title>
								Assets passing <Bold>“CH90 Packing” </Bold>have a <Highlight>higher chance of delay</Highlight> than
								other locations.
							</Title>
						</>
					)}
				</Header>
				<ChartWrapper>
					<ColumnBars data={dataTruncate} />
				</ChartWrapper>
				{!isSimple && (
					<Legend>
						{dataKeys.map((el: string, index: number) => (
							<ValueItem key={index}>
								<ColorLabel style={{ backgroundColor: colorPalette[index] }} /> {el}
							</ValueItem>
						))}
					</Legend>
				)}
				{isSimple ? (
					<ButtonNoBg>
						<Button label="View location" variant="no-bg" size="small" />
					</ButtonNoBg>
				) : null}
			</Container>
		</ThemeProvider>
	);
}
