import DefaultTag from "../Tag";
import { Body, Small } from "../Typography";
import { from } from "../../styles/responsive";
import { ChevronLeft, ChevronRight } from "@carbon/icons-react";
import styled, { css } from "styled-components";

import { $Props, $SizeProps } from "./types";

export const Container = styled.div`
	flex-shrink: 0;
`;

export const Wrapper = styled.div<$SizeProps>`
	.react-datepicker {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 20.5625rem;
		font-weight: ${({ theme }) => theme.weights.regular400};
		font-size: 0.875rem;
		font-family: inherit;
		line-height: 1.4rem;
		border: 1px solid ${({ theme }) => theme.colors.neutral200};
		border-radius: 0.75rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};

		${from["tabletPortrait"]} {
			flex-direction: row;
			flex-wrap: wrap;
			width: 41.125rem;
		}
	}
	.react-datepicker__input-container {
		input {
			width: 100%;
			color: ${({ theme }) => theme.colors.neutral600};
			font-size: 1rem;
			font-family: inherit;
			border: 1px solid ${({ theme }) => theme.colors.neutral300};
			border-radius: 0.5rem;
			padding-block: 0.5rem;
			padding-inline: 0.75rem 0.625rem;
		}
	}

	.react-datepicker__triangle {
		display: none;
	}

	.react-datepicker__header {
		background-color: inherit;
		border-bottom: inherit;
	}

	.react-datepicker__current-month {
		font-family: inherit;
	}
	.react-datepicker__header {
		padding: 0;
	}

	.react-datepicker__month-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		float: none;
		width: 19rem;
		overflow: hidden;

		/* padding-inline-start: 0.25rem; */

		${from["tabletPortrait"]} {
			width: 20.5rem;
		}
	}

	.react-datepicker__month {
		margin: 0;
		padding-block-start: 0.375rem;
		padding-block-end: 1.25rem;
	}

	.react-datepicker__day {
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
	}

	.react-datepicker__day--in-selecting-range {
		position: relative;
		inset-inline-start: 0;
		color: ${({ theme }) => theme.colors.neutral900};
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-radius: 0;
	}

	.react-datepicker__day--keyboard-selected {
		color: ${({ theme }) => theme.colors.neutral900};
		font-weight: ${({ theme }) => theme.weights.regular400} !important;
		background-color: ${({ theme }) => theme.colors.yellowBee200} !important;
		border-radius: 1.25rem !important;
	}

	.react-datepicker__day--selected {
		color: ${({ theme }) => theme.colors.neutral900};
		font-weight: ${({ theme }) => theme.weights.regular400} !important;
		background-color: ${({ theme }) => theme.colors.yellowBee200} !important;
		border-radius: 1.25rem !important;
	}

	.react-datepicker__day--today {
		position: relative;
		font-weight: ${({ theme }) => theme.weights.regular400};
		:before {
			position: absolute;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			font-weight: ${({ theme }) => theme.weights.regular400};
			border: 1px solid ${({ theme }) => theme.colors.neutral600};
			border-radius: 1.25rem !important;
			content: "";
		}
	}

	.react-datepicker__day--in-range {
		color: ${({ theme }) => theme.colors.neutral900};
		font-weight: ${({ theme }) => theme.weights.regular400};
		background-color: ${({ theme }) => theme.colors.yellowBee50};
		border-radius: 0;
	}
	.react-datepicker__day-names {
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		.react-datepicker__day-name {
			display: flex;
			align-items: center;
			justify-content: center;
			color: grey;
		}
	}

	.react-datepicker__day--outside-month {
		visibility: hidden;
	}

	.react-datepicker__day-name {
		width: 2.5rem;
		height: 2.5rem;
		margin: 0;
	}
	.react-datepicker__week {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2.625rem;
		margin-inline: 0.0625rem;
		margin-block: 0.0625rem;
	}

	.react-datepicker__day {
		position: relative;
		width: 2.5rem;
		height: 2.5rem;
		margin: 0;

		:hover {
			background-color: ${({ theme }) => theme.colors.neutral200};
			border-radius: 1.25rem;
		}
	}

	.react-datepicker__children-container {
		width: 41.125rem;
	}
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
	display: flex;
	align-items: center;
	width: 1.25rem;
	height: 1.25rem;
`;
export const ChevronRightIcon = styled(ChevronRight)`
	display: flex;
	align-items: center;
	width: 1.25rem;
	height: 1.25rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-block: 1.5rem 1.25rem;
`;
export const Title = styled(Body)``;

export const Footer = styled(Small)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Section = styled(Small)`
	padding-inline: 1rem;
	padding-block: 1rem;
	color: ${({ theme }) => theme.colors.neutral600};
	border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
`;

export const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-block-start: 0.75rem;
	cursor: pointer;
`;

export const Tag = styled(DefaultTag)<$Props>`
	margin-inline-end: 0.5rem;
	margin-block-end: 0.5rem;
	user-select: none;

	p {
		font-size: 0.75rem;
	}

	&:hover {
		color: ${({ theme }) => theme.colors.neutral900};
		background-color: ${({ theme }) => theme.colors.neutral200};
	}
	&:active {
		color: white;
		background-color: ${({ theme }) => theme.colors.neutral900};
	}

	${({ $active }) =>
		$active &&
		css`
			color: white;
			background-color: ${({ theme }) => theme.colors.neutral900};
		`}
`;

export const ButtonsWrapper = styled(Section)`
	display: flex;
	justify-content: flex-end;

	Button {
		margin-inline-start: 1rem;
		color: ${({ theme }) => theme.colors.neutral900};
	}
`;
