// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/HKGrotesk-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/HKGrotesk-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/HKGrotesk-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/HKGrotesk-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../assets/fonts/HKGrotesk-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../assets/fonts/HKGrotesk-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HKGrotesk - regular */
@font-face {
	font-family: "HK Grotesk";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
	font-display: swap;
}

/* HKGrotesk - medium */
@font-face {
	font-family: "HK Grotesk";
	font-style: normal;
	font-weight: 500;
	src: local(""), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
	font-display: swap;
}

/* HKGrotesk - bold */
@font-face {
	font-family: "HK Grotesk";
	font-style: normal;
	font-weight: 700;
	src: local(""), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
	font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;CACC,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB;;0GAE+G;CAC/G,kBAAkB;AACnB;;AAEA,uBAAuB;AACvB;CACC,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB;0GAC8G;CAC9G,kBAAkB;AACnB;;AAEA,qBAAqB;AACrB;CACC,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB;0GAC4G;CAC5G,kBAAkB;AACnB","sourcesContent":["/* HKGrotesk - regular */\n@font-face {\n\tfont-family: \"HK Grotesk\";\n\tfont-style: normal;\n\tfont-weight: 400;\n\tsrc: local(\"\"),\n\t\t/* Chrome 26+, Opera 23+, Firefox 39+ */ url(\"../assets/fonts/HKGrotesk-Regular.woff2\") format(\"woff2\"),\n\t\t/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(\"../assets/fonts/HKGrotesk-Regular.woff\") format(\"woff\");\n\tfont-display: swap;\n}\n\n/* HKGrotesk - medium */\n@font-face {\n\tfont-family: \"HK Grotesk\";\n\tfont-style: normal;\n\tfont-weight: 500;\n\tsrc: local(\"\"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(\"../assets/fonts/HKGrotesk-Medium.woff2\") format(\"woff2\"),\n\t\t/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(\"../assets/fonts/HKGrotesk-Medium.woff\") format(\"woff\");\n\tfont-display: swap;\n}\n\n/* HKGrotesk - bold */\n@font-face {\n\tfont-family: \"HK Grotesk\";\n\tfont-style: normal;\n\tfont-weight: 700;\n\tsrc: local(\"\"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url(\"../assets/fonts/HKGrotesk-Bold.woff2\") format(\"woff2\"),\n\t\t/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url(\"../assets/fonts/HKGrotesk-Bold.woff\") format(\"woff\");\n\tfont-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
