import { AddAlt } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, DropdownButton, IconContainer, TabsLi, TabsUl } from "./styles";
import Tab from "./Tab";
import type { TabProps } from "./Tab/types";
import type { TabsProps } from "./types";

export default function Tabs({ className, tabs, hideAddTab }: TabsProps): JSX.Element {
	const addTab = [
		{
			label: "New Tab 1",
			onClick: () => alert("New Tab 1 clicked")
		},
		{
			label: "New Tab 2",
			onClick: () => alert("New Tab 2 clicked")
		},
		{
			label: "New Tab 3",
			onClick: () => alert("New Tab 3 clicked")
		}
	];

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<TabsUl>
					{tabs.map((tab: TabProps, index: number) => (
						<TabsLi key={index}>
							<Tab label={tab.label} stats={tab?.stats} color={tab?.color} backgroundColor={tab?.backgroundColor} onClick={tab.onClick} active={tab?.active} />
						</TabsLi>
					))}
					{!hideAddTab && (
						<TabsLi>
							<IconContainer>
								<DropdownButton options={addTab} customIcon={<AddAlt />} />
							</IconContainer>
						</TabsLi>
					)}
				</TabsUl>
			</Container>
		</ThemeProvider>
	);
}
