import { useState } from "react";

const useLogic = () => {
	const [currentTab, setCurrentTab] = useState<string>("comments");

	return {
		currentTab,
		setCurrentTab
	};
};

export default useLogic;
