import DefaultButton from "../Button";
import DefaultSelect from "../Select";
import { Body } from "../Typography";
import styled from "styled-components";

import DefaultAlert from "./Alert";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 17rem;
	padding-block-start: 1rem;
	padding-inline: 1rem;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block-end: 1rem;
`;

export const Title = styled(Body)``;

export const Button = styled(DefaultButton).attrs({ variant: "no-bg" })`
	padding-inline: 0;
`;

export const Select = styled(DefaultSelect)`
	margin-block-end: 0.5rem;
`;

export const AlertList = styled.ul``;

export const Alert = styled(DefaultAlert)`
	:not(:last-of-type) {
		border-block-end: 1px solid ${({ theme }) => theme.colors.neutral200};
	}
	margin-inline: -1rem;
`;
