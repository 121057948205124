import { ArrowLeft, ArrowRight } from "@carbon/icons-react";
import range from "lodash/range";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import useLogic from "./logic";
import { ButtonWrapper, Container, Ellipsis, NextPageButton, Page, PageWrapper, PrevPageButton } from "./styles";
import type { PaginationProps } from "./types";

export default function Pagination({
	className,
	showShortcuts,
	total,
	current,
	active,
	onChangePage
}: PaginationProps): JSX.Element {
	const {
		showFirstShortcut,
		showFirstShortcutEllipsis,
		showLastShortcut,
		showLastShortcutEllipsis,
		pagesToShow,
		initial
	} = useLogic({ current, total, showShortcuts, active, onChangePage });

	const pages = range(pagesToShow).map((i) => {
		const page = i + initial;

		return (
			<Page $active={current === page} onClick={() => onChangePage(page)} key={`page-${page}`} label={`${page}`} />
		);
	});

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<PageWrapper>
					{showFirstShortcut && (
						<>
							<Page href="/" $active={current === 1} onClick={() => onChangePage(1)} key={`page-${1}`} label={`1`} />
							{showFirstShortcutEllipsis && (
								<Ellipsis $bold key="first-shortcut-ellipsis">
									...
								</Ellipsis>
							)}
						</>
					)}
					{pages}
					{showLastShortcut && (
						<>
							{showLastShortcutEllipsis && (
								<Ellipsis $bold key="last-shortcut-ellipsis">
									...
								</Ellipsis>
							)}
							<Page
								$active={current === total}
								onClick={() => onChangePage(total)}
								key={`page-${total}`}
								label={`${total}`}
								href="/"
							/>
						</>
					)}
				</PageWrapper>
				<ButtonWrapper>
					<PrevPageButton
						onClick={() => onChangePage(current - 1)}
						disabled={current === initial}
						icon={<ArrowLeft />}
						ariaLabel="Go to previous page"
					/>
					<NextPageButton
						onClick={() => onChangePage(current + 1)}
						disabled={current === total}
						icon={<ArrowRight />}
						ariaLabel="Go to next page"
					/>
				</ButtonWrapper>
			</Container>
		</ThemeProvider>
	);
}
