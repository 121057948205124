import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { useLogic } from "./logic";
import { Container, ContentWrapper, TooltipWrapper } from "./styles";
import { TooltipProps } from "./types";

export default function Tooltip({
	children,
	className,
	trigger = "hover",
	text,
	position = "top",
	offset
}: TooltipProps) {
	const { tooltipRef, showTooltip, handleMouseEnter, handleMouseLeave, handleClick } = useLogic({ trigger });
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container
				className={className}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleClick}
			>
				{children}
				{showTooltip ? (
					<TooltipWrapper position={position} ref={tooltipRef} offset={offset}>
						<ContentWrapper>{text}</ContentWrapper>
					</TooltipWrapper>
				) : null}
			</Container>
		</ThemeProvider>
	);
}
