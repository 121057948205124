import { useCallback } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import Comments from "./CommentsTab";
import HistoryTab from "./HistoryTab";
import useLogic from "./logic";
import { BoxTabs, Container, TabsContent, TabsHeader, TabsWrapper, Title } from "./styles";
import { CommentBoxProps } from "./types";

export default function SettingsView({ titleBox, historyMessages, commentsMessages }: CommentBoxProps): JSX.Element {
	const { currentTab, setCurrentTab } = useLogic();
	const renderContents = useCallback(() => {
		switch (currentTab) {
			case "commentsHistory":
				return <HistoryTab messages={historyMessages} />;
			case "comments":
			default:
				return <Comments comments={commentsMessages} />;
		}
	}, [currentTab, historyMessages, commentsMessages]);

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<Title $bold>{titleBox}</Title>
				<TabsWrapper>
					<TabsHeader>
						<BoxTabs
							currentTab={currentTab}
							onTab={setCurrentTab}
							tabs={[
								{
									label: "Comments",
									active: currentTab === "comments",
									onClick: () => {
										setCurrentTab("comments");
									}
								},
								{
									label: "History",
									active: currentTab === "commentsHistory",
									onClick: () => {
										setCurrentTab("commentsHistory");
									}
								}
							]}
							aria-label="Settings"
						/>
					</TabsHeader>

					<TabsContent>{renderContents()}</TabsContent>
				</TabsWrapper>
			</Container>
		</ThemeProvider>
	);
}
