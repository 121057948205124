import { Close } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { CloseButton, Container, IconContainer, Label } from "./styles";
import type { TagProps } from "./types";
export default function Tag({
	backgroundColor,
	color,
	hasCloseButton,
	onClick,
	iconLeft,
	label,
	onClose,
	small,
	className,
	containerAs
}: TagProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container
				as={containerAs}
				$backgroundColor={backgroundColor}
				$hasCloseButton={hasCloseButton}
				onClick={onClick}
				$iconLeft={iconLeft}
				$small={small}
				$color={color}
				className={className}
			>
				{iconLeft ? <IconContainer>{iconLeft}</IconContainer> : null}
				<Label>{label}</Label>
				{hasCloseButton ? (
					<CloseButton onClick={onClose} aria-label="close tag">
						<Close />
					</CloseButton>
				) : null}
			</Container>
		</ThemeProvider>
	);
}
