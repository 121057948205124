import styled from "styled-components";

import InfoboxCard from "./InfoboxCard";
import InfoDetail from "./InfoDetail";

export const Container = styled.section`
	max-width: 37.5rem;
	column-width: 17rem;
	padding-block: 2rem;
	padding-inline: 1rem;
`;

export const Card = styled(InfoboxCard)``;

export const Info = styled(InfoDetail)``;
