import { Body, Small } from "../Typography";
import styled, { css } from "styled-components";

import { InputRangeStyleProps } from "./types";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const Label = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const InputContainer = styled.div`
	display: flex;
	gap: 0.75rem;
	align-items: center;
`;

export const MinNumber = styled(Body)``;
export const MaxNumber = styled(Body)``;

export const trackStyles = css`
	height: 0.125rem;
	background-color: ${({ theme }) => theme.colors.neutral300};
`;

export const progressStyles = css`
	height: 0.125rem;
	background-color: ${({ theme }) => theme.colors.neutral900};
`;

export const thumbStyles = css`
	width: 0.75rem;
	height: 0.75rem;
	background-color: ${({ theme }) => theme.colors.neutral900};
	border-radius: 50%;
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	:hover {
		transform: scale(1.2);
		transition: all 0.2s ease-in-out;
	}
	:active {
		background-color: ${({ theme }) => theme.colors.yellowBee200};
		transition: none;
	}
`;

export const focusThumbStyles = css`
	outline: 2px solid ${({ theme }) => theme.colors.neutral600};
	outline-offset: 0.25rem;
`;

export const Input = styled.input<InputRangeStyleProps>`
	width: 13.6875rem;
	height: 2.625rem;
	background: none;
	-webkit-appearance: none;
	appearance: none;
	&::-webkit-slider-runnable-track {
		${trackStyles}
		${({ $progressValue }) =>
			$progressValue &&
			css`
				background: linear-gradient(
					to right,
					${({ theme }) => theme.colors.neutral900} 0%,
					${({ theme }) => theme.colors.neutral900} ${$progressValue}%,
					${({ theme }) => theme.colors.neutral300} ${$progressValue}%,
					${({ theme }) => theme.colors.neutral300} 100%
				);
				:active {
					background: linear-gradient(
						to right,
						${({ theme }) => theme.colors.yellowBee200} 0%,
						${({ theme }) => theme.colors.yellowBee200} ${$progressValue}%,
						${({ theme }) => theme.colors.neutral300} ${$progressValue}%,
						${({ theme }) => theme.colors.neutral300} 100%
					);
				}
			`}
	}
	&::-moz-range-track {
		${trackStyles}
	}
	&::-ms-track {
		${trackStyles}
	}
	&::-moz-range-progress {
		${progressStyles}
	}
	&::-ms-fill-upper {
		${progressStyles}
	}
	&::-webkit-slider-thumb {
		${thumbStyles}
		position: relative;
		inset-block-end: 0.3rem;
	}
	&::-moz-range-thumb {
		${thumbStyles}
		border: none;
	}
	&::-ms-thumb {
		${thumbStyles}
	}
	&:focus-visible::-webkit-slider-thumb {
		${focusThumbStyles}
	}
	&:focus-visible::-moz-range-thumb {
		${focusThumbStyles}
	}
	&:focus-visible::-ms-thumb {
		${focusThumbStyles}
	}
	&:focus-visible {
		outline: 2px solid ${({ theme }) => theme.colors.neutral200};
		outline-offset: 0.5rem;
	}
`;

export const Value = styled(Body)`
	min-width: 3.9375rem;
	text-align: center;
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.375rem;
	padding-block: 0.5rem;
	:hover,
	:focus {
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
		transition: all 0.2s ease-in-out;
	}
`;
