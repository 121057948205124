import { addAlpha } from "../../utils/addAlpha";
import styled, { css } from "styled-components";

import { $ContainerProps } from "./types";

export const IconContainer = styled.span`
	display: flex;
	align-items: center;
`;

export const Container = styled.span<$ContainerProps>`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	border-radius: 0.375rem;
	transition: background-color ease-in-out 0.2s;

	${IconContainer} {
		> svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	${({ $size }) => {
		switch ($size) {
			case "tiny":
				return css`
					min-height: 1.5rem;
					padding-inline: 0.125rem;
					padding-block: 0.125rem;
				`;
			case "small":
				return css`
					justify-content: center;
					width: fit-content;
					height: 2.25rem;
					padding-inline: 0.5rem;
					padding-block: 0.5rem;
				`;
			case "field":
				return css`
					justify-content: center;
					height: 2.5rem;
					padding-inline: 0.625rem;
					padding-block: 0.625rem;
				`;
			case "default":
			default:
				return css`
					height: 3rem;
					padding-inline: 0.875rem;
					padding-block: 0.875rem;
				`;
		}
	}}

	${({ theme, $variant, $disabled }) => {
		switch ($disabled) {
			case true:
				return css`
					pointer-events: none;
					cursor: default;
					text-decoration: none;
					background-color: transparent;
					opacity: ${$disabled ? "0.36" : "1"};
					&:hover {
						background-color: ${!$disabled && addAlpha(theme.colors.purpleSunset800, 0.08)};
					}
					&:focus-visible {
						background-color: ${!$disabled && addAlpha(theme.colors.purpleSunset800, 0.16)};
						outline: transparent;
					}
				`;
		}

		switch ($variant) {
			case "no-bg":
				return css`
					background-color: transparent;
					opacity: ${$disabled ? "0.36" : "1"};
					&:hover {
						background-color: ${!$disabled && addAlpha(theme.colors.purpleSunset800, 0.08)};
					}
					&:focus-visible {
						background-color: ${!$disabled && addAlpha(theme.colors.purpleSunset800, 0.16)};
						outline: transparent;
					}
				`;
			case "secondary":
				return css`
					background-color: ${theme.colors.neutral200};
					opacity: ${$disabled ? "0.36" : "1"};
					&:hover {
						${!$disabled && `background-color: ${theme.colors.neutral300}`}
					}
					&:focus-visible {
						background-color: ${theme.colors.neutral300};
						outline: transparent;
					}
				`;
			case "primary":
			default:
				return css`
					background-color: ${theme.colors.yellowBee200};
					opacity: ${$disabled ? "0.36" : "1"};
					&:hover {
						${!$disabled && `background-color: ${theme.colors.yellowBee100}`}
					}
					&:focus-visible {
						background-color: ${theme.colors.yellowBee100};
						outline: transparent;
					}
				`;
		}
	}}
`;
