import { prettyScrollbar } from "../../styles/mixins";
import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

export const Container = styled.div`
	position: relative;
`;

export const Input = styled(TextField)`
	& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
		position: relative;
		max-height: 1.375rem;
		padding-block: 0.5rem;
		padding-inline: 0 1.8rem;
		inset-block-end: 0.45rem;
	}

	& .MuiInputBase-input {
		font-family: ${({ theme }) => theme.fonts.main};
	}

	& .MuiInputBase-root {
		width: 100%;
		height: 2rem;
		color: ${({ theme }) => theme.colors.neutral900};
		font-size: 0.875rem;
		line-height: 1.5rem;
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.neutral300};
		border-radius: 0.5rem;
		padding-block: 0.35rem;
		padding-inline: 2rem 1.8rem;
		padding-right: 10px !important;

		::placeholder {
			color: ${({ theme }) => theme.colors.neutral600};
		}
	}

	/* Focus */

	& .MuiInputBase-root.Mui-focused {
		border-color: ${({ theme }) => theme.colors.neutral400};
		outline: none;
	}

	/* Disabled */

	&.MuiInputBase-root.Mui-disabled {
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-color: ${({ theme }) => theme.colors.neutral300};
		cursor: not-allowed;
		::placeholder {
			color: ${({ theme }) => theme.colors.neutral400};
		}
	}

	& .MuiAutocomplete-clearIndicator {
		position: relative;
		color: ${({ theme }) => theme.colors.neutral400};
		inset-inline-end: 0.5rem;
	}

	/* Overridden styles */

	& .MuiSelect-select:focus,
	& .MuiAutocomplete-clearIndicator:hover {
		background-color: transparent;
	}
	& .MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline:hover:not(.Mui-disabled):before,
	.MuiInput-underline:focus:not(.Mui-disabled):before,
	.MuiInput-underline.Mui-focused:before,
	.MuiInput-underline.Mui-disabled:before {
		border-bottom: none;
	}
	& .MuiAutocomplete-popupIndicator {
		display: none;
	}
`;

export const Dropdown = styled(Paper)`
	margin-block-start: 0.25rem;
	& .MuiAutocomplete-listbox {
		max-height: 15rem;
		${prettyScrollbar({})}
	}
	&.MuiPaper-root {
		padding-block: 0;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 0.5rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	& .MuiAutocomplete-option[data-focus="true"] {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	& .MuiAutocomplete-option {
		font-size: 0.875rem;
		font-family: ${({ theme }) => theme.fonts.main};
		line-height: 1.4rem;
		padding-block: 0.625rem;
		padding-inline: 0.75rem;
	}
	.MuiAutocomplete-noOptions {
		text-align: center;
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	inset-inline-start: 0.55rem;
	inset-block-start: 0.4rem;
	svg {
		width: 1.25rem;
		height: 1.25rem;
		color: ${({ theme }) => theme.colors.neutral400};
	}
`;
