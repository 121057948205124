import { Close } from "@carbon/icons-react";

import { Body } from "../../Typography";
import { InfoWindowActionButton, InfoWindowFooter, InfoWindowHeader } from "./styles";

const InfoWindow = ({ name, description, onClose }) => {
	return (
		<div>
			<InfoWindowHeader>
				<Body $bold>{name}</Body>
				<InfoWindowActionButton label="" onClick={onClose} icon={<Close />} />
			</InfoWindowHeader>
			<Body>
				<p>{description}</p>
			</Body>
			<InfoWindowFooter></InfoWindowFooter>
		</div>
	);
};

export default InfoWindow;
