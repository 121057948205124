import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useEffect, useRef } from "react";

import { LogicProps } from "./types";

export const useLogic = ({ isOpen, onClose, isClose }: LogicProps) => {
	const modalRef = useRef<HTMLDivElement>(null);
	useOnClickOutside({ ref: modalRef, handleClick: onClose, isClose: isClose });

	useEffect(() => {
		const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === "Escape" ? onClose() : null);
		document.body.addEventListener("keydown", closeOnEscapeKey);

		if (isOpen) {
			const lockHtml = document.querySelector("html");
			if (lockHtml) lockHtml.style.overflow = "hidden";
		}

		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
			const unlockHtml = document.querySelector("html");
			if (unlockHtml) unlockHtml.style.overflow = "auto";
		};
	}, [isOpen, onClose]);

	return {
		modalRef
	};
};
