import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container } from "./styles";

import { CardProps } from "./types";

export default function Card({ children }: CardProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>{children}</Container>
		</ThemeProvider>
	);
}
