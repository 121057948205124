import { Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { ChevronDown } from "@carbon/icons-react";
import { Checkbox, MenuItem, Select } from "@material-ui/core";
import styled, { css } from "styled-components";

import { SelectMultiStyleProps } from "./types";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
`;

export const RenderArea = styled.div<SelectMultiStyleProps>`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
	padding-inline-end: 1rem;
	color: ${({ theme }) => theme.colors.neutral900};

	${({ $isSmall }) =>
		$isSmall &&
		css`
			padding-inline-end: 0.5rem;
		`}
`;

export const Text = styled.p<SelectMultiStyleProps>`
	font-size: 100%;
	line-height: 1.15;
	text-transform: capitalize;

	${({ $isSmall }) =>
		$isSmall &&
		css`
			font-size: 0.875rem;
			line-height: 1.4rem;
		`}
`;

export const Count = styled.p<SelectMultiStyleProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.1875rem;
	height: 2rem;
	font-size: 100%;
	line-height: 1.15;
	background-color: ${({ theme }) => theme.colors.neutral100};
	border-radius: 0.25rem;
	padding-block: 0.125rem;

	${({ $isSmall }) =>
		$isSmall &&
		css`
			width: 1.6875rem;
			height: 1.5rem;
			font-size: 0.75rem;
			line-height: 1.25rem;
		`}
`;

export const Label = styled(Small)<SelectMultiStyleProps>`
	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral500};
		`}
`;

export const StyledSelect = styled(Select)<SelectMultiStyleProps>`
	width: 100%;
	height: 2.5rem;
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;

	${({ $isSmall }) =>
		$isSmall &&
		css`
			width: 100%;
			min-width: unset !important;
			max-width: 11.25rem;
			height: 2rem;
		`}

	& .MuiInputBase-input {
		padding-inline: 0.75rem;
		${({ $isSmall }) =>
			$isSmall &&
			css`
				padding-inline: 0.5rem;
				padding-block: 0.3125rem;
			`}
	}

	&.MuiInputBase-root {
		color: ${({ theme }) => theme.colors.neutral900};
		font-size: 1rem;
		font-family: inherit;
		line-height: 1.5rem;
		transition: 0.2s ease-in-out;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				font-size: 0.875rem;
				line-height: 1.4rem;
			`}

		:hover,
    :focus {
			background-color: ${({ theme }) => theme.colors.neutral100};
		}
	}
	& .MuiPaper-root {
		max-height: 21.25rem;
		border: 1px solid ${({ theme }) => theme.colors.neutral200};
		border-radius: 0.75rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow24};
		margin-block-start: 0.5rem;

		${prettyScrollbar({})}

		${({ $isSmall }) =>
			$isSmall &&
			css`
				width: 100%;
				min-width: unset !important;
				max-width: 11.25rem;
			`}
	}

	/* Chevron */

	& .MuiSelect-icon {
		width: 1.25rem;
		height: 1.25rem;
		color: inherit;
		margin-block-start: 0.1875rem;
		margin-inline-end: 0.625rem;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				margin-block-start: 0.125rem;
				margin-inline-end: 0.375rem;
			`}
	}

	/* Focus */

	&.MuiInputBase-root.Mui-focused {
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
	}

	/* Disabled */

	& .MuiSelect-select.Mui-disabled {
		cursor: not-allowed;
	}
	&.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.colors.neutral400};
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	/* Overridden styles */

	& .MuiSelect-select:focus {
		background-color: transparent;
	}
	&.MuiInput-underline:before,
	&.MuiInput-underline:after,
	&.MuiInput-underline:hover:not(.Mui-disabled):before,
	&.MuiInput-underline:focus:not(.Mui-disabled):before {
		border-bottom: none;
	}
	&.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: none;
	}
`;

export const Chevron = styled(ChevronDown)``;

export const StyledCheckbox = styled(Checkbox)`
	&.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${({ theme }) => theme.colors.neutral900};
	}
	&.MuiCheckbox-box {
		border-color: ${({ theme }) => theme.colors.neutral900};
	}
`;

export const Option = styled(MenuItem)<SelectMultiStyleProps>`
	width: 100%;
	&.MuiMenuItem-root {
		font-size: 1rem;
		font-family: inherit;
		line-height: 1.5rem;
		background-color: ${({ theme }) => theme.colors.white};
		margin-block-end: 0.125rem;
		padding-inline: 0.75rem;
		padding-block: 0.625rem;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				height: 2.375rem;
				min-height: unset !important;
				font-size: 0.875rem;
				line-height: 1.4rem;
				padding-block: 0.5rem;
			`}
	}
	&.MuiMenuItem-root:hover,
	&.MuiMenuItem-root:focus,
	&.MuiListItem-root.Mui-focusVisible:hover,
	&.MuiListItem-root.Mui-focusVisible:focus {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	&.MuiMenuItem-root.Mui-selected {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
`;
