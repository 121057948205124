import { Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { ChevronDown } from "@carbon/icons-react";
import { MenuItem, Select } from "@material-ui/core";
import styled, { css } from "styled-components";

import { SelectStyleProps } from "./types";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
`;

export const Label = styled(Small)<SelectStyleProps>`
	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral500};
		`}
`;

export const StyledSelect = styled(Select)<SelectStyleProps>`
	width: 100%;
	height: 2.5rem;
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;

	${({ $isSmall }) =>
		$isSmall &&
		css`
			width: 100%;
			min-width: unset !important;
			max-width: 11.25rem;
			height: 2rem;
		`}

	& .MuiInputBase-input {
		padding-inline: 0.75rem;
		> div {
			display: none;
		}
		${({ $isSmall }) =>
			$isSmall &&
			css`
				padding-inline: 0.5rem;
				padding-block: 0.3125rem;
			`}
	}

	&.MuiInputBase-root {
		color: ${({ theme }) => theme.colors.neutral600};
		font-size: 1rem;
		font-family: inherit;
		line-height: 1.5rem;
		transition: 0.2s ease-in-out;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				font-size: 0.875rem;
				line-height: 1.4rem;
			`}

		:hover,
    :focus {
			background-color: ${({ theme }) => theme.colors.neutral100};
		}
	}
	& .MuiPaper-root {
		max-height: 21.25rem;
		border: 1px solid ${({ theme }) => theme.colors.neutral200};
		border-radius: 0.75rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow24};
		margin-block-start: 0.5rem;

		${prettyScrollbar({})}

		${({ $isSmall }) =>
			$isSmall &&
			css`
				width: 100%;
				min-width: unset !important;
				max-width: 11.25rem;
			`}
	}

	/* Chevron */

	& .MuiSelect-icon {
		width: 1.25rem;
		height: 1.25rem;
		color: inherit;
		margin-block-start: 0.1875rem;
		margin-inline-end: 0.625rem;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				margin-block-start: 0.125rem;
				margin-inline-end: 0.375rem;
			`}
	}

	/* Focus */

	&.MuiInputBase-root.Mui-focused {
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
	}

	/* Disabled */

	& .MuiSelect-select.Mui-disabled {
		cursor: not-allowed;
	}
	&.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.colors.neutral400};
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	/* Overridden styles */

	& .MuiSelect-select:focus {
		background-color: transparent;
	}
	&.MuiInput-underline:before,
	&.MuiInput-underline:after,
	&.MuiInput-underline:hover:not(.Mui-disabled):before,
	&.MuiInput-underline:focus:not(.Mui-disabled):before {
		border-bottom: none;
	}
	&.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: none;
	}
`;

export const Chevron = styled(ChevronDown)``;

export const IconContainer = styled.div<SelectStyleProps>`
	display: flex;
	margin-inline-end: 0.75rem;
	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
	${({ $isSmall }) =>
		$isSmall &&
		css`
			svg {
				width: 1rem;
				height: 1rem;
			}
		`}
`;

export const Option = styled(MenuItem)<SelectStyleProps>`
	width: 100%;
	&.MuiMenuItem-root {
		font-size: 1rem;
		font-family: inherit;
		line-height: 1.5rem;
		background-color: ${({ theme }) => theme.colors.white};
		margin-block-end: 0.125rem;
		padding-inline: 0.75rem;
		padding-block: 0.625rem;

		${({ $isSmall }) =>
			$isSmall &&
			css`
				height: 2.375rem;
				min-height: unset !important;
				font-size: 0.875rem;
				line-height: 1.4rem;
				padding-block: 0.5rem;
			`}
	}
	&.MuiMenuItem-root:hover,
	&.MuiMenuItem-root:focus,
	&.MuiListItem-root.Mui-focusVisible:hover,
	&.MuiListItem-root.Mui-focusVisible:focus {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	&.MuiMenuItem-root.Mui-selected {
		background-color: ${({ theme }) => theme.colors.neutral100};
		background-image: ${({ $hasCheck }) =>
			$hasCheck
				? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='none' d='M0 0h20v20H0z' style='mix-blend-mode:multiply'/%3E%3Cpath fill='%23100F16' d='M8.125 15 2.5 9.375l.884-.884 4.741 4.74 8.491-8.49.884.884L8.125 15Z'/%3E%3C/svg%3E")`
				: ""};
		background-repeat: no-repeat;
		background-position: ${({ $isSmall }) => ($isSmall ? "top 0.6rem right 0.75rem" : "top 0.9rem right 0.75rem")};
		background-size: 1.25rem 1.25rem;
		padding-inline-end: 2.75rem;
	}
`;
