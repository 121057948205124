import { Card, Container, Item, Value, Wrapper } from "./styles";
import type { CustomTooltipProps } from "./types";

export default function CustomTooltip({ className, data, chartType }: CustomTooltipProps): JSX.Element {
	return (
		<Container className={className}>
			<Wrapper>
				<Card>
					<Value $bold>{data?.valueOne}</Value>
					{chartType !== "horizontalLabel" ? <Item>{data?.item}</Item> : null}
				</Card>
			</Wrapper>
		</Container>
	);
}
