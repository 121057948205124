import DefaultTag from "../Tag";
import { Body, Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { ChevronDown } from "@carbon/icons-react";
import { Checkbox, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled, { css } from "styled-components";

import { SelectCheckboxesCategoryStyleProps } from "./types";

export const Container = styled.div`
	min-height: 6rem;
`;

export const Label = styled(Small)<SelectCheckboxesCategoryStyleProps>`
	display: inline-block;
	margin-block-end: 0.375rem;

	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral500};
		`}
`;

export const RenderArea = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	overflow-x: hidden;
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const AutocompleteElement = styled(Autocomplete)<SelectCheckboxesCategoryStyleProps>`
	height: 2.5rem;
	color: ${({ theme }) => theme.colors.neutral600};
	font-family: ${({ theme }) => theme.fonts.main};
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;
	:focus-within {
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
	}

	& .MuiButtonBase-root {
		margin-block-start: 0.1875rem;
		margin-inline-end: 0.625rem;

		:hover {
			background-color: transparent;
		}
	}

	& .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
		min-width: 0rem !important;
	}

	& .MuiAutocomplete-option[data-focus="true"],
	.MuiAutocomplete-option[aria-selected="true"] {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral400};
			background-color: ${theme.colors.neutral100};

			& .MuiButtonBase-root {
				color: ${theme.colors.neutral400};
			}
		`}
`;

export const Input = styled(TextField)`
	/* Overridden styles */
	.MuiAutocomplete-inputRoot {
		flex-wrap: nowrap;
		height: 2.5rem;
	}

	& .MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline:hover:not(.Mui-disabled):before,
	.MuiInput-underline:focus:not(.Mui-disabled):before,
	.MuiInput-underline.Mui-focused:before,
	.MuiInput-underline.Mui-disabled:before {
		border-bottom: none;
	}
	& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
		font-size: 1rem;
		font-family: ${({ theme }) => theme.fonts.main};
		line-height: 150%;
		padding-block: 0.625rem 0.5rem;
		padding-inline: 0.75rem 0.5rem;
	}
`;

export const Chevron = styled(ChevronDown)`
	width: 1.25rem;
	height: 1.25rem;
	color: inherit;
`;

export const Dropdown = styled(Paper)`
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	margin-block-start: 0.375rem;
	& .MuiAutocomplete-listbox {
		max-height: 15rem;
		${prettyScrollbar({})}
	}
	& .MuiAutocomplete-option {
		min-height: unset;
		padding: 0 0 0 1.25rem !important;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	&.MuiPaper-root {
		padding-block: 0;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 0.5rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
	& .MuiAutocomplete-option[data-focus="true"] {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	& .MuiAutocomplete-groupUl .MuiAutocomplete-option {
		padding-inline-start: 0.75rem;
	}
	.MuiAutocomplete-noOptions {
		text-align: center;
	}
	.MuiListSubheader-root {
		color: ${({ theme }) => theme.colors.neutral600};
		font-weight: 700;
		font-size: 0.625rem;
		line-height: 1rem;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		padding-block: 1rem 0.25rem;
		padding-inline: 0.75rem;
	}
`;
export const StyledCheckbox = styled(Checkbox)`
	&.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${({ theme }) => theme.colors.neutral900};
	}
	&.MuiCheckbox-box {
		border-color: ${({ theme }) => theme.colors.neutral900};
	}
`;

export const TagList = styled.ul`
	display: flex;
	flex: 1;
	gap: 0.5rem;
	overflow-x: hidden;
	font-family: ${({ theme }) => theme.fonts.main};
`;

export const Tag = styled(DefaultTag)`
	flex-shrink: 0;
	height: 1.5rem;
	> p {
		font-size: 0.75rem;
		line-height: 1.25rem;
	}
`;

export const EllipseTag = styled(DefaultTag)`
	width: 1.5625rem;
	height: 1.5rem;
`;

export const ClearAllButton = styled.button`
	z-index: 1301;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	color: ${({ theme }) => theme.colors.neutral900};
	background-color: ${({ theme }) => theme.colors.neutral100};
	border-radius: 0.25rem;
`;

export const OptionWrapper = styled.li`
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.main};
`;

export const CategoryText = styled(Body)``;
