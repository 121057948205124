import { ResponsiveBar } from "@nivo/bar";

import useLogic from "../logic";
import { CustomBar } from "./CustomBar";
import { Card, Container, Item, Value, WrapperTooltip } from "./styles";
import type { ColumnBarsProps } from "./types";
export default function ColumnBars({ className, data }: ColumnBarsProps): JSX.Element {
	const { dataKeys, colors, isGrouped, getColor } = useLogic({ data });

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const basicTooltip: any = (props: { index: number; data: { item: string; valueOne: number } }) => {
		const isFirstHalf = props.index >= data.length - 3;

		return (
			<>
				{isFirstHalf ? (
					<WrapperTooltip>
						<Card>
							<Value $bold>{props.data.valueOne}%</Value>
							<Item>{props.data.item}</Item>
						</Card>
					</WrapperTooltip>
				) : (
					<Card>
						<Value $bold>{props.data.valueOne}%</Value>
						<Item>{props.data.item}</Item>
					</Card>
				)}
			</>
		);
	};

	return (
		<Container className={className}>
			<ResponsiveBar
				barComponent={CustomBar}
				colors={isGrouped ? colors.slice(1).map((color) => color.original) : getColor}
				tooltip={basicTooltip}
				onMouseEnter={(_data, event) => {
					const currentColor: string = event.currentTarget.attributes?.[4]?.value ?? "";

					const colorFound = colors.find(({ original }) => original === currentColor);
					const hoverColor = colorFound?.hover ?? "";
					event.currentTarget.style.fill = hoverColor;
				}}
				onMouseLeave={(_data, event) => {
					event.currentTarget.style.fill = "";
				}}
				data={data}
				keys={dataKeys && dataKeys.map((el: string) => el)}
				indexBy="item"
				groupMode="grouped"
				margin={{ top: 5, right: 5, bottom: 68, left: 36 }}
				padding={data.length > 6 ? 0.3 : 0.85}
				innerPadding={data.length > 6 ? 0 : 3}
				valueScale={{ type: "linear" }}
				animate={false}
				enableLabel={false}
				gridYValues={isGrouped ? 4 : 2}
				borderRadius={isGrouped ? 4 : 6}
				maxValue={isGrouped ? 100 : 10} //{maxValueData ? maxValueData : 'auto'}
				axisLeft={{
					tickValues: isGrouped ? 4 : 2,
					tickSize: 0,
					tickPadding: 12,
					tickRotation: 0,
					format: (value: string) => (isGrouped ? value : `${value}%`)
				}}
				axisBottom={{
					tickSize: 0,
					tickPadding: 10,
					tickRotation: -45
				}}
				theme={{
					textColor: "#908E9A",
					fontSize: 10,
					grid: {
						line: {
							stroke: "#E8E7ED",
							strokeWidth: 1
						}
					}
				}}
			/>
		</Container>
	);
}
