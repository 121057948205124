import { CustomBar } from "../CustomBar";
import CustomTooltip from "../CustomTooltip";
import useLogic from "../logic";
import { ResponsiveBar } from "@nivo/bar";

import { Container } from "./styles";
import type { SmallChartColumnBarsProps } from "./types";
export default function SmallChartColumnBars({ className, data, chartType }: SmallChartColumnBarsProps): JSX.Element {
	const { dataKeys, colors, isGrouped, getColor } = useLogic({ data });

	const formatK = (value: number) => {
		if (value < 1e3) return `${value / 1000}K`;
		if (value >= 1e3) return `${+(value / 1e3).toFixed(1)}K`;
	};

	return (
		<Container className={className}>
			<ResponsiveBar
				barComponent={(props) => <CustomBar {...props} chartType={chartType} />}
				colors={isGrouped ? colors.slice(1).map((color) => color.original) : getColor}
				tooltip={CustomTooltip}
				onMouseEnter={(_data, event) => {
					const currentColor: string = event.currentTarget.attributes?.[4]?.value ?? "";

					const colorFound = colors.find(({ original }) => original === currentColor);
					const hoverColor = colorFound?.hover ?? "";
					event.currentTarget.style.fill = hoverColor;
				}}
				onMouseLeave={(_data, event) => {
					event.currentTarget.style.fill = "";
				}}
				data={data}
				keys={dataKeys && dataKeys.map((el: string) => el)}
				indexBy="item"
				groupMode="grouped"
				margin={{ top: 5, right: 5, bottom: 6, left: 33 }}
				padding={0.3}
				innerPadding={0}
				valueScale={{ type: "linear" }}
				animate={false}
				enableLabel={false}
				gridYValues={2}
				borderRadius={4}
				maxValue={1000}
				axisLeft={{
					tickValues: 2,
					tickSize: 0,
					tickPadding: 12,
					format: (value: string) => formatK(parseInt(value))
				}}
				axisBottom={null}
				theme={{
					textColor: "#908E9A",
					fontSize: 10,
					grid: {
						line: {
							stroke: "#E8E7ED",
							strokeWidth: 1
						}
					}
				}}
			/>
		</Container>
	);
}
