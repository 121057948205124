import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Container, Results, RowsPerPage, Select, Span } from "./styles";
import type { RowResultsProps } from "./types";
export default function RowResults({
	className,
	totalResults,
	shownResults,
	current,
	onChangeshownResults
}: RowResultsProps): JSX.Element {
	const rowsPerPage = [
		{
			value: "3",
			label: "3",
			id: "3"
		},
		{
			value: "5",
			label: "5",
			id: "5"
		},
		{
			value: "10",
			label: "10",
			id: "10"
		},
		{
			value: "20",
			label: "20",
			id: "20"
		},
		{
			value: "50",
			label: "50",
			id: "50"
		},
		{
			value: "100",
			label: "100",
			id: "100"
		}
	];
	const lastIndex = current * shownResults;
	const firstIndex = lastIndex - shownResults + 1;
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Results>
					{`Showing ${firstIndex} to ${Math.min(lastIndex, totalResults)} of ${totalResults}`}
					<Span>Results</Span>
				</Results>
				<RowsPerPage>
					<Select
						small={true}
						label="Rows per page"
						name="rows-select"
						options={rowsPerPage}
						placeholder={shownResults.toString()}
						onChange={(shownResults) => onChangeshownResults(+shownResults)}
					/>
				</RowsPerPage>
			</Container>
		</ThemeProvider>
	);
}
