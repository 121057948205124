import { Checkmark, Subtract } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { CheckContainer, Container, HiddenInput, HiddenLabel, Label } from "./styles";
import type { CheckboxProps } from "./types";

export default function Checkbox({
	name,
	label,
	id = name,
	disabled,
	checked,
	onChange,
	indeterminate = false
}: CheckboxProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<HiddenInput
					type="checkbox"
					onChange={onChange}
					name={name}
					id={id}
					disabled={!!disabled}
					defaultChecked={checked}
				/>
				<Label htmlFor={id}>
					<HiddenLabel>{label}</HiddenLabel>
					<CheckContainer aria-disabled={disabled}>
						{!indeterminate ? <Checkmark size={18} /> : <Subtract size={18} />}
					</CheckContainer>
				</Label>
			</Container>
		</ThemeProvider>
	);
}
