import styled from "styled-components";

export const MapWrapper = styled.div`
	width: 100%;
	height: 475px;
	.mapboxgl-popup {
		top: -5px; // same as circle marker radius
	}
	.mapboxgl-popup-content {
		display: flex;
		flex-direction: column;
		max-width: 17rem;
		padding-block-end: 1rem;
		padding-inline: 1rem;
		overflow: hidden;
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: 0.75rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
`;
