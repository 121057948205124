import { Close } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";

import { Cell, ClearButton, Container, IconContainer, InfoWrapper, Text } from "./styles";
import type { AlertRowProps } from "./types";
export default function AlertRow({ className, alert }: AlertRowProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Cell>
					<InfoWrapper>
						<Text>{alert.type}</Text>
						<IconContainer $iconColor={alert.iconColor} aria-hidden>
							{alert.icon}
						</IconContainer>
					</InfoWrapper>
				</Cell>
				<Cell>
					<Text>{alert.date}</Text>
				</Cell>
				<Cell>
					<InfoWrapper>
						<Text>{alert.location}</Text>
						<ClearButton ariaLabel="Clear alert" icon={<Close />} />
					</InfoWrapper>
				</Cell>
			</Container>
		</ThemeProvider>
	);
}
