import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { useLogic } from "./logic";
import { DraggableContainer, ListItem, ListItemContainer } from "./styles";
import { DraggableListProps } from "./types";

export default function DraggableList({ id, list }: DraggableListProps): JSX.Element {
	const { handleDrop, items, setItems } = useLogic(list);
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId={id}>
					{(provided) => (
						<ListItemContainer {...provided.droppableProps} ref={provided.innerRef}>
							{items.map((item, index) => (
								<DraggableContainer draggableId={`dc-${id}-${index}`} index={index} key={`dc-${id}-${index}`}>
									{(prov) => (
										<ListItemContainer
											className="item-container"
											ref={prov.innerRef}
											{...prov.draggableProps}
											{...prov.dragHandleProps}
										>
											<ListItem
												text={item}
												onDelete={() => {
													const updatedList = [...items];
													updatedList.splice(index, 1);
													setItems(updatedList);
												}}
											/>
										</ListItemContainer>
									)}
								</DraggableContainer>
							))}
							{provided.placeholder}
						</ListItemContainer>
					)}
				</Droppable>
			</DragDropContext>
		</ThemeProvider>
	);
}
