import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import DefaultListItem from "./ListItem";

export const DraggableContainer = styled(Draggable)``;

export const ListItem = styled(DefaultListItem)`
	:active {
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
`;

export const ListItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`;
