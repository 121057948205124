import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { ButtonLabel, Container, IconContainer } from "./styles";
import type { Props } from "./types";

export default function Button({ className, disabled, icon, label, size, variant, onClick }: Props): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container
				className={className}
				$disabled={disabled}
				$size={size}
				$variant={variant}
				as="button"
				onClick={onClick}
			>
				{icon ? <IconContainer>{icon}</IconContainer> : null}
				<ButtonLabel>{label}</ButtonLabel>
			</Container>
		</ThemeProvider>
	);
}
