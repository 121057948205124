import { Caption, Small } from "../../Typography";
import styled from "styled-components";

export const Container = styled.div`
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
	position: absolute;
	bottom: 0.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	animation: fadeIn 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 6.25rem;
`;

export const Card = styled.div`
	min-width: 1rem;
	min-height: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const Item = styled(Caption)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Value = styled(Small)``;
