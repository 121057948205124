import { useEffect, useState } from "react";

export default function useMedia(query: string) {
	const formattedQuery = query.slice(7);
	const [matches, setMatches] = useState(false);
	useEffect(() => {
		const media = window.matchMedia(formattedQuery);
		if (media.matches) {
			setMatches(media.matches);
		}

		const listener = () => setMatches(media.matches);
		media.addEventListener("change", () => {
			listener();
		});

		return () =>
			media.removeEventListener("change", () => {
				listener();
			});
	}, [formattedQuery, matches]);
	return matches;
}
