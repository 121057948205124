import { RefObject, useLayoutEffect, useRef, useState } from "react";

import { Option as OptionType } from "./types";

export default function useLogic(options: OptionType[]): {
	values: OptionType[];
	setValues: (values: OptionType[]) => void;
	opened: boolean;
	setOpened: (open: boolean) => void;
	isCategoryChecked: (category: string, variant: string) => boolean;
	shownTags: number;
	tagsListRef: RefObject<HTMLUListElement>;
	clearAll: () => void;
} {
	const [opened, setOpened] = useState(false);
	const [values, setValues] = useState<OptionType[]>([]);
	const clearAll = () => {
		setValues([]);
	};
	const isCategoryChecked = (category: string, variant: string) => {
		let all = true;
		let any = false;
		options
			.filter((option) => option.category === category)
			.forEach((option) => {
				if (values.some((value) => value.value === option.value)) any = true;
				else all = false;
			});
		return variant === "all" ? all : any && !all;
	};

	const [shownTags, setShownTags] = useState(0);
	const tagsListRef = useRef<HTMLUListElement>(null);
	useLayoutEffect(() => {
		setShownTags(values.length);
	}, [values]);
	useLayoutEffect(() => {
		if (tagsListRef.current) {
			if (shownTags > 1 && tagsListRef.current.scrollWidth > tagsListRef.current.clientWidth) {
				setShownTags(Math.max(shownTags - 1, 1));
			}
		}
	}, [shownTags]);

	return {
		values,
		setValues,
		opened,
		setOpened,
		isCategoryChecked,
		shownTags,
		tagsListRef,
		clearAll
	};
}
