import styled, { css } from "styled-components";

import type { StyledProps } from "./types";

export const Container = styled.div``;

export const DropContent = styled.div<StyledProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24.375rem;
	padding-inline: 1.5rem;
	padding-block: 1.5rem;
	color: ${({ theme }) => theme.colors.neutral900};
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px dashed ${({ theme }) => theme.colors.neutral500};
	border-radius: 0.75rem;
	cursor: pointer;

	${({ isDragActive, theme }) =>
		isDragActive &&
		css`
			border: 2px solid ${theme.colors.yellowBee200};
		`}
	${({ isDragAccept, theme }) =>
		isDragAccept &&
		css`
			border: 2px solid ${theme.colors.yellowBee200};
		`}
  ${({ isDragReject, theme }) =>
		isDragReject &&
		css`
			border: 2px solid ${theme.colors.supportError400};
		`}
`;

export const Underlined = styled.span`
	color: ${({ theme }) => theme.colors.neutral900};
	text-decoration: underline;
	margin-inline-start: 0.25rem;
`;

export const ErrorMessage = styled.span`
	color: ${({ theme }) => theme.colors.yellowBee200};
	font-size: 0.75rem;
	line-height: 133%;
	letter-spacing: 0.025rem;
	margin-block-start: 0.5rem;
`;

export const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.neutral900};
	svg {
		pointer-events: none;
		margin-block-start: -0.0938rem;
		margin-inline-end: 0.625rem;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline: 0.75rem;
	padding-block: 0.75rem;
	color: ${({ theme }) => theme.colors.yellowBee400};
	background-color: ${({ theme }) => theme.colors.yellowBee50};
	border-radius: 50%;

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

export const TextWrapper = styled.div`
	display: inline-flex;
`;
