import DefaultButton from "../Button";
import { Body, Caption } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import styled from "styled-components";

import DefaultAlertRow from "./AlertRow";

export const Container = styled.div`
	padding-block-start: 1rem;
	width: 100%;
	max-width: 35rem;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const Content = styled.div`
	${prettyScrollbar({ vertical: false })}
	overflow-x: auto;
`;

export const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block-end: 1rem;
	padding-inline: 1rem;
	min-width: 35rem;
`;

export const Title = styled(Body)``;

export const Button = styled(DefaultButton).attrs({ variant: "no-bg" })`
	padding-inline: 0;
`;

export const Table = styled.table`
	width: 31rem;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
`;

export const TableHeading = styled.thead``;

export const Row = styled.tr``;

export const HeadingCell = styled.th.attrs({ scope: "col" })`
	padding-block-end: 1rem;
	text-align: start;
	:nth-child(1) {
		width: 13.0625rem;
		padding-inline-start: 1rem;
	}
	:nth-child(2) {
		width: 10.0625rem;
		padding-inline-start: 1.5rem;
	}
	:nth-child(3) {
		width: 11.875rem;
		padding-inline-start: 1.5rem;
	}
`;

export const Text = styled(Caption)``;

export const AlertRow = styled(DefaultAlertRow)`
	:not(:last-of-type) {
		border-block-end: 1px solid ${({ theme }) => theme.colors.neutral200};
	}
	margin-inline: -1rem;
`;
