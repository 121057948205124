import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const createWrapperAndAppendToBody = (wrapperId: string) => {
	const wrapperElement = document.createElement("div");
	wrapperElement.setAttribute("id", wrapperId);
	wrapperElement.setAttribute("data-testid", "react-portal");
	document.body.appendChild(wrapperElement);
	return wrapperElement;
};

export const Portal = ({
	children,
	wrapperId = "react-portal-wrapper"
}: {
	children: ReactNode;
	wrapperId: string;
}) => {
	const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		let element = document.getElementById(wrapperId);
		let systemCreated = false;
		if (!element) {
			systemCreated = true;
			element = createWrapperAndAppendToBody(wrapperId);
		}
		setWrapperElement(element);

		return () => {
			if (systemCreated && element?.parentNode) {
				element.parentNode.removeChild(element);
			}
		};
	}, [wrapperId]);

	if (wrapperElement === null) return null;

	return createPortal(children, wrapperElement);
};
