import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import {
	AlertRow,
	Button,
	Container,
	Content,
	Heading,
	HeadingCell,
	Row,
	Table,
	TableHeading,
	Text,
	Title
} from "./styles";
import type { AlertsTableCardProps } from "./types";
export default function AlertsTableCard({ className, alertList }: AlertsTableCardProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Content>
					<Heading>
						<Title $bold>Alerts</Title>
						<Button label="View all" />
					</Heading>
					<Table>
						<TableHeading>
							<Row>
								<HeadingCell>
									<Text $bold>Alert</Text>
								</HeadingCell>
								<HeadingCell>
									<Text $bold>Date</Text>
								</HeadingCell>
								<HeadingCell>
									<Text $bold>Location</Text>
								</HeadingCell>
							</Row>
						</TableHeading>
						{alertList.map((item, idx) => (
							<AlertRow key={idx} alert={item} />
						))}
					</Table>
				</Content>
			</Container>
		</ThemeProvider>
	);
}
