import Button from "../../Button";
import IconButton from "../../IconButton";
import { Body, Overline } from "../../Typography";
import styled, { css } from "styled-components";

import { InfoDetailColorProps, InfoDetailStyleProps } from "./types";

export const Container = styled.div``;

export const IconContainer = styled.div<InfoDetailColorProps>`
	display: flex;
	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
	${({ $iconColor }) =>
		$iconColor &&
		css`
			color: ${$iconColor};
		`}
`;

export const Title = styled(Overline).attrs({ as: "p" })`
	display: flex;
	gap: 0.25rem;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral600};
	margin-block-end: 0.125rem;
	${IconContainer} {
		svg {
			width: 1rem;
			height: 1rem;
		}
	}
`;

export const TitleIconButton = styled(IconButton)`
	height: auto;
	padding: 0;
	&:hover {
		background-color: transparent;
	}
`;

export const Detail = styled(Body).attrs({ as: "p" })<InfoDetailStyleProps>`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	${({ $isChecked }) =>
		$isChecked &&
		css`
			justify-content: space-between;
		`}
`;

export const Link = styled(Button).attrs({ variant: "no-bg" })`
	display: inline-block;
	height: auto;
	padding: 0;
	text-decoration: underline;
`;

export const CheckContainer = styled.div`
	display: flex;
	svg {
		width: 1.25rem;
		height: 1.25rem;
		color: ${({ theme }) => theme.colors.supportSuccess400};
	}
`;
