import { TextEllipsis } from "../../../styles/mixins";
import styled from "styled-components";

export const Container = styled.div``;

export const StyledInput = styled.input`
	width: 100%;
	padding-inline: 0.75rem 6.75rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;
	padding-block: 0.5rem;
	${TextEllipsis}

	::placeholder {
		color: ${({ theme }) => theme.colors.neutral600};
		font-size: 0.875rem;
		padding-inline-end: 6.75rem;
	}

	:focus {
		border-color: ${({ theme }) => theme.colors.neutral900};
		outline: none;
	}

	:disabled {
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-color: ${({ theme }) => theme.colors.neutral300};
		cursor: not-allowed;
		::placeholder {
			color: ${({ theme }) => theme.colors.neutral400};
		}
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	inset-inline-end: 0.75rem;
	inset-block-start: 0.55rem;
	svg {
		width: 1.25rem;
		height: 1.25rem;
		color: ${({ theme }) => theme.colors.neutral600};
	}
`;
export const TagWrapper = styled.div`
	position: absolute;
	inset-inline-end: 2.5rem;
	inset-block-start: 0.3rem;
`;
