import { Search } from "@carbon/icons-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, Dropdown, IconWrapper, Input } from "./styles";
import type { InputSearchProps } from "./types";
export default function InputSearch({
	className,
	placeholder,
	data,
	handleInputChange
}: InputSearchProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Autocomplete
					id="search-input"
					freeSolo
					options={data ?? []}
					getOptionLabel={(option: any) => option?.value || option} //options must have two KEYS ==> key & value
					noOptionsText={"No options available"}
					renderInput={(params) => <Input {...params} placeholder={placeholder} />}
					PaperComponent={({ children }) => <Dropdown elevation={0}>{children}</Dropdown>}
					onChange={(event, value) => handleInputChange(event, value)}
				/>
				<IconWrapper>
					<Search />
				</IconWrapper>
			</Container>
		</ThemeProvider>
	);
}
