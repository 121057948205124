import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Container, Progress, Text } from "./styles";
import { ProgressBarProps } from "./types";

export default function ProgressBar({ title, value = 0, max = 100, className }: ProgressBarProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Text>{title}</Text>
				<Progress max={max} value={value} />
			</Container>
		</ThemeProvider>
	);
}
