import useModal from "../../hooks/useModal";
import { from } from "../../styles/responsive";
import useMedia from "../../utils/useMedia";
import { useCallback } from "react";

export default function useLogic() {
	const isMobile = !useMedia(from["tabletPortrait"] as string);

	const { isOpenModal, onOpenModal, onCloseModal: onCloseSearchModal } = useModal();

	const onOpenSearchModal = useCallback(() => {
		onOpenModal();
	}, [onOpenModal]);

	return {
		isMobile,
		isOpenModal,
		onOpenSearchModal,
		onCloseSearchModal
	};
}
