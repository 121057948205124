import { Close } from "@carbon/icons-react";
import styled, { css } from "styled-components";

import { StyledProp } from "./types";

export const IconContainer = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	padding-inline: 0.125rem;
	padding-block: 0.125rem;
`;

export const CloseButton = styled.button`
	width: 1.5rem;
	height: 1.5rem;
	padding-inline: 0.125rem;
	padding-block: 0.125rem;
`;

export const Container = styled.div<StyledProp>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 22.5rem;
	padding-block: 0.75rem;
	padding-inline: 1rem;
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow24};

	${({ $variant }) => {
		switch ($variant) {
			default:
			case "neutral":
				return css`
					color: ${({ theme }) => theme.colors.white};
					background-color: ${({ theme }) => theme.colors.purpleSunset800};
				`;
			case "successful":
				return css`
					padding-inline: 1rem;
					padding-block: 0.6875rem;
					color: ${({ theme }) => theme.colors.supportSuccess800};
					background-color: ${({ theme }) => theme.colors.supportSuccess50};
					border: 1px solid ${({ theme }) => theme.colors.supportSuccess400};

					${IconContainer} {
						color: ${({ theme }) => theme.colors.supportSuccess400};
					}
				`;
			case "warning":
				return css`
					padding-inline: 1rem;
					padding-block: 0.6875rem;
					color: ${({ theme }) => theme.colors.supportWarning800};
					background-color: ${({ theme }) => theme.colors.supportWarning50};
					border: 1px solid ${({ theme }) => theme.colors.supportWarning200};
					${IconContainer} {
						color: ${({ theme }) => theme.colors.supportWarning200};
					}
					${CloseButton} {
						color: ${({ theme }) => theme.colors.supportWarning800};
					}
				`;
			case "error":
				return css`
					color: ${({ theme }) => theme.colors.supportError800};
					background-color: ${({ theme }) => theme.colors.supportError50};
					border: 1px solid ${({ theme }) => theme.colors.supportError200};
					${IconContainer} {
						color: ${({ theme }) => theme.colors.supportError200};
					}
				`;
		}
	}}
`;

export const Wrapper = styled.div`
	display: flex;
	gap: 0.8438rem;
	align-items: center;
	justify-content: start;
`;

export const Text = styled.div``;

export const CloseIcon = styled(Close)``;
