import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Chevron, Container, IconContainer, Label, Option, StyledSelect } from "./styles";
import type { SelectProps } from "./types";

export default function Select({ className, disabled, name, options, placeholder, onChange, value, label, small, iconLeft, hasCheck }: SelectProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label ? (
					<Label $bold as="label" htmlFor={name} $isDisabled={!!disabled}>
						{label}
					</Label>
				) : null}
				<StyledSelect
					$isSmall={small}
					id={name}
					defaultValue={placeholder}
					value={value === "" ? (placeholder as string) : (value as string)}
					onChange={onChange ? (e) => onChange(e.target.value as string) : undefined}
					disabled={disabled}
					IconComponent={Chevron}
					MenuProps={{
						disablePortal: true,
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						},
						children: false
					}}
				>
					{options?.map(({ label: optionLabel, value: optionValue, disabled: disabledOption }) => (
						<Option $hasCheck={hasCheck} $isSmall={small} key={optionValue} value={optionValue} disabled={disabledOption}>
							{iconLeft ? <IconContainer $isSmall={small}>{iconLeft}</IconContainer> : null}
							{optionLabel}
						</Option>
					))}
				</StyledSelect>
			</Container>
		</ThemeProvider>
	);
}
