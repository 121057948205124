import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { CheckMark, Circle, Input, Label, Text } from "./styles";
import type { Props } from "./types";

export default function RadioButton({
	className,
	name,
	checked,
	disabled,
	id,
	label,
	hiddenLabel,
	onChange,
	...props
}: Props) {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Label className={className}>
				<Circle>
					<Input
						type="radio"
						defaultChecked={checked}
						disabled={disabled}
						name={name}
						id={id}
						onChange={onChange}
						value={label}
						{...props}
					/>
					<CheckMark />
				</Circle>
				<Text $visuallyHidden={!!hiddenLabel}>{label}</Text>
			</Label>
		</ThemeProvider>
	);
}
