import { ButtonLink, Container, Heading, Info, InfoWrapper, List, Percentage, SecondColumn, Title } from "./styles";
import { ThemeProvider } from "styled-components";
import type { ListCardProps } from "./types";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
export default function ListCard({ className, title, percentage, info }: ListCardProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Heading>
					<Title $bold>{title}</Title>
					{percentage ? <Percentage>{percentage}</Percentage> : null}
				</Heading>
				<List>
					{info?.map((data, index) => (
						<InfoWrapper key={`${data.description}-${index}`}>
							<Info>{data.description}</Info>
							{data.link ? (
								<ButtonLink label={data.value} href={data.link} variant="no-bg" />
							) : (
								<SecondColumn $bold>{data.value}</SecondColumn>
							)}
						</InfoWrapper>
					))}
				</List>
			</Container>
		</ThemeProvider>
	);
}
