import { visuallyHidden } from "../../styles/mixins";
import styled from "styled-components";

export const Container = styled.div`
	display: inline-flex;
`;

export const CheckContainer = styled.span`
	display: flex;
	align-items: center;
	width: 1.125rem;
	height: 1.125rem;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.white};
	border: solid 1px ${({ theme }) => theme.colors.neutral900};
	border-radius: 0.25rem;
`;

export const HiddenInput = styled.input`
	width: 100%;
	height: 100%;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	appearance: none;
`;

export const Label = styled.label`
	${HiddenInput}:checked + &,
  ${HiddenInput}:hover:checked + &,
  ${HiddenInput}:focus:checked  + & {
		${CheckContainer} {
			background-color: ${({ theme }) => theme.colors.neutral800};
			border-color: ${({ theme }) => theme.colors.neutral800};
		}
	}

	${HiddenInput}:disabled:not(:checked) + &,
  ${HiddenInput}:hover:disabled:not(:checked) + &,
  ${HiddenInput}:focus:disabled:not(:checked)  + & {
		${CheckContainer} {
			color: ${({ theme }) => theme.colors.white};
			background-color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.neutral300};
		}
	}

	${HiddenInput}:disabled:checked + & {
		${CheckContainer} {
			background-color: ${({ theme }) => theme.colors.neutral300};
			border-color: ${({ theme }) => theme.colors.neutral300};
		}
	}

	${HiddenInput}:hover:not(:checked):not(:disabled) + &, 
  ${HiddenInput}:focus:not(:checked):not(:disabled) + & {
		${CheckContainer} {
			color: ${({ theme }) => theme.colors.neutral200};
			background-color: ${({ theme }) => theme.colors.neutral200};
			border-color: ${({ theme }) => theme.colors.neutral800};
		}
	}
`;

export const HiddenLabel = styled.span`
	${visuallyHidden}
`;
