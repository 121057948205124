import { Portal } from "../../Portal";
import FocusTrap from "focus-trap-react";
import React from "react";

import { Backdrop, InputSearchGlobal } from "./styles";
import { SearchModalProps } from "./types";

export const SearchModal = ({ className, isOpen, onClose, id, placeholder, data, quick, recent }: SearchModalProps) => {
	if (!isOpen) {
		return null;
	}

	return (
		<Portal wrapperId="modal">
			<FocusTrap
				active={isOpen}
				focusTrapOptions={{
					tabbableOptions: { displayCheck: "none" },
					allowOutsideClick: true
				}}
			>
				<Backdrop className={className} data-testid="backdrop">
					<InputSearchGlobal
						id={id}
						placeholder={placeholder}
						data={data}
						quick={quick}
						recent={recent}
						onCloseModal={onClose}
					/>
				</Backdrop>
			</FocusTrap>
		</Portal>
	);
};
