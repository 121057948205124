import { Body, H3 } from "../Typography";
import { Growth } from "@carbon/icons-react";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 17rem;
	min-height: 13.25rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	padding-block: 1rem;
	padding-inline: 1rem;
`;

export const ChartWrapper = styled.div`
	width: 100%;
	max-height: 6.125rem;
	margin-block-start: 1rem;
`;

export const Header = styled.div``;

export const Title = styled(Body)`
	color: ${({ theme }) => theme.colors.neutral900};
	letter-spacing: -0.038rem;
`;

export const MainValue = styled(H3)`
	margin-block-start: 0.5rem;
	display: flex;
	justify-content: space-between;
`;

export const Trend = styled.span`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.supportError200};
	font-size: 0.875rem;
`;

export const GrowthIcon = styled(Growth)`
	transform: scaleY(-1);
	margin-inline-end: 0.3125rem;
`;
