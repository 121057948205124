import { ArrowLeft, Search, Time } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { useLogic } from "./logic";
import { Autocomplete, Container, Dropdown, GoBackButton, IconWrapper, Input, RecentOptionContainer } from "./styles";
import type { InputSearchGlobalProps } from "./types";

export default function InputSearchGlobal({
	className,
	placeholder,
	data,
	quick,
	recent,
	onCloseModal
}: InputSearchGlobalProps): JSX.Element {
	const { searching, setSearching, results, setResults, initialData, isMobile, formattedData } = useLogic(
		data,
		quick,
		recent
	);
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Autocomplete
					id="search-input-global"
					options={results ?? []}
					noOptionsText={"No options available"}
					onInputChange={(event, value) => {
						if ((!searching && value.length > 0) || (searching && value.length < 1)) {
							setSearching(value.length > 0);
							setResults(value.length > 0 || initialData.length === 0 ? formattedData : initialData);
						}
					}}
					renderOption={(option) => {
						const typedOption = option as { label: string; category: string };
						if (typedOption.category === "Recent searches")
							return (
								<li>
									<RecentOptionContainer>
										<Time />
										{typedOption.label}
									</RecentOptionContainer>
								</li>
							);
						else if (typedOption.category === "Quick search suggestions") return <li>{typedOption.label}</li>;
						else return <li>{typedOption.label}</li>;
					}}
					groupBy={searching ? null : (option) => (option as { label: string; category: string }).category}
					getOptionLabel={(option) => (option as { label: string; category: string }).label}
					renderInput={(params) => <Input {...params} placeholder={placeholder} />}
					PaperComponent={({ children }) => <Dropdown elevation={0}>{children}</Dropdown>}
				/>
				{isMobile ? (
					<GoBackButton icon={<ArrowLeft />} ariaLabel="Go Back" onClick={onCloseModal} />
				) : (
					<IconWrapper>
						<Search />
					</IconWrapper>
				)}
			</Container>
		</ThemeProvider>
	);
}
