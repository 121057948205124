import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { useMaterialReactTable, MaterialReactTable } from "material-react-table";
import { AllFiltersButton, Container, Content, Header, MainActions, MainTitle, TableContainer, Toolbar } from "./styles";
import { MenuItem } from "@material-ui/core";

export default function DataTable({ className, title, columns, data, rowCount, isLoading, columnFilters, onColumnFiltersChange, columnFilterFns, onColumnFilterFnsChange, sorting, onSortingChange, pagination, onPaginationChange, filterConfigs, toolbarConfigs }): JSX.Element {
	const table = useMaterialReactTable({
		data,
		columns,
		initialState: { showColumnFilters: false },
		rowCount: rowCount,
		// enableColumnFilterModes: true,
		enableColumnResizing: true,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enableColumnPinning: true,
		enableColumnOrdering: true,
		enableFacetedValues: true,
		enableGlobalFilter: false,
		manualFiltering: true,
		manualPagination: true,
		manualSorting: true,
		onColumnFiltersChange,
		onColumnFilterFnsChange,
		onPaginationChange,
		onSortingChange,
		paginationDisplayMode: "pages",
		state: { isLoading, columnFilters, columnFilterFns, pagination, sorting },
		renderTopToolbarCustomActions: ({ table }) => (
			<MainActions>
				{filterConfigs.map((filterConfig, index) => (
					<AllFiltersButton key={index} size={filterConfig.size} variant={filterConfig.variant} onClick={filterConfig.onClick} label={filterConfig.label} />
				))}
				<Toolbar>
					{toolbarConfigs.map((toolbarConfig, index) => (
						<AllFiltersButton key={index} size={toolbarConfig.size} variant={toolbarConfig.variant} onClick={toolbarConfig.onClick} label={toolbarConfig.label} />
					))}
				</Toolbar>
			</MainActions>
		)
		// displayColumnDefOptions: {
		// 	"mrt-row-actions": {
		// 		header: "" //change header text
		// 		// size: 50 //make actions column wider
		// 	}
		// }
		// enableRowActions: true,
		// renderRowActionMenuItems: ({ row }) => [
		// 	<MenuItem key="edit" onClick={() => console.info("Edit")}>
		// 		Edit
		// 	</MenuItem>,
		// 	<MenuItem key="delete" onClick={() => console.info("Delete")}>
		// 		Delete
		// 	</MenuItem>
		// ]
		// enableEditing: true,
		// editDisplayMode: "modal",
	});

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Content>
					<Header>
						<MainTitle>{title}</MainTitle>
					</Header>
					<TableContainer>
						<MaterialReactTable table={table} />
					</TableContainer>
				</Content>
			</Container>
		</ThemeProvider>
	);
}
