import { Body, Caption, Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 35rem;
	min-height: 17.5rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	padding-block: 1rem;
	padding-inline: 1rem;

	${prettyScrollbar({})}
`;

export const ChartWrapper = styled.div`
	width: 100%;
	overflow-x: auto;
`;

export const Header = styled.div`
	margin-block-end: 1.5625rem;
`;

export const Title = styled(Body)`
	color: ${({ theme }) => theme.colors.neutral900};
`;

export const Bold = styled(Body).attrs({
	as: "span"
})`
	font-weight: bold;
`;

export const Highlight = styled(Body).attrs({
	as: "span"
})`
	color: #6245d9;
	font-weight: bold;
`;

export const Description = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Legend = styled.div`
	display: flex;
`;

export const ValueItem = styled(Caption)`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral600};
	margin-inline-end: 1rem;
`;

export const ColorLabel = styled.span`
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.25rem;
	margin-inline-end: 0.5rem;
`;

export const ButtonNoBg = styled.div`
	margin-inline-start: -0.75rem;
`;
