import DefaultButton from "../Button";
import DefaultIconButton from "../IconButton";
import styled, { css } from "styled-components";

import { StyledDropdownWrapperProps } from "./types";
export const Container = styled.div`
	position: relative;
	display: flex;
	width: fit-content;
`;

export const Button = styled(DefaultIconButton).attrs({
	type: "button",
	variant: "no-bg"
})`
	&:focus-visible {
		outline: ${({ theme }) => theme.colors.neutral900} solid 0.125rem;
	}
`;

export const DropdownWrapper = styled.div<StyledDropdownWrapperProps>`
	position: absolute;
	inset-block-start: 3.125rem;
	z-index: 1;
	display: none;
	overflow: auto;
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow24};
	${({ $open }) =>
		$open &&
		css`
			display: flex;
			flex-direction: column;
		`}
	${({ $position }) =>
		$position === "left" &&
		css`
			inset-inline-end: 0;
		`}

    ${({ $size }) => {
		switch ($size) {
			case "default":
				return css`
					min-width: 15.75rem;
				`;
			case "small":
			default:
				return css`
					min-width: 11.2969rem;
					font-size: 0.875rem;
					line-height: 1.4rem;
				`;
		}
	}}
`;

export const DropdownItem = styled(DefaultButton).attrs({
	type: "button",
	variant: "no-bg"
})`
	justify-content: start;
	padding-block: 0.5rem;
	padding-inline: 0.75rem;
	color: ${({ theme }) => theme.colors.neutral900};
	border-radius: 0.25rem;

	:hover {
		color: ${({ theme }) => theme.colors.neutral900};
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
`;
