import { Small } from "../Typography";
import { addAlpha } from "../../utils/addAlpha";
import styled, { css } from "styled-components";

import { ContainerProps } from "./types";

export const Container = styled.div<ContainerProps>`
	display: flex;
	padding-block: 0.25rem;
	padding-inline: 0.75rem;
	gap: 0.375rem;
	align-items: center;
	justify-content: center;
	width: fit-content;
	color: ${({ theme, $color }) => ($color ? $color : theme.colors.neutral900)};
	background-color: ${({ theme, $backgroundColor }) => ($backgroundColor ? $backgroundColor : theme.colors.neutral100)};
	border: none;
	border-radius: 0.375rem;

	${({ $small, $hasCloseButton, $iconLeft }) =>
		$small &&
		!$hasCloseButton &&
		!$iconLeft &&
		css`
			font-size: 0.875rem;
			line-height: 132%;
			padding-block: 0.125rem;
			padding-inline: 0.625rem;
		`}

	${({ $hasCloseButton, $backgroundColor }) =>
		$hasCloseButton &&
		css`
			padding-inline-end: 0.4375rem;
			:hover,
			:focus {
				background-color: ${({ theme }) =>
					$backgroundColor ? addAlpha($backgroundColor, 0.5) : addAlpha(theme.colors.neutral100, 0.5)};
			}
		`}
    
  ${({ $iconLeft }) =>
		$iconLeft &&
		css`
			padding-inline-start: 0.625rem;
		`}
`;

export const CloseButton = styled.button`
	display: flex;
	color: inherit;
	border: none;
`;

export const IconContainer = styled.div`
	display: flex;
	width: 1rem;
	height: 1rem;
	color: inherit;
`;

export const Label = styled(Small)``;
