import light from "../../../styles/themes/light";
import { ResponsiveLine } from "@nivo/line";

import { Card, Container, Date, Location, Point, SpecialTooltipWrapper, TooltipWrapper, Value } from "./styles";
import { Datum, LineChartProps } from "./types";

export default function SensorData({ className, data }: LineChartProps): JSX.Element {
	const colorPalette = [light.colors.purpleSunset400, light.colors.oceanicBlue200, light.colors.yellowBee200];

	return (
		<Container className={className}>
			<ResponsiveLine
				data={data}
				margin={{
					top: 10,
					right: 30,
					bottom: data.length > 1 ? 80 : 50,
					left: 60
				}}
				xScale={{ type: "point" }}
				yScale={{
					type: "linear",
					min: 0,
					max: 100,
					stacked: false,
					reverse: false
				}}
				colors={colorPalette}
				yFormat=" >-.2%"
				curve="cardinal"
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 0,
					tickPadding: 20,
					tickRotation: 0
				}}
				axisLeft={{
					tickSize: 0,
					tickPadding: 24,
					tickRotation: 0,
					format: (value: string) => `${value}%`
				}}
				enableGridX={false}
				enablePoints={false}
				crosshairType="x"
				useMesh={true}
				legends={
					data.length > 1
						? [
								{
									anchor: "bottom-left",
									direction: "row",
									justify: false,
									translateX: -38,
									translateY: 80,
									itemsSpacing: 8,
									itemDirection: "left-to-right",
									itemWidth: 88,
									itemHeight: 20,
									itemOpacity: 1,
									symbolSize: 8,
									symbolShape: "circle"
								}
						  ]
						: []
				}
				tooltip={({ point }) => {
					const { x, y, location, date } = point.data as never as Datum["data"][number];

					const pointX = point.data.x;
					const dataPointX = data?.[0]?.data?.[0]?.x;

					return (
						<>
							{pointX === dataPointX ? (
								<TooltipWrapper>
									<Card>
										<Value $bold>{`${y.toString()}%`}</Value>
										<Location>{location}</Location>
										<Date>
											{date} / {x.toString()}
										</Date>
									</Card>
								</TooltipWrapper>
							) : (
								<SpecialTooltipWrapper>
									<Card>
										<Value $bold>{`${y.toString()}%`}</Value>
										<Location>{location}</Location>
										<Date>
											{date} / {x.toString()}
										</Date>
									</Card>
								</SpecialTooltipWrapper>
							)}
							<Point $color={point.color} />
						</>
					);
				}}
				theme={{
					textColor: "#908E9A",
					fontSize: 12,
					grid: {
						line: {
							stroke: "#E8E7ED",
							strokeWidth: 1
						}
					}
				}}
			/>
		</Container>
	);
}
