import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { BreadcrumbItem, BreadcrumbLink, Container } from "./styles";
import type { BreadcrumbProps } from "./types";
import { NavLink, BrowserRouter } from "react-router-dom";

export default function Breadcrumb({ className, links }: BreadcrumbProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{links.map((link, index) => (
					<BreadcrumbItem key={`${link.name}-${index}`} $current={links.length - 1 === index}>
						<BrowserRouter>
							<NavLink to={link.value}>
								<BreadcrumbLink $current={links.length - 1 === index}>{link.name}</BreadcrumbLink>
							</NavLink>
						</BrowserRouter>
					</BreadcrumbItem>
				))}
			</Container>
		</ThemeProvider>
	);
}
