import { Data } from "../CardChart/types";
import light from "../../styles/themes/light";
import type { ComputedDatum } from "@nivo/bar";

import type { SmallChartCardProps } from "./types";

export default function useLogic({ data }: SmallChartCardProps) {
	const dataItems = data[0];
	const dataKeys: string[] = dataItems ? Object.keys(dataItems).slice(1) : [];
	const isSimple: boolean = dataKeys.length < 2;
	const colorPalette = [light.colors.purpleSunset400, light.colors.oceanicBlue200, light.colors.yellowBee200];

	const colors = [
		{
			original: light.colors.purpleSunset100,
			hover: light.colors.purpleSunset200,
			higher: light.colors.purpleSunset400
		},
		{
			original: light.colors.purpleSunset400,
			hover: light.colors.purpleSunset600
		},
		{
			original: light.colors.oceanicBlue200,
			hover: light.colors.oceanicBlue400
		},
		{
			original: light.colors.yellowBee200,
			hover: light.colors.yellowBee400
		},
		{
			original: light.colors.purpleSunset200,
			hover: light.colors.purpleSunset400
		}
	];

	const colorsLabel = [
		{
			original: light.colors.purpleSunset50,
			hover: light.colors.purpleSunset100
		},
		{
			original: light.colors.oceanicBlue50,
			hover: light.colors.oceanicBlue100
		},
		{
			original: light.colors.yellowBee50,
			hover: light.colors.yellowBee100
		}
	];

	const isGrouped: boolean = dataKeys.length > 1 ? true : false;
	const highestValue: number = Math.max(...data.map((el) => el.valueOne ?? 0));
	const highestBar = data.findIndex(({ valueOne }) => valueOne === highestValue);
	const isHighestBar = ({ index }: ComputedDatum<Data>) => index === highestBar;

	const getColor = (bar: ComputedDatum<Data>) => {
		if (isHighestBar(bar)) {
			return colors[0]?.higher ?? "";
		} else {
			return colors[0]?.original ?? "";
		}
	};

	// HorizontalBarFeaturedData
	const average = (arr: number[]) => arr && arr.reduce((acc, el) => acc + el, 0) / arr.length;

	const featuredDataArray: number[] = Object.values(data && data.map((el) => el.valueOne));
	const featuredData: string = featuredDataArray && average(featuredDataArray).toFixed(1);

	// HorizontalBarLabelInside and verticalBarReducedVersion
	let sum = 0;
	data.forEach((element) => {
		sum += element?.valueOne ?? 0;
	});

	return {
		dataKeys,
		isSimple,
		colorPalette,
		colors,
		isGrouped,
		highestValue,
		highestBar,
		isHighestBar,
		getColor,
		colorsLabel,
		featuredData,
		sum
	};
}
