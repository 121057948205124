import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Circle, CircleWrapper, Container, GreyCircle, Text, YellowCircle } from "./styles";
import { ProgressCircleProps } from "./types";

export function ProgressCircle({
	className,
	progress = 0,
	text = "Uploading...",
	ariaBusy,
	...props
}: ProgressCircleProps) {
	const value = 100 - Math.min(100, Math.max(-1, progress));

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container aria-busy={ariaBusy}>
				<CircleWrapper className={className} viewBox="22 22 44 44" {...props}>
					<GreyCircle cx={44} cy={44} r={20.2} strokeWidth={3.6} fill={"none"} />
					{progress >= 100 ? (
						<YellowCircle cx={44} cy={44} r={20.2} strokeWidth={3.6} fill={"none"} />
					) : progress === 0 ? null : (
						<Circle
							cx={44}
							cy={44}
							r={20.2}
							strokeWidth={3.6}
							fill={"none"}
							pathLength={100}
							strokeDashoffset={value}
							strokeDasharray={100}
						/>
					)}
				</CircleWrapper>
				<Text>{text}</Text>
			</Container>
		</ThemeProvider>
	);
}
