import { Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { ChevronDown } from "@carbon/icons-react";
import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled, { css } from "styled-components";

import { SelectSearchStyleProps } from "./types";

export const Container = styled.div``;

export const Label = styled(Small)<SelectSearchStyleProps>`
	display: inline-block;
	margin-block-end: 0.375rem;
	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral500};
		`}
`;

export const AutocompleteElement = styled(Autocomplete)<SelectSearchStyleProps>`
	height: 2rem;
	width: 10vw;
	color: ${({ theme }) => theme.colors.neutral600};
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;
	:hover {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	:focus-within {
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
	}
	& .MuiButtonBase-root {
		margin-block-start: 0.075rem;
		margin-inline-end: 0.225rem;
		:hover {
			background-color: transparent;
		}
	}
	& .MuiAutocomplete-input {
		padding-block: 0.425rem 0.5rem !important;
		font-family: ${({ theme }) => theme.fonts.main} !important;
		font-size: 0.875rem !important;
	}
	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral400};
			background-color: ${theme.colors.neutral100};
			& .MuiButtonBase-root {
				color: ${theme.colors.neutral400};
			}
		`}
`;

export const Input = styled(TextField)`
	/* Overridden styles */
	& .MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline:hover:not(.Mui-disabled):before,
	.MuiInput-underline:focus:not(.Mui-disabled):before,
	.MuiInput-underline.Mui-focused:before,
	.MuiInput-underline.Mui-disabled:before {
		border-bottom: none;
	}
	& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
		font-size: 1rem;
		padding-block: 0.625rem 0.5rem;
		padding-inline: 0.75rem 0.5rem;
		font-family: inherit;
		line-height: 150%;
	}
	& .MuiAutocomplete-option {
		min-height: unset;
	}
`;

export const Chevron = styled(ChevronDown)`
	width: 1.25rem;
	height: 1.25rem;
`;

export const Dropdown = styled(Paper)`
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	margin-block-start: 0.375rem;
	& .MuiAutocomplete-listbox {
		max-height: 15rem;
		${prettyScrollbar({})}
	}
	&.MuiPaper-root {
		background: ${({ theme }) => theme.colors.white};
		border-radius: 0.5rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
	& .MuiAutocomplete-option[data-focus="true"] {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	& .MuiAutocomplete-option {
		min-height: unset;
		font-size: 1rem;
		line-height: 1.5rem;
		padding-block: 0.625rem;
		padding-inline: 0.75rem;
	}
	.MuiAutocomplete-noOptions {
		text-align: center;
	}
`;
