import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import { Container, Date, Text, Title, TitleWrapper } from "./styles";
import type { MessageProps } from "./types";
export default function ActivityMessage({ className, comment }: MessageProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<TitleWrapper>
					<Title $bold>{comment.title}</Title>
					<Date>{comment.date}</Date>
				</TitleWrapper>
				<Text>{comment.text}</Text>
			</Container>
		</ThemeProvider>
	);
}
