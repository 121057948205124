import DefaultInputSearchGlobal from "../../InputSearchGlobal";
import { addAlpha } from "../../../utils/addAlpha";
import styled from "styled-components";

export const Backdrop = styled.div`
	position: fixed;
	z-index: 1;
	height: 100vh;
	padding: 0.875rem;
	background: ${({ theme }) => addAlpha(theme.colors.neutral900, 0.16)};
	inset: 0;
`;

export const InputSearchGlobal = styled(DefaultInputSearchGlobal)`
	width: 100%;
`;
