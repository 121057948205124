import { CustomBar } from "../CustomBar";
import CustomTooltip from "../CustomTooltip";
import useLogic from "../logic";
import { ResponsiveBar } from "@nivo/bar";

import { Container, Labels } from "./styles";
import type { SmallChartHorizontalFeaturedProps } from "./types";
export default function SmallChartHorizontalFeatured({
	className,
	data,
	chartType
}: SmallChartHorizontalFeaturedProps): JSX.Element {
	const { dataKeys, colors } = useLogic({ data });

	const colorsPalette: string[] = colors.slice(1).map((color) => color.original);

	return (
		<Container className={className}>
			<Labels>
				{data.map((el, index) => (
					<li key={index}>{el.item}</li>
				))}
			</Labels>
			<ResponsiveBar
				barComponent={(props) => <CustomBar {...props} chartType={chartType} />}
				colors={[...colorsPalette].reverse()}
				colorBy="indexValue"
				tooltip={CustomTooltip}
				onMouseEnter={(_data, event) => {
					const currentColor: string = event.currentTarget.attributes?.[4]?.value ?? "";

					const colorFound = colors.find(({ original }) => original === currentColor);
					const hoverColor = colorFound?.hover ?? "";
					event.currentTarget.style.fill = hoverColor;
				}}
				onMouseLeave={(_data, event) => {
					event.currentTarget.style.fill = "";
				}}
				data={[...data].reverse()}
				keys={dataKeys && dataKeys.map((el: string) => el)}
				indexBy="item"
				groupMode="grouped"
				layout="horizontal"
				margin={{ top: 0, right: 0, bottom: -17, left: 66 }}
				padding={0.2}
				innerPadding={0}
				valueScale={{ type: "linear" }}
				animate={false}
				enableLabel={false}
				enableGridY={false}
				gridYValues={2}
				borderRadius={4}
				axisLeft={null}
				axisBottom={null}
				theme={{
					textColor: "#908E9A",
					fontSize: 10,
					grid: {
						line: {
							stroke: "#E8E7ED",
							strokeWidth: 1
						}
					}
				}}
			/>
		</Container>
	);
}
