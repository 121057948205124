/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Checkbox from "../Checkbox";
import DatePicker from "../DatePicker";
import RowResults from "../RowResults";
import Sorting from "../Sorting";
import { Download, Help, Settings } from "@carbon/icons-react";

import AccordionRow from "./AccordionRow";
import Row from "./Row";
import { ActionHeaderCell, AllFiltersButton, ClearAllButton, Container, Content, DateHeaderCell, DownloadButton, Filters, FilterText, Header, HelpHeaderCell, IconHeaderCell, ItemNameHeaderCell, MainActionButton, NavigationWrapper, Pagination, Search, SecondaryInfoHeaderCell, SelectedFilters, SettingsHeaderCell, TableBody, TableContainer, TableHeader, TableHeaderRow, Text, CenterText, Wrapper, ItemNameSearchCell, NoData } from "./styles";
import type { TableProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import Select from "../Select";
import AppThemes from "../../styles/themes";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import moment from "moment";

export default function Table({ selectedFilters, buttonAction, checkboxOnChange, className, data, columns, showFilters, isSelectable, total, page, pageSize, handleEvent, isLoading, pagination = true, sticky = false }: TableProps): JSX.Element {
	const options = [
		{
			label: "Action menu item 1",
			onClick: () => alert("Item 1 clicked")
		},
		{
			label: "Item 2",
			onClick: () => alert("Item 2 clicked")
		},
		{
			label: "Item 3",
			onClick: () => alert("Item 3 clicked")
		}
	];

	// const [shownResults, setShownResults] = useState(pageSize);
	const [tableData, setTableData] = useState([]);
	const totalPages = Math.ceil(total / pageSize);

	// serching
	const [search, setSearch] = useState([]);
	// sorting
	const [sort, setSort] = useState({});
	// table event
	const [tableEvent, setTableEvent] = useState({
		page: 1,
		pageSize: 10,
		search: [],
		sort: {}
	});
	const headerStyle: React.CSSProperties = sticky ? { position: "sticky", top: 0, zIndex: 10, backgroundColor: "white" } : {};

	useEffect(() => {
		setTableData(data.map((d) => d).splice(0, pageSize));
	}, [data, pageSize]);

	function handleSort(status, column) {
		const sortObj = {
			field: column.field,
			status: status
		};

		setSort(sortObj);
		const event = {
			page: page,
			sort: sortObj,
			search: search,
			pageSize: pageSize
		};

		setTableEvent(event);

		// callback to parent to handle event
		handleEvent(event);
	}

	function handleSearch(value, column, operator) {
		let searchArray = search;
		const existingSearch = search.find((c) => c.field === column.field);
		if (value || value == 0) {
			// if the field is already exists in search then just need to
			// chnage its value instaed of adding as a new search.
			if (existingSearch) {
				existingSearch.value = value;
				existingSearch.operator = operator;
			} else {
				searchArray.push({
					field: column.field,
					value: value,
					operator: operator
				});
			}
		} else {
			// if search key is there in search, remove it
			// this is the case where user has searched something and then cleared the searchbox
			searchArray = searchArray.filter((element) => element.field !== column.field);
		}

		setSearch(searchArray);

		const event = {
			page: 1,
			sort: sort,
			pageSize: pageSize,
			search: searchArray
		};

		setTableEvent(event);

		// callback to parent to handle event
		handleEvent(event);
	}

	function handleDateFilter(value, column) {
		// Remove all objects where the 'field' property is equal to 'column'
		let dateArray = search.filter((val) => val.field !== column.field);

		if (value?.startDate || value?.endDate) {
			if (value?.endDate == null) {
				dateArray.push(
					{
						field: column.field,
						value: moment(value?.startDate).startOf("day").unix(),
						operator: "gte"
					},
					{
						field: column.field,
						value: moment(value?.startDate).endOf("day").unix(),
						operator: "lte"
					}
				);
			} else if (value?.endDate != null && value?.startDate != null) {
				dateArray.push(
					{
						field: column.field,
						value: moment(value?.startDate).startOf("day").unix(),
						operator: "gte"
					},
					{
						field: column.field,
						value: moment(value?.endDate).endOf("day").unix(),
						operator: "lte"
					}
				);
			}
		} else {
			// if search key is there in search, remove it
			// this is the case where user has searched something and then cleared the searchbox
			dateArray = dateArray.filter((element) => element.field !== column.field);
		}
		setSearch(dateArray);

		const event = {
			page: 1,
			sort: sort,
			pageSize: pageSize,
			search: dateArray
		};

		setTableEvent(event);

		// callback to parent to handle event
		handleEvent(event);
	}

	function handlePage(page) {
		let event = tableEvent;
		event = {
			...event,
			page: page,
			pageSize: pageSize
		};
		setTableEvent(event);
		// callback to parent to handle event
		handleEvent(event);
	}

	function handlePageSize(pageSize) {
		let event = tableEvent;
		event = {
			...event,
			pageSize: pageSize
		};
		setTableEvent(event);
		// callback to parent to handle event
		handleEvent(event);
	}

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Content>
					{showFilters && (
						<Header>
							<Search id="table-search" placeholder="Search by [item]..." data={["Text", "Other text", "Another text"]} />
							<Select
								small={true}
								name="select"
								options={[
									{
										id: "Port",
										label: "Port of Loading",
										value: "Port of Loading"
									},
									{
										id: "Madrid-1",
										label: "Madrid",
										value: "Madrid"
									}
								]}
								placeholder="Port of Loading"
							/>
							<Select
								small={true}
								name="select"
								options={[
									{
										id: "Port",
										label: "Port of Discharge",
										value: "Port of Discharge"
									},
									{
										id: "Madrid-1",
										label: "Madrid",
										value: "Madrid"
									}
								]}
								placeholder="Port of Discharge"
							/>
							{/* <DatePicker monthDays={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]} weekDays={["S", "M", "W", "T", "F", "S"]} firstDay={1} currentDay={new Date().getDay()} startDay={1} endDay={31} selected={new Date().getDay()} onClick={() => {}} options={[""]} /> */}
							{/* <AllFiltersButton size="small" variant="secondary" onClick={buttonAction} label="All filters" /> */}
							<ClearAllButton size="small" variant="no-bg" onClick={buttonAction} label="Clear All" />
							{/* <MainActionButton size="small" variant="primary" onClick={buttonAction} label="Main action" /> */}
							<DownloadButton size="small" icon={<Download />} variant="no-bg" onClick={buttonAction} ariaLabel="download button" />
						</Header>
					)}
					{selectedFilters != undefined && selectedFilters.length > 0 && (
						<SelectedFilters>
							<FilterText>Selected filters </FilterText>
							{selectedFilters.map((value) => (
								<Filters key={"tag" + value} label={value} backgroundColor="#EBE5FF" color="#29204B" hasCloseButton={true} />
							))}
						</SelectedFilters>
					)}
					<TableContainer>
						<TableHeader style={headerStyle}>
							<TableHeaderRow>
								{columns.map((c, i) => {
									c.sort = "default";
									return (
										<>
											<ItemNameHeaderCell width={c.width}>
												<Wrapper>
													{isSelectable && i === 0 && <Checkbox label="Header Checkbox" onChange={checkboxOnChange} indeterminate={true} disabled={false} id="header-checkbox" />}
													{c.isSortable ? <Sorting title={c.headerName} status={c.sort} ariaLabel="sorting icon" onClick={(status) => handleSort(status, c)} /> : <Text $bold>{c.headerName}</Text>}

													{c.tooltip && c.tooltip !== "" ? <Help size={16} /> : ""}
												</Wrapper>
											</ItemNameHeaderCell>
										</>
									);
								})}
							</TableHeaderRow>
							<TableHeaderRow>
								{columns.map((c, i) => {
									c.sort = "default";
									return (
										<ItemNameSearchCell width={c.width}>
											<Wrapper>
												{c.isSearchable &&
													(c.searchType ? (
														c.searchType.type == "selectbox" && (
															<Search
																id="table-search"
																placeholder="Search"
																data={c.searchType.options}
																handleInputChange={(event, value) => handleSearch(value?.key, c, c.searchType.operator)} // Here we are sending the selected options key for filtering
															/>
														)
													) : (
														<Search
															id="table-search"
															placeholder="Search"
															data={[]}
															handleInputChange={(event, value) => handleSearch(value, c, "like")} //Auto Crud is sutpporting like operator
														/>
													))}
												{c.isDateFilter && <DatePicker onClick={() => {}} handleDateChange={(value) => handleDateFilter(value, c)} monthDays={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]} weekDays={["S", "M", "W", "T", "F", "S"]} firstDay={1} currentDay={new Date().getDay()} startDay={1} endDay={31} selected={new Date().getDay()} options={[""]} />}
											</Wrapper>
										</ItemNameSearchCell>
									);
								})}
							</TableHeaderRow>
						</TableHeader>
						<TableBody>
							{isLoading === true && tableData.length <= 0 && (
								<td colSpan={columns.length}>
									<Skeleton
										sx={{
											transform: "none",
											height: "78vh",
											width: "100%",
											captionSide: "bottom",
											display: "flex",
											bgcolor: "${AppThemes.light.colors.neutral200}"
										}}
										animation="wave"
									/>
								</td>
							)}
							{tableData.length > 0 &&
								tableData.map((d) => {
									return <Row unseen={false} rowData={d} isSelectable={isSelectable} columns={columns} itemText="Unseen Item" />;
								})}

							{!isLoading && !tableData.length && (
								<NoData>
									<CenterText>No Data !</CenterText>
								</NoData>
							)}
						</TableBody>
					</TableContainer>
				</Content>
				{pagination && (
					<NavigationWrapper>
						<RowResults
							totalResults={total || data.length}
							shownResults={pageSize}
							current={page}
							onChangeshownResults={(shownResults) => {
								// setShownResults(shownResults);
								handlePageSize(shownResults);
							}}
						/>
						<Pagination
							current={page}
							total={totalPages}
							showShortcuts={true}
							active={false}
							onChangePage={(page: number) => {
								handlePage(page);
							}}
						/>
					</NavigationWrapper>
				)}
			</Container>
		</ThemeProvider>
	);
}
