import Checkbox from "../../assets/icons/Checkbox.svg";
import CheckboxChecked from "../../assets/icons/CheckboxChecked.svg";
import { Close } from "@carbon/icons-react";

import useLogic from "./logic";
import {
	Chevron,
	ClearAllButton,
	Container,
	EllipseTag,
	Label,
	Option,
	RenderArea,
	StyledCheckbox,
	StyledSelect,
	Tag,
	TagList,
	Text
} from "./styles";
import type { SelectCheckboxesProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function SelectCheckboxes({
	className,
	disabled,
	name,
	options,
	placeholder,
	label,
	onChange
}: SelectCheckboxesProps): JSX.Element {
	const { arrayValue, setArrayValue, shownTags, tagsListRef, clearAll } = useLogic();
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name} $isDisabled={!!disabled}>
					{label}
				</Label>
				<StyledSelect
					id={name}
					multiple
					defaultValue={placeholder}
					value={arrayValue}
					renderValue={() => (
						<RenderArea>
							<TagList ref={tagsListRef}>
								{(shownTags ? arrayValue.slice(0, shownTags) : arrayValue).map((v) => (
									<Tag
										containerAs="li"
										key={v}
										label={v}
										hasCloseButton
										onClose={() => {
											const newArrayValue = arrayValue.filter((val) => val !== v);
											setArrayValue(newArrayValue);
										}}
									/>
								))}
								{shownTags ? <EllipseTag label="..." /> : null}
							</TagList>
							{arrayValue.length > shownTags ? (
								<ClearAllButton onClick={clearAll}>
									<Close />
								</ClearAllButton>
							) : null}
						</RenderArea>
					)}
					onChange={(e) => {
						setArrayValue(e.target.value as string[]);
						if (onChange) {
							onChange(e.target.value as string[]);
						}
					}}
					disabled={disabled}
					IconComponent={Chevron}
					MenuProps={{
						disablePortal: true,
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						}
					}}
				>
					{options?.map(({ label: optionLabel, value: optionValue, disabled: disabledOption }) => (
						<Option key={optionValue} value={optionValue} disabled={disabledOption}>
							<StyledCheckbox
								icon={<Checkbox />}
								checkedIcon={<CheckboxChecked />}
								checked={arrayValue.includes(optionValue)}
							/>

							<Text primary={optionLabel} />
						</Option>
					))}
				</StyledSelect>
			</Container>
		</ThemeProvider>
	);
}
