import Tabs from "../Tabs";
import { IconContainer } from "../Tabs/styles";
import { TabLabel } from "../Tabs/Tab/styles";
import { Body } from "../Typography";
import styled from "styled-components";

export const Container = styled.div`
	padding-block-start: 1rem;
	display: flex;
	flex-direction: column;
	max-width: 23rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const Title = styled(Body)`
	padding-inline: 1rem;
`;

export const BoxTabs = styled(Tabs)`
	margin-block-end: 1.5rem;
	${TabLabel} {
		margin-inline: 0;
	}
	${IconContainer} {
		display: none;
	}
`;

export const TabsWrapper = styled.div``;

export const TabsHeader = styled.div`
	margin-block-start: 1rem;
	margin-inline: 1rem;
`;

export const TabsContent = styled.div``;
