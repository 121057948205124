import { Warning } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, Error, Helper, IconWrapper, Label, StyledInput } from "./styles";
import type { InputEmailProps } from "./types";
import { useState } from "react";

export default function InputEmail({ autoComplete, className, disabled, error, help, helpMessage, id, label, name, defaultValue, onBlur, onChange, onFocus, pattern, placeholder, readOnly, required, errorMessage }: InputEmailProps): JSX.Element {
	const [checkEmail, setCheckEmail] = useState(false);

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}
	const handleChange = (event) => {
		if (isValidEmail(event.target.value)) {
			setCheckEmail(false);
		} else {
			setCheckEmail(true);
		}
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name}>
					{label}
				</Label>
				<StyledInput
					$hasError={!!error}
					id={id}
					name={name}
					onKeyPress={(event) => {
						handleChange(event);
					}}
					defaultValue={defaultValue}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
					pattern={pattern}
					placeholder={placeholder}
					required={required}
					type="email"
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={disabled}
				/>

				{checkEmail && <Error role="alert">Email is invalid</Error>}
				{error ? (
					<IconWrapper $hasError={!!error}>
						<Warning />
					</IconWrapper>
				) : null}
				{error ? <Error role="alert">{errorMessage}</Error> : null}
				{help && !error ? <Helper>{helpMessage}</Helper> : null}
			</Container>
		</ThemeProvider>
	);
}
