import { useState } from "react";
import type { DropResult } from "react-beautiful-dnd";

import { DraggableListProps } from "./types";
export const useLogic = (list: DraggableListProps["list"] = []) => {
	const [items, setItems] = useState<string[]>(list);
	function handleDrop(result: DropResult) {
		if (!result.destination) {
			return;
		}
		if (result.destination.index === result.source.index) {
			return;
		}
		const updatedList = [...items];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(result.source.index, 1);
		// Add dropped item
		if (reorderedItem) {
			updatedList.splice(result.destination.index, 0, reorderedItem);
		}
		setItems(updatedList);
	}
	return { handleDrop, items, setItems };
};
