import { from } from "../../styles/responsive";
import useMedia from "../../utils/useMedia";
import { useState } from "react";

export const useLogic = (data: string[], quick: string[] | undefined, recent: string[] | undefined) => {
	const initialData =
		quick
			.map((label) => ({
				label,
				category: "Quick search suggestions"
			}))
			.concat(recent.map((label) => ({ label, category: "Recent searches" }))) || [];

	const formattedData = data.map((item) => ({ label: item, category: "" }));
	const [searching, setSearching] = useState(false);
	const [results, setResults] = useState(initialData);
	const isMobile = !useMedia(from["tabletPortrait"] as string);

	return {
		searching,
		setSearching,
		results,
		setResults,
		initialData,
		isMobile,
		formattedData
	};
};

export default useLogic;
