import styled, { css } from "styled-components";
import themes from "../../styles/themes";

export const H1 = styled.h1`
	font-weight: ${themes.light.weights.bold700};
	font-size: 2.25rem;
	line-height: 2.7rem;
	letter-spacing: -0.02em;
`;

export const H2 = styled.h2`
	font-weight: ${themes.light.weights.bold700};
	font-size: 2rem;
	line-height: 2.4rem;
	letter-spacing: -0.02em;
`;

export const H3 = styled.h3`
	font-weight: ${themes.light.weights.bold700};
	font-size: 1.75rem;
	line-height: 2.1rem;
	letter-spacing: -0.01em;
`;

export const H4 = styled.h4`
	font-weight: ${themes.light.weights.bold700};
	font-size: 1.5rem;
	line-height: 1.8rem;
`;

export const Title = styled.p<{ $bold?: boolean }>`
	font-weight: ${({ $bold }) => ($bold ? themes.light.weights.bold700 : themes.light.weights.regular400)};
	font-size: 1.25rem;
	line-height: 1.625rem;
`;

export const Body = styled.p<{ $bold?: boolean; $underline?: boolean }>`
	font-weight: ${({ theme, $bold }) => ($bold ? themes.light.weights.bold700 : themes?.light.weights?.regular400)};
	font-size: 1rem;
	line-height: 1.5rem;

	${({ $underline }) =>
		$underline &&
		css`
			text-decoration-line: underline;
		`}
`;

export const Small = styled.p<{ $bold?: boolean; $underline?: boolean }>`
	font-weight: ${({ theme, $bold }) => ($bold ? themes?.light.weights?.bold700 : themes.light?.weights?.regular400)};
	font-size: 0.875rem;
	line-height: 1.4rem;

	${({ $underline }) =>
		$underline &&
		css`
			text-decoration-line: underline;
		`}
`;

export const Caption = styled.p<{
	$bold?: boolean;
	$underline?: boolean;
}>`
	font-weight: ${({ theme, $bold }) => ($bold ? theme.weights.bold700 : theme.weights.regular400)};
	font-size: 0.75rem;
	line-height: 1.25rem;

	${({ $underline }) =>
		$underline &&
		css`
			text-decoration-line: underline;
		`}
`;

export const Overline = styled.p<{ $bold?: boolean }>`
	font-weight: ${({ theme, $bold }) => ($bold ? theme.weights.bold700 : theme.weights.regular400)};
	font-size: 0.625rem;
	line-height: 1rem;
	letter-spacing: 0.08em;
	text-transform: uppercase;
`;

export const Button = styled.span`
	font-weight: ${({ theme }) => theme.weights.medium500};
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.01em;
`;

export const ButtonSmall = styled.span`
	font-weight: ${({ theme }) => theme.weights.medium500};
	font-size: 0.875rem;
	line-height: 1.375rem;
	letter-spacing: 0.01em;
`;
