import { Body } from "../../Typography";
import { from } from "../../../styles/responsive";
import styled, { css } from "styled-components";

import { InfoboxCardStyleProps } from "./types";

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	width: 17rem;
	padding-inline: 1rem;
	padding-block: 1rem;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	break-inside: avoid;
	margin-block-end: 1rem;
`;

export const Title = styled(Body).attrs({
	as: "h3"
})`
	margin-block-end: 1.5rem;
`;

export const Content = styled.div<InfoboxCardStyleProps>`
	display: grid;
	gap: 1rem;
	${from.tabletPortrait} {
		${({ cols }) =>
			cols &&
			css`
				grid-template-columns: repeat(${cols}, 1fr);
			`}
	}

	${from.tabletPortrait} {
		${({ $hasTwoColumns }) =>
			$hasTwoColumns &&
			css`
				grid-template-columns: 1fr 1fr;
			`}
	}
`;
