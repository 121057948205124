/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FocusEvent, useCallback, useEffect, useRef, useState } from "react";

export default function useLogic() {
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);

	const onOpenDropdown = useCallback(() => {
		setIsOpenDropdown(true);
	}, [setIsOpenDropdown]);

	const onCloseDropdown = useCallback(() => {
		setIsOpenDropdown(false);
	}, [setIsOpenDropdown]);

	const onClickDropdownButton = useCallback(() => {
		if (isOpenDropdown) {
			onCloseDropdown();
		} else {
			onOpenDropdown();
		}
	}, [isOpenDropdown, onCloseDropdown, onOpenDropdown]);

	const onOptionsBlur = useCallback(
		(event: FocusEvent<HTMLDivElement>) => {
			if (!event.currentTarget.contains(event.relatedTarget)) {
				onCloseDropdown();
			}
		},
		[onCloseDropdown]
	);

	const ref = useRef<HTMLInputElement | null>();

	useEffect(() => {
		const handleClickOutside = ({ target }: MouseEvent) => {
			if (ref.current && !ref.current.contains(target as Node)) {
				onCloseDropdown();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
	}, [onCloseDropdown, ref]);

	return {
		isOpenDropdown,
		onOpenDropdown,
		onCloseDropdown,
		onClickDropdownButton,
		onOptionsBlur,
		ref
	};
}
