import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useReducer, useRef } from "react";

import { LogicProps } from "./types";

export const useLogic = ({ trigger }: LogicProps) => {
	const [showTooltip, setShowTooltip] = useReducer((current) => !current, false);

	const handleMouseEnter = () => {
		if (trigger === "click") return;
		setShowTooltip();
	};

	const handleMouseLeave = () => {
		if (trigger === "click") return;
		setShowTooltip();
	};

	const handleClick = () => {
		if (trigger !== "click") return;
		setShowTooltip();
	};

	const tooltipRef = useRef<HTMLDivElement>(null);
	useOnClickOutside({ ref: tooltipRef, handleClick });

	return {
		tooltipRef,
		showTooltip,
		handleMouseEnter,
		handleMouseLeave,
		handleClick,
		setShowTooltip
	};
};
