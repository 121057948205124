import { visuallyHidden } from "../../styles/mixins";
import styled from "styled-components";

export const Input = styled.input`
	${visuallyHidden}
`;

export const Label = styled.label`
	display: inline-flex;
	column-gap: 1ch;
`;

export const Circle = styled.span`
	position: relative;
	display: inline-block;
	width: 1.125rem;
	height: 1.125rem;
	padding-inline: 0.3125rem;
	padding-block: 0.3125rem;
	cursor: pointer;
`;

export const CheckMark = styled.span`
	position: absolute;
	width: 100%;
	height: 100%;
	border: 0.0625rem solid ${({ theme }) => theme.colors.neutral800};
	border-radius: 50%;
	inset-block-start: 0;
	inset-inline-start: 0;

	&:before {
		position: absolute;
		width: 0.5rem;
		height: 0.5rem;
		background-color: ${({ theme }) => theme.colors.neutral800};
		border-radius: 50%;
		transform: translate(-50%, -50%) scale(0);
		opacity: 0;
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
		content: "";
		inset-block-start: 50%;
		inset-inline-start: 50%;

		@media (prefers-reduced-motion) {
			transition: none;
		}
	}

	${Input}:checked + & {
		border: 0.0625rem solid ${({ theme }) => theme.colors.neutral800};
		&:before {
			transform: translate(-50%, -50%) scale(1);
			opacity: 1;
		}
	}

	${Input}:hover + & {
		background-color: rgba(16, 15, 22, 0.08);
	}

	${Input}:disabled + & {
		opacity: 0.36;
	}
`;

export const Text = styled.span<{ $visuallyHidden: boolean }>`
	${({ $visuallyHidden }) => ($visuallyHidden ? visuallyHidden : ``)}
`;
