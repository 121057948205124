import styled from "styled-components";

export const CenteredLoaderContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.transparent.transparent1200}; /* Semi-transparent background */
	backdrop-filter: blur(5px); /* Apply a blur effect to the background */
	z-index: 999; /* Ensure the loader is on top of other content */
`;

export const StyledHexagonLoader = styled.svg`
	transform: rotate(90deg);
	.hex {
		fill: ${({ theme }) => theme.colors.yellowBee200};
		animation: fadein 2.2s infinite;
		opacity: 0;
		transform-origin: 50% 50%;
	}

	.hex:nth-child(1) {
		animation-delay: 0.2s;
	}

	.hex:nth-child(2) {
		animation-delay: 0.4s;
	}

	.hex:nth-child(3) {
		animation-delay: 0.6s;
	}

	.hex:nth-child(4) {
		animation-delay: 0.8s;
	}

	.hex:nth-child(5) {
		animation-delay: 1s;
	}

	.hex:nth-child(6) {
		animation-delay: 0s;
	}

	.hex:nth-child(7) {
		animation-delay: 1.2s;
	}

	@keyframes fadein {
		0%,
		100% {
			// transform: scale(0.001, 0.001);
			opacity: 0;
		}

		33%,
		66% {
			transform: scale(0.9, 0.9);
			opacity: 1;
		}
	}
`;
