import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import { BoxInput, Container, Message, SubmitButton, Wrapper, WrapperInput } from "./styles";
import type { CommentsTabProps } from "./types";

export default function CommentsTab({ className, comments }: CommentsTabProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<WrapperInput>
					<BoxInput
						placeholder="Add a comment"
						help={false}
						disabled={false}
						error={false}
						id="add-comment"
						name="add-comment"
					/>
					<SubmitButton label="Add" variant="secondary" size="small" />
				</WrapperInput>
				<Wrapper>
					{comments.map((comment) => (
						<Message key={comment.id} comment={comment} />
					))}{" "}
				</Wrapper>
			</Container>
		</ThemeProvider>
	);
}
