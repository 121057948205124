import { useState } from "react";

type AccordionProps = {
	id: number;
	isHidden: boolean;
};

export const useLogic = (accordionIds: number[]) => {
	const [hiddenAccordion, setAccordionHidden] = useState<AccordionProps[]>(
		accordionIds.map((value: number) => ({ id: value, isHidden: false }))
	);

	function onClickAccordion(id: number) {
		return () => {
			setAccordionHidden(
				hiddenAccordion.map((value: AccordionProps) =>
					value.id === id ? { ...value, isHidden: !value.isHidden } : { ...value }
				)
			);
		};
	}

	function getAccordionHidden(id: number) {
		const accordion = hiddenAccordion.filter((value: AccordionProps) => value.id === id)[0];
		return accordion ? accordion.isHidden : false;
	}

	return { onClickAccordion, hiddenAccordion, getAccordionHidden };
};
