import light from "../../styles/themes/light";
import type { ComputedDatum } from "@nivo/bar";

import { Data } from "../CardChart/types";
import type { CardChartProps } from "./types";

export default function useLogic({ data }: CardChartProps) {
	const truncateText = (text: string) => (text.length > 16 ? `${text.substring(0, 13)}...` : text);

	const dataTruncate = data.map((object?) => ({
		...object,
		item: truncateText(object.item as string)
	}));

	const dataItems = data[0];
	const dataKeys: string[] = dataItems ? Object.keys(dataItems).slice(1) : [];
	const isSimple: boolean = dataKeys.length < 2;
	const colorPalette = [light.colors.purpleSunset400, light.colors.oceanicBlue200, light.colors.yellowBee200];

	const colors = [
		{
			original: light.colors.purpleSunset100,
			hover: light.colors.purpleSunset200,
			higher: light.colors.purpleSunset400
		},
		{
			original: light.colors.purpleSunset400,
			hover: light.colors.purpleSunset600
		},
		{
			original: light.colors.oceanicBlue200,
			hover: light.colors.oceanicBlue400
		},
		{
			original: light.colors.yellowBee200,
			hover: light.colors.yellowBee400
		}
	];

	const isGrouped: boolean = dataKeys.length > 1 ? true : false;
	const highestValue: number = Math.max(...data.map((el) => el.valueOne ?? 0));
	const highestBar = data.findIndex(({ valueOne }) => valueOne === highestValue);
	const isHighestBar = ({ index }: ComputedDatum<Data>) => index === highestBar;

	const getColor = (bar: ComputedDatum<Data>) => {
		if (isHighestBar(bar)) {
			return colors[0]?.higher ?? "";
		} else {
			return colors[0]?.original ?? "";
		}
	};

	return {
		dataTruncate,
		truncateText,
		dataKeys,
		isSimple,
		colorPalette,
		colors,
		isGrouped,
		highestValue,
		highestBar,
		isHighestBar,
		getColor
	};
}
