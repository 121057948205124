import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-inline: 1rem;
	padding-block: 1rem;
	overflow: hidden;
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	border-radius: 0.5rem;
`;
