import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import Button from "../Button";

import { Container, Text, Title } from "./styles";
import type { TemplateProps } from "./types";
export default function TemplateCard({
	className,
	title,
	titleAs,
	text,
	buttonLabel,
	buttonOnClick
}: TemplateProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Title $bold as={titleAs}>
					{title}
				</Title>
				<Text>{text}</Text>
				<Button label={buttonLabel} size="small" variant="primary" onClick={buttonOnClick} />
			</Container>
		</ThemeProvider>
	);
}
