import { useEffect } from "react";

type Props = {
	ref: React.RefObject<Element>;
	handleClick: () => void;
	isClose?: boolean;
};

export const useOnClickOutside = ({ ref, handleClick, isClose }: Props) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (isClose) {
				if (event.target instanceof Element && ref.current && !ref.current.contains(event.target)) {
					handleClick();
				}
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handleClick]);
};
