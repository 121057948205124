import Button from "../Button";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DatePickerSelect from "./DatePickerSelect";
import { ButtonsWrapper, ChevronLeftIcon, ChevronRightIcon, Container, Footer, Header, Section, Tag, Tags, Title, Wrapper } from "./styles";
import type { DatePickerProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import moment from "moment";

export default function DatePickerCalendar({ className, handleDateChange, onClick }: DatePickerProps): JSX.Element {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([moment().toDate(), null]);
	const [startDate, endDate] = dateRange;
	const [isOpen, setIsOpen] = useState(false);
	const startRef = useRef<DatePicker>();

	const onChange = (update: [Date | null, Date | null]) => {
		setDateRange(update);
	};

	const customInput = <DatePickerSelect isOpen={isOpen} />;

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Wrapper>
					<DatePicker
						ref={startRef}
						onChange={onChange}
						onCalendarClose={() => setIsOpen(false)}
						onCalendarOpen={() => setIsOpen(true)}
						selectsStart
						selectsEnd
						placeholderText="Created"
						selected={startDate}
						monthsShown={2}
						selectsRange={true}
						startDate={startDate}
						endDate={endDate}
						shouldCloseOnSelect={false}
						customInput={customInput}
						renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth, customHeaderCount }) => (
							<Header>
								<button aria-label="Previous Month" style={customHeaderCount === 1 ? { visibility: "hidden" } : null} onClick={decreaseMonth}>
									<span>
										<ChevronLeftIcon />
									</span>
								</button>

								<Title $bold>
									{monthDate.toLocaleString("en-US", {
										month: "long",
										year: "numeric"
									})}
								</Title>
								<button aria-label="Next Month" style={customHeaderCount === 0 ? { visibility: "hidden" } : null} onClick={increaseMonth}>
									<span>
										<ChevronRightIcon />
									</span>
								</button>
							</Header>
						)}
					>
						<Footer>
							{/* <Section>
								Or select a predefined time frame
								<Tags>
									<Tag small={true} label="Today" onClick={onClick} />
									<Tag small={true} label="This week" onClick={onClick} />
									<Tag small={true} label="Last week" onClick={onClick} />
									<Tag small={true} label="This month" onClick={onClick} $active />
									<Tag small={true} label="Last month" onClick={onClick} />
									<Tag small={true} label="This year" onClick={onClick} />
									<Tag small={true} label="Last year" onClick={onClick} />
								</Tags>
							</Section> */}
							<ButtonsWrapper>
								<Button
									href=""
									label="Clear selection"
									size="small"
									variant="secondary"
									onClick={() => {
										setDateRange([null, null]);
										handleDateChange(null);
									}}
								/>
								<Button
									href=""
									label="Done"
									size="small"
									onClick={() => {
										handleDateChange(startRef?.current?.props);
										startRef?.current?.setOpen(false);
									}}
								/>
							</ButtonsWrapper>
						</Footer>
					</DatePicker>
				</Wrapper>
			</Container>
		</ThemeProvider>
	);
}
