import { Caption, Small } from "../../Typography";
import styled from "styled-components";

export const Container = styled.div`
	width: 33rem;
	height: 14.5rem;
	margin-block-end: 1.5rem;
`;
export const WrapperTooltip = styled.div`
	position: absolute;
	inset-inline-end: 0;
	inset-block-end: 0.9375rem;
	min-width: 6.25rem;
`;

export const Card = styled.div`
	min-width: 1rem;
	min-height: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const Item = styled(Caption)``;

export const Value = styled(Small)``;
