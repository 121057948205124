import { Caption } from "../Typography";
import styled from "styled-components";

import { $ContainerProps } from "./types";

export const Container = styled.button<$ContainerProps>`
	display: flex;
	gap: 0.4688rem;
	line-height: 1.25rem;
	> div > svg {
		color: ${({ $status, theme }) => ($status === "default" ? theme.colors.neutral400 : theme.colors.neutral900)};
	}
	&:hover > div > svg {
		color: ${({ theme }) => theme.colors.neutral900};
	}
`;

export const Text = styled(Caption)``;

export const IconContainer = styled.div``;
