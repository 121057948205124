import IconButton from "../IconButton";
import { Portal } from "../Portal";
import { Close } from "@carbon/icons-react";
import FocusTrap from "focus-trap-react";
import React from "react";

import { useLogic } from "./logic";
import { Backdrop, Container, ContentWrapper, Header, ModalWrapper, Section, Title, TitleContainer } from "./styles";
import { ModalProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export const Modal = ({ title, className, isOpen, size, children, onClose, isClose }: ModalProps) => {
	const { modalRef } = useLogic({ isOpen, onClose, isClose });

	if (!isOpen) {
		return null;
	}

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Portal wrapperId="modal">
				<FocusTrap active={isOpen} focusTrapOptions={{ tabbableOptions: { displayCheck: "none" } }}>
					<Backdrop className={className} data-testid="backdrop">
						<Container role="dialog" arial-modal="true" aria-label={title ?? "Modal"} ref={modalRef} $size={size}>
							<ModalWrapper>
								<Section>
									{isClose && (
										<Header data-testid="header">
											<TitleContainer>{title && <Title $bold>{title}</Title>}</TitleContainer>
											<IconButton
												onClick={onClose}
												icon={<Close />}
												ariaLabel="Close button"
												variant="no-bg"
												id="close-button"
												size="default"
											/>
										</Header>
									)}
									<ContentWrapper $size={size}>{children}</ContentWrapper>
								</Section>
							</ModalWrapper>
						</Container>
					</Backdrop>
				</FocusTrap>
			</Portal>
		</ThemeProvider>
	);
};

export default Modal;
