import { OverflowMenuVertical } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import useLogic from "./logic";
import { Button, Container, DropdownItem, DropdownWrapper } from "./styles";
import type { DropdownButtonProps } from "./types";

export default function DropdownButton({
	className,
	options = [],
	position = "right",
	size = "default",
	customIcon
}: DropdownButtonProps): JSX.Element {
	const { isOpenDropdown, onCloseDropdown, onClickDropdownButton } = useLogic();

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Button
					icon={customIcon || <OverflowMenuVertical />}
					onClick={(event) => {
						event.stopPropagation();
						onClickDropdownButton();
					}}
					size="tiny"
					ariaLabel={isOpenDropdown ? "close dropdown menu" : "open dropdown menu"}
				/>

				<DropdownWrapper $open={isOpenDropdown} $position={position} $size={size}>
					{options
						.filter((x) => !x.hide)
						.map(({ label, icon, onClick: onClickOption }, index) => (
							<DropdownItem
								key={index}
								label={label}
								icon={icon}
								onClick={(event) => {
									event.stopPropagation();
									onCloseDropdown();
									onClickOption(event);
								}}
							/>
						))}
				</DropdownWrapper>
			</Container>
		</ThemeProvider>
	);
}
