import DefaultSelect from "../Select";
import { Label, StyledSelect } from "../Select/styles";
import { Small } from "../Typography";
import styled from "styled-components";
export const Container = styled.div`
	display: flex;
	gap: 1.5rem;
	align-items: center;
	width: 100%;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const Results = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;
export const Span = styled.span`
	margin-inline-start: 0.5rem;
`;

export const RowsPerPage = styled.div`
	@media (max-width: 800px) {
		margin-top: -15px;
	}
`;

export const Select = styled(DefaultSelect)`
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	width: 100%;

	${StyledSelect} {
		width: 4.5rem;
		max-height: 2.25rem;
		color: ${({ theme }) => theme.colors.neutral900};
		font-size: 0.875rem;
		line-height: 1.375rem;
		> svg {
			flex-shrink: 0;
		}
		& .MuiSelect-select {
			padding-block: 0.4375rem;
			padding-inline: 0.5rem;
		}
		& .MuiSelect-icon {
			inset-inline-end: 0.625rem;
		}
	}

	${Label} {
		flex-shrink: 0;
		color: ${({ theme }) => theme.colors.neutral600};
		font-weight: ${({ theme }) => theme.weights.regular400};
		margin-block-end: unset;
	}
`;
