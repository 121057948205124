import DefaultIconButton from "../../IconButton";
import DefaultProgressBar from "../../ProgressBar";
import styled, { css } from "styled-components";

import type { StyledProps } from "./types";

export const Container = styled.div``;

export const Wrapper = styled.div<StyledProps>`
	width: 24.375rem;
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.75rem;

	${({ isDragActive, theme }) =>
		isDragActive &&
		css`
			border: 2px solid ${theme.colors.yellowBee200};
		`}
	${({ isDragAccept, theme }) =>
		isDragAccept &&
		css`
			border: 2px solid ${theme.colors.yellowBee200};
		`}
  ${({ isDragReject, theme }) =>
		isDragReject &&
		css`
			border: 2px solid ${theme.colors.supportError400};
		`}
`;

export const DropContent = styled.div``;

export const Row = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	padding-inline: 1rem;
	padding-block: 0.75rem;
	border-block-end: 1px solid ${({ theme }) => theme.colors.neutral200};
	color: ${({ theme }) => theme.colors.neutral900};
`;

export const LoadingContainer = styled(Row)`
	border-block-end: 0;
`;

export const ProgressBar = styled(DefaultProgressBar)``;

export const IconButton = styled(DefaultIconButton)`
	height: fit-content;
	padding: 0;
	margin-inline-start: auto;
	color: ${({ theme }) => theme.colors.supportError400};

	&:hover {
		background-color: transparent;
		opacity: 0.8;
	}
`;
