import { Body, Small } from "../Typography";
import styled from "styled-components";

export const Container = styled.div`
	flex-direction: column;
	max-width: 17rem;
	padding-inline: 1rem;
	padding-block: 1rem;
	border-radius: 12px;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const Title = styled(Body).attrs({
	as: "h3"
})`
	margin-block-end: 0.5rem;
`;

export const Text = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
	margin-block-end: 1rem;
`;
