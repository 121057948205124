import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Circle, CircleWrapper, Container, GreyCircle, Percentage, RingWrapper, Title } from "./styles";
import { RingChartProps } from "./types";

export default function RingChart({ className, percentage = 0, ariaBusy, ...props }: RingChartProps) {
	const stroke = 100 - Math.min(100, Math.max(-1, percentage));
	const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(value, max));
	const batteryStatus = percentage <= 25 ? "low" : percentage <= 50 ? "half" : "full";

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<Title $bold>Battery</Title>
				<RingWrapper aria-busy={ariaBusy}>
					<CircleWrapper className={className} viewBox="22 22 44 44" {...props}>
						<GreyCircle cx={44} cy={44} r={20.2} strokeWidth={3.6} fill={"none"} />
						<Circle
							cx={44}
							cy={44}
							r={20.2}
							strokeWidth={3.6}
							fill={"none"}
							pathLength={100}
							strokeDashoffset={stroke}
							strokeDasharray={100}
							$battery={batteryStatus}
						/>
					</CircleWrapper>
					<Percentage>{clamp(percentage, 0, 100)}%</Percentage>
				</RingWrapper>
			</Container>
		</ThemeProvider>
	);
}
