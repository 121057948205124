import { View, ViewOff } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import useLogic from "./logic";
import { Container, Error, Helper, Label, StyledInput, ToggleButton } from "./styles";
import type { InputPasswordProps } from "./types";

export default function InputPassword({
	autoComplete,
	className,
	disabled,
	error,
	help,
	helpMessage,
	id,
	label,
	name,
	onBlur,
	onChange,
	onFocus,
	pattern,
	placeholder,
	readOnly,
	required,
	errorMessage
}: InputPasswordProps): JSX.Element {
	const { showPassword, togglePasswordVisibility } = useLogic();
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name}>
					{label}
				</Label>
				<StyledInput
					$hasError={!!error}
					id={id}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
					pattern={pattern}
					placeholder={placeholder}
					required={required}
					type={showPassword ? "text" : "password"}
					autoComplete={autoComplete}
					readOnly={readOnly}
					disabled={disabled}
				/>
				<ToggleButton disabled={disabled} onClick={togglePasswordVisibility} $hasError={error}>
					{!showPassword ? <ViewOff aria-label="View password" /> : <View aria-label="Hide password" />}
				</ToggleButton>

				{error ? <Error role="alert">{errorMessage}</Error> : null}
				{help && !error ? <Helper>{helpMessage}</Helper> : null}
			</Container>
		</ThemeProvider>
	);
}
