import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import { Container, Content, Title } from "./styles";
import type { InfoboxCardProps } from "./types";

export default function InfoboxCard({
	className,
	title,
	containerAs,
	children,
	hasTwoColumns,
	cols
}: InfoboxCardProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container as={containerAs} className={className}>
				{title ? <Title $bold>{title}</Title> : ""}
				{/* <Title $bold>{title}</Title> */}
				<Content $hasTwoColumns={hasTwoColumns} cols={cols}>
					{children}
				</Content>
			</Container>
		</ThemeProvider>
	);
}
