/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "../../Button";
import DropdownButton from "../../DropdownButton";
import Tag from "../../Tag";
import { Add, CircleSolid, Warning } from "@carbon/icons-react";

import {
	ActionRowCell,
	// Checkbox,
	// DateRowCell,
	// DropdownButtonWrapper,
	// HelpRowCell,
	// IconRowCell,
	ItemNameRowCell,
	RowContainer,
	// SecondaryInfoRowCell,
	// SettingsRowCell,
	Text
	// Unseen,
	// Wrapper
} from "./styles";
import type { RowProps } from "./types";
// import Checkbox from "../../Checkbox";
export default function Row({
	// checkboxOnChange,
	className,
	unseen,
	// itemText,
	// secondaryInfoText,
	// data,
	// tagColor,
	// backgroundTagColor,
	// tagLabel,
	// unseenAriaLabel,
	// onClickButton,
	// options,
	// checkboxId,
	// isSelectable,
	rowData,
	columns
}: RowProps): JSX.Element {
	return (
		<>
			<RowContainer className={className}>
				{columns.map((c, i) => {
					if (c.renderCell) {
						return (
							<ItemNameRowCell width={c.width} key={i}>
								{c.renderCell({ row: rowData, value: rowData[c.field] })}
							</ItemNameRowCell>
						);
					} else if (c.hasAction) {
						return (
							<ActionRowCell width={c.width} key={i}>
								<Button size="small" variant="no-bg" label={rowData[c.field]} onClick={() => c.onActionClick(rowData)} />
							</ActionRowCell>
						);
					} else {
						return (
							<ItemNameRowCell width={c.width} key={i}>
								<Text $bold={unseen}>{rowData[c.field]}</Text>
							</ItemNameRowCell>
						);
					}
				})}
				{/* <ItemNameRowCell>
					<Wrapper>
						<Checkbox
							label="Row Checkbox"
							onChange={checkboxOnChange}
							indeterminate={false}
							disabled={false}
							id={checkboxId}
						/>
						{unseen && (
							<Unseen aria-label={unseenAriaLabel}>
								{" "}
								<CircleSolid />
							</Unseen>
						)}
						<Text $bold={unseen}>{itemText}</Text>
					</Wrapper>
				</ItemNameRowCell>
				<SecondaryInfoRowCell>
					<Text>{secondaryInfoText}</Text>
				</SecondaryInfoRowCell>
				<ActionRowCell>
					<Button size="small" variant="no-bg" label="Action" onClick={onClickButton} icon={<Add />} />
				</ActionRowCell>
				<HelpRowCell>
					<Tag label={tagLabel} color={tagColor} backgroundColor={backgroundTagColor} />
				</HelpRowCell>
				<IconRowCell>
					<Warning size={16} />
				</IconRowCell>
				<DateRowCell>
					<Text>{data}</Text>
				</DateRowCell>
				<SettingsRowCell>
					<DropdownButtonWrapper>
						<DropdownButton options={options} position="left" />
					</DropdownButtonWrapper>
				</SettingsRowCell> */}
			</RowContainer>
		</>
	);
}
