import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import { Container, Message, Wrapper } from "./styles";
import type { HistoryTabProps } from "./types";

export default function HistoryTab({ className, messages }: HistoryTabProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Wrapper>{messages ? messages.map((message) => <Message key={message.id} comment={message} />) : null}</Wrapper>
			</Container>
		</ThemeProvider>
	);
}
