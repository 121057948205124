import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import "./font.css";
import themes from "./themes";

const GlobalStyle = createGlobalStyle`
  ${normalize}  
  * {
    box-sizing: border-box;
  }
  input::-moz-focus-inner {
    margin: 0;
    padding: 0;
    border: 0;
  }
  ul,
  li,
  ol,
  dd,
  dl {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    overflow-wrap: break-word;
  }
  p {
    margin: 0;
    overflow-wrap: break-word;
  }
  a {
		text-decoration: none !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
  cite {
    font-style: normal;
  }
  fieldset {
    margin: 0;
    padding: 0;
    border-width: 0;
  }
  button {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
  }
  html {
    scroll-behavior: smooth;
    @media (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
      transition: none;
    }
  }
  body {
    color: ${themes.light.colors.neutral900};
    background: ${themes.light.colors.neutral100} 0% 0% no-repeat padding-box;
    
    font-family: ${themes.light.fonts.main}, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #__next {
    display: flex;
    min-height: 100vh;
    
    > * {
      flex: 1;
    }
  }
  .mapboxgl-canvas {
    box-shadow: 0px 4px 8px #100f1629;
	  border-radius: 8px;
   }
`;

export default GlobalStyle;
