import { Title as DefaultTitle } from "../Typography";
import { from } from "../../styles/responsive";
import { addAlpha } from "../../utils/addAlpha";
import styled, { css } from "styled-components";

import { $SizeProps } from "./types";

export const Backdrop = styled.div`
	position: fixed;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: ${({ theme }) => addAlpha(theme.colors.neutral900, 0.4)};
	inset: 0;
`;

export const Container = styled.div<$SizeProps>`
	display: flex;
	width: 100%;
	max-height: calc(100vh - 5rem);
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow24};

	${({ $size }) => {
		switch ($size) {
			case "s":
				return css`
					${from["tabletPortrait"]} {
						width: 60%;
					}
					${from["tabletLandscape"]} {
						width: 42%;
					}
					${from["laptop"]} {
						width: 36%;
					}
				`;
			case "m":
				return css`
					${from["tabletPortrait"]} {
						width: 84%;
					}
					${from["tabletLandscape"]} {
						width: 60%;
					}
					${from["laptop"]} {
						width: 60%;
					}
				`;
			case "l":
				return css`
					${from["tabletPortrait"]} {
						width: 96%;
					}
					${from["tabletLandscape"]} {
						width: 84%;
					}
					${from["laptop"]} {
						width: 72%;
					}
				`;
			case "xs":
			default:
				return css`
					${from["tabletPortrait"]} {
						width: 48%;
					}
					${from["tabletLandscape"]} {
						width: 32%;
					}
					${from["laptop"]} {
						width: 24%;
					}
				`;
		}
	}}
`;

export const ModalWrapper = styled.div`
	display: flex;
	flex: 1;
`;

export const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	padding-inline: 1rem;
	padding-block: 1.25rem;
`;

export const TitleContainer = styled.div`
	flex: 1;
`;

export const Title = styled(DefaultTitle)`
	margin-inline-end: 1rem;
`;

export const ContentWrapper = styled.div<$SizeProps>`
	overflow: auto;
	padding-block: 0 1rem;
	padding-inline: 1rem;

	${({ $size }) =>
		$size !== "xs" &&
		css`
			${from["laptop"]} {
				padding-block: 0 1.5rem;
				padding-inline: 1.5rem;
			}
		`}
`;
