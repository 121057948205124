import styled, { css } from "styled-components";

import { TooltipPosition } from "./types";

const TOOLTIP_DEFAULT_DISTANCE = "0.75rem";

export const Container = styled.div`
	position: relative;
`;

export const ContentWrapper = styled.div`
	position: relative;
	min-width: 1rem;
	min-height: 1rem;
	white-space: nowrap;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const TooltipWrapper = styled.div<{
	position: TooltipPosition;
	offset?: string;
}>`
	position: absolute;
	padding-inline: 0.5rem;
	padding-block: 0.5rem;
	color: ${({ theme }) => theme.colors.neutral900};
	font-size: 0.75rem;
	text-transform: none;
	background-color: transparent;
	border-radius: 0.5rem;
	inset-inline-start: 50%;
	${({ position, offset }) => {
		switch (position) {
			case "top":
				return css`
					inset-block-end: calc(100% + ${offset ?? TOOLTIP_DEFAULT_DISTANCE});
					transform: translateX(-50%);
					transform-origin: bottom;
					${ContentWrapper}:after {
						inset-block-start: 100%;
					}
				`;
			case "bottom":
				return css`
					inset-block-start: calc(100% + ${offset ?? TOOLTIP_DEFAULT_DISTANCE});
					transform: translateX(-50%);
					${ContentWrapper}:after {
						inset-block-end: 100%;
					}
				`;
		}
	}}
`;
