import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Alert, AlertList, Button, Container, Heading, Select, Title } from "./styles";
import type { AlertsCardProps } from "./types";
export default function AlertsCard({ className, alertList, options }: AlertsCardProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Heading>
					<Title $bold>Alerts</Title>
					<Button label="View all" />
				</Heading>
				<Select name="alert-select" options={options} small={false} />
				<AlertList>
					{alertList.map((item, idx) => (
						<Alert key={idx} alert={item} />
					))}
				</AlertList>
			</Container>
		</ThemeProvider>
	);
}
