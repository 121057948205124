import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import useLogic from "./logic";

import SmallChartColumnBars from "./SmallChartColumnBars";
import SmallChartHorizontalFeatured from "./SmallChartHorizontalFeatured";
import SmallChartHorizontalLabel from "./SmallChartHorizontalLabel";
import { ChartWrapper, Container, GrowthIcon, Header, MainValue, Title, Trend } from "./styles";
import type { SmallChartCardProps } from "./types";
export default function SmallChartCard({
	className,
	data,
	title,
	trend,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	mainValue,
	chartType
}: SmallChartCardProps): JSX.Element {
	const formatNumber = new Intl.NumberFormat("en-US");

	const { featuredData, sum } = useLogic({ data });

	const printValue = (chart?: string) => {
		switch (chart) {
			case "verticalBar":
				return (
					<>
						{sum && formatNumber.format(sum)}
						<Trend>
							<GrowthIcon />
							{trend}%
						</Trend>
					</>
				);
			case "horizontalLabel":
				return <>{sum}</>;
			case "horizontalFeatured":
			default:
				return <>{featuredData} h</>;
		}
	};

	const printComponent = (chart?: string) => {
		switch (chart) {
			case "verticalBar":
				return <SmallChartColumnBars data={data} chartType={chart} />;
			case "horizontalLabel":
				return <SmallChartHorizontalLabel data={data} chartType={chart} />;
			case "horizontalFeatured":
			default:
				return (
					<SmallChartHorizontalFeatured
						data={data}
						chartType={(chart || "horizontalFeatured") as SmallChartCardProps["chartType"]}
					/>
				);
		}
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Header>
					<Title $bold>{title}</Title>
					<MainValue>{printValue(chartType)}</MainValue>
				</Header>
				<ChartWrapper>{printComponent(chartType)}</ChartWrapper>
			</Container>
		</ThemeProvider>
	);
}
