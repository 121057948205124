import { RefObject, useEffect, useRef, useState } from "react";

export default function useLogic(): {
	shownTags: number;
	arrayValue: string[];
	setArrayValue: (value: string[]) => void;
	tagsListRef: RefObject<HTMLUListElement>;
	clearAll: () => void;
} {
	const tagsListRef = useRef<HTMLUListElement>(null);
	const [shownTags, setShownTags] = useState(0);
	const [arrayValue, setArrayValue] = useState<string[]>([]);
	const clearAll = () => {
		setArrayValue([]);
	};
	useEffect(() => {
		if (tagsListRef.current) {
			if (tagsListRef.current.scrollWidth > tagsListRef.current.clientWidth) {
				if (tagsListRef.current.scrollWidth - tagsListRef.current.clientWidth > 32) {
					setShownTags(Math.max(arrayValue.length - 1, 1));
				} else {
					setShownTags(Math.max(arrayValue.length - 2, 1));
				}
			} else if (arrayValue.length <= shownTags) {
				setShownTags(0);
			}
		}
	}, [arrayValue, shownTags]);

	return { arrayValue, setArrayValue, shownTags, tagsListRef, clearAll };
}
