import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import { AppThemes } from "../../roambee-client-styleguide";
import { Container } from "@material-ui/core";
import { CloseButton, CloseIconWrapper, Content, ContentTitle, ContentWrapper, RightPanel } from "./styles";

type DrawerProps = {
	children: ReactNode;
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
};

export default function DrawerPanel({ children, open, setOpen, title }: DrawerProps): JSX.Element {
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<RightPanel variant="persistent" anchor="right" open={open}>
					<CloseIconWrapper>
						<CloseButton onClick={toggleDrawer} size={20} />
					</CloseIconWrapper>
					<ContentWrapper>
						<ContentTitle $bold>{title}</ContentTitle>
						<Content>{children}</Content>
					</ContentWrapper>
				</RightPanel>
			</Container>
		</ThemeProvider>
	);
}
