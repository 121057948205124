import Button from "../../Button";
import ActivityMessage from "../../CommentBox/Message";
import InputText from "../../InputText";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperInput = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 1rem;
	padding-block-end: 1.5rem;
`;

export const BoxInput = styled(InputText)`
	justify-content: center;
	width: 17rem;
	min-height: 0;
`;

export const SubmitButton = styled(Button)`
	margin-block-start: 0.4375rem;
`;

export const Wrapper = styled.ul``;

export const Message = styled(ActivityMessage)`
	:not(:last-of-type) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
	}
	:last-of-type {
		border-radius: 0 0 0.75rem 0.75rem;
	}
`;
