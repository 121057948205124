import { BatteryFull, ConnectionSignal, Unlocked } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Card, Container, Info } from "./styles";
import type { InfoboxProps } from "./types";
export default function Infobox({ className }: InfoboxProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Card title="Overview">
					<Info data="Estimated Arrival" detail="Today, 19:24" isChecked />
					<Info data="Sensor Thresholds" detail="Compliant" isChecked />
				</Card>
				<Card title="Asset Information">
					<Info data="origin" detail="Facility Schaffhausen, CH" />
					<Info data="On shipment" detail="Pharma-0114" href="/" />
					<Info data="created" detail="10.09.2021 / 15:10" />
				</Card>
				<Card title="Device Information" hasTwoColumns>
					<Info data="Bee" detail="RCR20941" href="/" />
					<Info data="Bee type" detail="BeeBeacon" />
					<Info data="Battery" detail="83%" icon={<BatteryFull />} iconColor="#00A91C" />
					<Info data="Locked" detail="No" icon={<Unlocked />} iconColor="#B2B0BC" />
				</Card>
				<Card title="Device Communication">
					<Info data="last ping" detail="10.09.2021 / 16:40" />
					<Info data="reporting interval" detail="Every 15 minutes" />
					<Info data="Status" detail="Communicating" icon={<ConnectionSignal />} iconColor="#00A91C" />
					<Info data="battery Saving mode" detail="Off" />
					<Info data="Carrier" detail="T-Mobile" />
					<Info data="FLS" detail="Disabled" />
				</Card>
				<Card title="Location Information">
					<Info data="created" detail="31.10.2021" />
					<Info data="location type" detail="Warehouse" />
					<Info data="Part of location" detail="NIT-Basel-01" href="/" />
				</Card>
			</Container>
		</ThemeProvider>
	);
}
