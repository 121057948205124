import { ArrowDown, ArrowsVertical, ArrowUp } from "@carbon/icons-react";
import { ReactElement, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, IconContainer, Text } from "./styles";
import type { SortingProps } from "./types";

export default function Sorting({ className, title, status, onClick, ariaLabel }: SortingProps): JSX.Element {
	const [sort, setSort] = useState(status);

	const handleClick = (status): void => {
		if (status === "default") {
			status = "ascendant";
		} else if (status === "ascendant") {
			status = "descendent";
		} else {
			status = "default";
		}

		setSort(status);

		if (onClick) {
			onClick(status);
		}
	};

	let renderIcon: ReactElement;
	switch (sort) {
		case "ascendant":
			renderIcon = <ArrowUp size={12} />;
			break;
		case "descendent":
			renderIcon = <ArrowDown size={12} />;
			break;
		default:
			renderIcon = <ArrowsVertical size={12} />;
			break;
	}

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container
				className={className}
				onClick={() => handleClick(sort)}
				aria-label={ariaLabel}
				as="button"
				$status={sort}
			>
				<Text $bold> {title} </Text>
				<IconContainer>{renderIcon}</IconContainer>
			</Container>
		</ThemeProvider>
	);
}
