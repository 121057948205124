import { useCallback, useState } from "react";

export default function useLogic(): {
	showPassword: boolean;
	togglePasswordVisibility: () => void;
} {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const togglePasswordVisibility = useCallback(() => {
		setShowPassword(!showPassword);
	}, [setShowPassword, showPassword]);

	return { showPassword, togglePasswordVisibility };
}
