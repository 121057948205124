import DefaultTag from "../Tag";
import { Small } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { ChevronDown } from "@carbon/icons-react";
import { Checkbox, ListItemText, MenuItem, Select } from "@material-ui/core";
import styled, { css } from "styled-components";

import { SelectCheckboxesStyleProps } from "./types";

export const Container = styled.div`
	min-height: 6rem;
`;

export const RenderArea = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	padding-block-start: 0.125rem;
	padding-inline: 0.5rem 0.75rem;
`;

export const TagList = styled.ul`
	display: flex;
	flex: 1;
	gap: 0.5rem;
	overflow-x: hidden;
	font-family: ${({ theme }) => theme.fonts.main};
`;

export const Tag = styled(DefaultTag)`
	flex-shrink: 0;
	height: 1.5rem;
	> p {
		font-size: 0.75rem;
		line-height: 1.25rem;
	}
`;

export const EllipseTag = styled(DefaultTag)`
	width: 1.5625rem;
	height: 1.5rem;
`;

export const ClearAllButton = styled.button`
	z-index: 1301;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	color: ${({ theme }) => theme.colors.neutral900};
	background-color: ${({ theme }) => theme.colors.neutral100};
	border-radius: 0.25rem;
`;

export const Label = styled(Small)<SelectCheckboxesStyleProps>`
	display: inline-block;
	margin-block-end: 0.375rem;

	${({ theme, $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${theme.colors.neutral500};
		`}
`;

export const Chevron = styled(ChevronDown)``;

export const StyledCheckbox = styled(Checkbox)`
	&.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${({ theme }) => theme.colors.neutral900};
	}
	&.MuiCheckbox-box {
		border-color: ${({ theme }) => theme.colors.neutral900};
	}
`;
export const Text = styled(ListItemText)``;

export const StyledSelect = styled(Select)`
	width: 100%;
	height: 2.5rem;
	& .MuiTypography-body1 {
		font-family: ${({ theme }) => theme.fonts.main};
	}
	& .MuiSelect-select {
		&[aria-expanded="true"] {
			${Tag} {
				z-index: 1301;
			}
		}
	}
	&.MuiInputBase-root {
		color: ${({ theme }) => theme.colors.neutral600};
		font-size: 1rem;
		font-family: ${({ theme }) => theme.fonts.main};
		line-height: 1.5rem;
		border: 1px solid ${({ theme }) => theme.colors.neutral300};
		border-radius: 0.5rem;
		:hover,
		:focus {
			background-color: ${({ theme }) => theme.colors.neutral100};
		}
	}
	& .MuiPaper-root {
		max-height: 21.25rem;
		border: 1px solid ${({ theme }) => theme.colors.neutral200};
		border-radius: 0.75rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow24};
		margin-block-start: 0.5rem;
		${prettyScrollbar({})}
	}

	/* Chevron */

	& .MuiSelect-icon {
		width: 1.25rem;
		height: 1.25rem;
		color: inherit;
		margin-block-start: 0.1875rem;
		margin-inline-end: 0.625rem;
	}

	/* Focus */

	&.MuiInputBase-root.Mui-focused {
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.neutral900};
	}

	/* Disabled */

	& .MuiSelect-select.Mui-disabled {
		cursor: not-allowed;
	}
	&.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.colors.neutral400};
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	/* Overridden styles */

	& .MuiSelect-select:focus {
		background-color: transparent;
	}
	&.MuiInput-underline:before,
	&.MuiInput-underline:after,
	&.MuiInput-underline:hover:not(.Mui-disabled):before,
	&.MuiInput-underline:focus:not(.Mui-disabled):before {
		border-bottom: none;
	}
	&.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: none;
	}
`;

export const Option = styled(MenuItem)`
	&.MuiMenuItem-root {
		max-height: 2.5rem;
		font-size: 1rem;
		font-family: ${({ theme }) => theme.fonts.main};
		line-height: 1.5rem;
		background-color: ${({ theme }) => theme.colors.white};
		margin-block-end: 0.3125rem;
		padding-inline: 0.75rem;
		padding-block: 0.6875rem;
	}
	&.MuiMenuItem-root:hover,
	&.MuiMenuItem-root:focus,
	&.MuiListItem-root.Mui-focusVisible:hover,
	&.MuiListItem-root.Mui-focusVisible:focus {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}

	&.MuiMenuItem-root.Mui-selected {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
`;
