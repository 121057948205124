import DefaultMessage from "../Message";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Wrapper = styled.ul``;

export const Message = styled(DefaultMessage)`
	:not(:last-of-type) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
	}
	:last-of-type {
		border-radius: 0 0 0.75rem 0.75rem;
	}
	:first-of-type {
		padding-block-start: 24px;
	}
`;
