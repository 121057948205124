import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Container, Input, InputContainer, Label, MaxNumber, MinNumber, Value } from "./styles";
import { InputRangeProps } from "./types";

export default function InputRange({
	className,
	label,
	name,
	min,
	max,
	value,
	progressValue,
	onChange,
	hasInputField,
	...props
}: InputRangeProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className} {...props}>
				<Label as="label" htmlFor={name}>
					{label}
				</Label>
				<InputContainer>
					<MinNumber>{min}</MinNumber>
					<Input
						type="range"
						id={name}
						name={name}
						min={min}
						max={max}
						onChange={onChange}
						value={value}
						$progressValue={progressValue}
					/>
					<MaxNumber>{max}</MaxNumber>
					{hasInputField ? <Value>{value}</Value> : null}
				</InputContainer>
			</Container>
		</ThemeProvider>
	);
}
