import { Small } from "../../Typography";
import styled from "styled-components";

import { $StyledProps } from "./types";

export const Container = styled.div`
	width: 100%;
	min-width: 32rem;
	height: 19.75rem;
`;

export const TooltipWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	inset-inline-start: 1.5rem;
	inset-block-start: -1rem;
`;

export const SpecialTooltipWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	inset-inline-start: -10rem;
	inset-block-start: -1rem;
`;

export const Card = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
	width: 8.375rem;
	padding-inline: 0.5rem;
	padding-block: 0.5rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
`;

export const Value = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral900};
`;

export const Location = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Date = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Point = styled.div<$StyledProps>`
	position: absolute;
	width: 0.75rem;
	height: 0.75rem;
	background-color: ${({ $color }) => $color};
	border: 2px solid ${({ theme }) => theme.colors.white};
	border-radius: 50%;
	inset-inline-start: -0.375rem;
	inset-block-start: -0.375rem;
`;
