import { keysOf } from "../utils/keysOf";

export const sizes: { [index: string]: number } = {
	mobile: 360,
	tabletPortrait: 768,
	tabletLandscape: 900,
	laptop: 1200,
	desktop: 1440
};

const minWidthQuery = (width: number) => `@media (min-width: ${width}px)`;

export const from = keysOf(sizes).reduce(
	(
		acc: {
			[index: string]: string;
		},
		key: keyof typeof sizes
	): { [index: string]: string } => ({
		...acc,
		[key]: minWidthQuery(sizes[key] as number)
	}),
	{}
);
