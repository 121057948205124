import { Close } from "@carbon/icons-react";
import { Drawer } from "@material-ui/core";
import styled from "styled-components";
import { Title } from "../Typography";

const drawerWidth = 320;

export const Container = styled.div`
	position: relative;
	display: flex;
	width: fit-content;
`;
export const RightPanel = styled(Drawer)`
	width: ${drawerWidth}px;
	flex-shrink: 0;

	& .MuiDrawer-paper {
		width: ${drawerWidth}px;
		border-left: 0;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
`;
export const CloseIconWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 8px; // Adjust the padding as needed
`;
export const CloseButton = styled(Close)`
	cursor: pointer;
`;
export const ContentWrapper = styled.div`
	padding: 1rem;
`;
export const ContentTitle = styled(Title)`
	padding-block-end: 1.5rem;
`;
export const Content = styled.div``;
