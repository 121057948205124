import { Body, H4 } from "../../components/Typography";
import styled, { css } from "styled-components";

import { $StyledProps } from "./types";

export const Container = styled.div`
	padding-inline: 1rem;
	padding-block: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const Title = styled(Body)`
	padding-block-end: 1rem;
`;

export const RingWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CircleWrapper = styled.svg`
	display: block;
	width: auto;
	width: 6.5rem;
	height: 100%;
	height: 6.5rem;
	overflow: hidden;
	transform: rotate(-90deg);
`;

export const GreyCircle = styled.circle`
	stroke: ${({ theme }) => theme.colors.neutral200};
`;

export const Circle = styled.circle<$StyledProps>`
	transition: stroke-dashoffset 0.2s ease-in-out;
	stroke: ${({ theme }) => theme.colors.yellowBee200};
	stroke-linecap: round;

	${({ $battery }) => {
		switch ($battery) {
			case "half":
				return css`
					stroke: ${({ theme }) => theme.colors.yellowBee200};
				`;
			case "full":
				return css`
					stroke: ${({ theme }) => theme.colors.supportSuccess200};
				`;
			case "low":
			default:
				return css`
					stroke: ${({ theme }) => theme.colors.supportError200};
				`;
		}
	}}
`;

export const Percentage = styled(H4)`
	position: absolute;
	inset-block-start: 50%;
	transform: translateY(-50%);
`;
