import { Small } from "../Typography";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
`;

export const CircleWrapper = styled.svg`
	display: block;
	width: auto;
	width: 3rem;
	height: 100%;
	height: 3rem;
	overflow: hidden;
	transform: rotate(-90deg);
`;

export const GreyCircle = styled.circle`
	stroke: ${({ theme }) => theme.colors.neutral200};
`;

export const YellowCircle = styled.circle`
	stroke: ${({ theme }) => theme.colors.yellowBee200};
`;

export const Circle = styled.circle`
	transition: stroke-dashoffset 0.2s ease-in-out;
	stroke: ${({ theme }) => theme.colors.yellowBee200};
`;

export const Text = styled(Small)``;
