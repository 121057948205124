import Checkbox from "../../assets/icons/Checkbox.svg";
import CheckboxChecked from "../../assets/icons/CheckboxChecked.svg";
import CheckboxIndeterminated from "../../assets/icons/CheckboxIndeterminated.svg";
import { Close } from "@carbon/icons-react";
import type { PaperProps } from "@material-ui/core";
import { useCallback } from "react";

import useLogic from "./logic";
import {
	AutocompleteElement,
	CategoryText,
	Chevron,
	ClearAllButton,
	Container,
	Dropdown,
	EllipseTag,
	Input,
	Label,
	OptionWrapper,
	RenderArea,
	StyledCheckbox,
	Tag,
	TagList
} from "./styles";
import type { Option as OptionType, SelectCheckboxesCategoryProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

export default function SelectCheckboxesCategory({
	className,
	disabled,
	name,
	options,
	placeholder,
	label,
	id
}: SelectCheckboxesCategoryProps): JSX.Element {
	const { values, setValues, opened, setOpened, isCategoryChecked, shownTags, tagsListRef, clearAll } =
		useLogic(options);
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Label $bold as="label" htmlFor={name} $isDisabled={!!disabled}>
					{label}
				</Label>
				<AutocompleteElement
					onBlur={() => setOpened(false)}
					multiple
					disableClearable
					disableCloseOnSelect
					disabled={disabled}
					$isDisabled={disabled}
					id={id}
					popupIcon={<Chevron />}
					noOptionsText={"No options available"}
					value={values}
					open={opened && !disabled}
					onChange={(event, newValue) => {
						setValues(newValue as OptionType[]);
					}}
					getOptionSelected={(option, value) => (option as OptionType).value === (value as OptionType).value}
					renderInput={({ inputProps, ...otherParams }) => (
						<Input
							placeholder={values.length === 0 ? placeholder : ""}
							{...otherParams}
							inputProps={{ ...inputProps, readOnly: true }}
							onClick={() => setOpened(!opened)}
						/>
					)}
					renderTags={(tags) => (
						<RenderArea onClick={() => setOpened(!opened)}>
							<TagList ref={tagsListRef}>
								{(shownTags ? tags.slice(0, shownTags) : tags).map((tag) => (
									<Tag
										containerAs="li"
										key={(tag as OptionType).value}
										label={(tag as OptionType).label}
										hasCloseButton
										onClose={() => {
											const newTags = values.filter((val) => val.value !== (tag as OptionType).value);
											setValues(newTags);
										}}
									/>
								))}
								{shownTags < values.length ? <EllipseTag label="..." /> : null}
							</TagList>
							<ClearAllButton onClick={clearAll}>
								<Close />
							</ClearAllButton>
						</RenderArea>
					)}
					PaperComponent={useCallback(
						(props: PaperProps) => (
							<Dropdown>{props.children}</Dropdown>
						),
						[]
					)}
					options={options.sort((a, b) => -b.category.localeCompare(a.category))}
					renderOption={(option, state) => (
						<OptionWrapper>
							<StyledCheckbox
								icon={<Checkbox />}
								checkedIcon={<CheckboxChecked />}
								indeterminateIcon={<CheckboxIndeterminated />}
								checked={state.selected}
							/>
							{(option as OptionType).label}
						</OptionWrapper>
					)}
					groupBy={(option) => (option as OptionType).category}
					renderGroup={(group) => (
						<div>
							<OptionWrapper>
								<StyledCheckbox
									icon={<Checkbox />}
									checkedIcon={<CheckboxChecked />}
									indeterminateIcon={<CheckboxIndeterminated />}
									checked={isCategoryChecked(group.group, "all")}
									indeterminate={isCategoryChecked(group.group, "any")}
									onChange={() => {
										// If all the options in the category are selected, unselect them all
										if (isCategoryChecked(group.group, "all")) {
											setValues(values.filter((value) => value.category !== group.group));
											return;
										}
										// Else, select all the options in the category (even if some of them are already selected)
										let newValues = values;
										const categoryOptions = options.filter((option) => option.category === group.group);
										for (const option of categoryOptions) {
											if (!values.some((opt) => opt.value === option.value)) {
												newValues = [...newValues, option];
											}
										}
										setValues(newValues);
									}}
								/>
								<CategoryText $bold>{group.group}</CategoryText>
							</OptionWrapper>
							{group.children}
						</div>
					)}
					getOptionLabel={(option) => (option as OptionType).label}
				/>
			</Container>
		</ThemeProvider>
	);
}
