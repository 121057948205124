import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../../styles/global";
import AppThemes from "../../../styles/themes";
import { Container, DismissButton, Heading, IconContainer, Info, Title } from "./styles";
import type { AlertProps } from "./types";
export default function Alert({ className, alert }: AlertProps): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				<Heading>
					<Title $bold>{alert.title}</Title>
					<DismissButton label="Dismiss" />
					<IconContainer $iconColor={alert.iconColor} aria-hidden>
						{alert.icon}
					</IconContainer>
				</Heading>
				<Info>22 ºC (+ 2 ºC)</Info>
				<Info>Pharma-0114</Info>
				<Info>{alert.date}</Info>
			</Container>
		</ThemeProvider>
	);
}
