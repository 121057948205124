import IconButton from "../../IconButton";
import { Small } from "../../Typography";
import { visuallyHidden } from "../../../styles/mixins";
import styled, { css } from "styled-components";

import { AlertRowColorProps } from "./types";

export const ClearButton = styled(IconButton).attrs({
	variant: "no-bg",
	size: "field"
})``;

export const Container = styled.tr`
	width: 100%;
	height: 3rem;
	&:hover,
	&:focus-within {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	&:not(:hover) {
		${ClearButton} {
			&:not(:focus-visible) {
				${visuallyHidden}
			}
		}
	}
`;

export const Cell = styled.td`
	text-align: start;
	:nth-child(1) {
		width: 13.0625rem;
		padding-inline-start: 1rem;
	}
	:nth-child(2) {
		width: 9.0625rem;
		padding-inline-start: 1.5rem;
	}
	:nth-child(3) {
		width: 12.885rem;
		padding-inline-start: 1.5rem;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Text = styled(Small)`
	flex: 1;
`;

export const IconContainer = styled.div<AlertRowColorProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.25rem;
	height: 1.25rem;
	${({ $iconColor }) =>
		$iconColor &&
		css`
			color: ${$iconColor};
		`}
`;
