import { Body, Small } from "../../Typography";
import styled from "styled-components";

export const Container = styled.li`
	width: 100%;
	max-width: 23rem;
	padding-inline: 1rem;
	padding-block: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled(Body)``;

export const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-block-end: 0.5rem;
`;

export const Date = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Text = styled(Body)``;
