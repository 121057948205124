import IconButton from "../IconButton";
import { prettyScrollbar } from "../../styles/mixins";
import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DefaultAutocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";

import { InputSearchGlobalStyleProps } from "./types";

export const Container = styled.div`
	position: relative;
`;

export const Autocomplete = styled(DefaultAutocomplete)<InputSearchGlobalStyleProps>``;

export const Input = styled(TextField)`
	& .MuiInputBase-root {
		width: 100%;
		height: 2.5rem;
		color: ${({ theme }) => theme.colors.neutral900};
		line-height: 1.5rem;
		background-color: ${({ theme }) => theme.colors.white};
		border: 1px solid transparent;
		border-radius: 0.5rem;
		padding-inline: 2.625rem 0.625rem;

		::placeholder {
			color: ${({ theme }) => theme.colors.neutral600};
		}
	}

	& .MuiInputBase-input {
		font-family: ${({ theme }) => theme.fonts.main};
	}

	& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	/* Focus */

	& .MuiInputBase-root.Mui-focused {
		border-color: ${({ theme }) => theme.colors.neutral900};
		outline: none;
	}

	/* Disabled */

	&.MuiInputBase-root.Mui-disabled {
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-color: ${({ theme }) => theme.colors.neutral300};
		cursor: not-allowed;
		::placeholder {
			color: ${({ theme }) => theme.colors.neutral400};
		}
	}

	& .MuiAutocomplete-clearIndicator {
		position: relative;
		color: ${({ theme }) => theme.colors.neutral400};
		inset-inline-end: 0.5rem;
	}

	/* Overridden styles */

	& .MuiSelect-select:focus,
	& .MuiAutocomplete-clearIndicator:hover {
		background-color: transparent;
	}
	& .MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline:hover:not(.Mui-disabled):before,
	.MuiInput-underline:focus:not(.Mui-disabled):before,
	.MuiInput-underline.Mui-focused:before,
	.MuiInput-underline.Mui-disabled:before {
		border-bottom: none;
	}
	& .MuiAutocomplete-popupIndicator {
		display: none;
	}
`;

export const Dropdown = styled(Paper)`
	margin-block-start: 0.25rem;
	& .MuiAutocomplete-listbox {
		max-height: 15rem;
		${prettyScrollbar({})}
	}
	&.MuiPaper-root {
		padding-block: 0;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 0.5rem;
		box-shadow: ${({ theme }) => theme.shadows.shadow12};
	}
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	& .MuiAutocomplete-option[data-focus="true"] {
		background-color: ${({ theme }) => theme.colors.neutral100};
	}
	& .MuiAutocomplete-option {
		font-size: 1rem;
		line-height: 1.5rem;
		padding-block: 0.625rem;
		padding-inline: 0.75rem;
	}
	& .MuiAutocomplete-noOptions {
		text-align: center;
	}
	& .MuiListSubheader-root {
		margin-block-start: -1rem;
		margin-block-end: 0.625rem;
		height: 1.875rem;
		font-size: 0.75rem;
	}
	& .MuiAutocomplete-groupUl > .MuiAutocomplete-option {
		padding-inline-start: 1rem;
	}
	& .MuiAutocomplete-groupLabel {
		font-family: ${({ theme }) => theme.fonts.main};
		background-color: transparent;
	}
`;

export const RecentOptionContainer = styled.div`
	display: flex;
	gap: 0.75rem;
	align-items: center;
`;

export const IconWrapper = styled.div`
	position: absolute;
	inset-inline-start: 0.75rem;
	inset-block-start: 0.55rem;
	svg {
		width: 1.25rem;
		height: 1.25rem;
		color: ${({ theme }) => theme.colors.neutral600};
	}
`;

export const GoBackButton = styled(IconButton).attrs({
	variant: "no-bg",
	size: "tiny"
})`
	position: absolute;
	inset-inline-start: 0.625rem;
	inset-block-start: 0.5rem;
`;
