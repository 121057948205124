import { Body, Caption, Small } from "../Typography";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 35rem;
	min-height: 17.5rem;
	padding-block: 1rem;
	padding-inline: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 0.75rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
`;

export const ChartWrapper = styled.div`
	width: 100%;
	height: 12.5rem;
`;

export const Header = styled.div`
	margin-block-end: 1.5625rem;
`;

export const Title = styled(Body)`
	color: ${({ theme }) => theme.colors.neutral900};
`;

export const Description = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;

export const Legend = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ValueItem = styled(Caption)`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral600};
	margin-inline-end: 1rem;
`;

export const ColorLabel = styled.div`
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.25rem;
	margin-inline-end: 0.5rem;
`;

export const ButtonNoBg = styled.div`
	margin-inline-start: -0.75rem;
`;

export const Card = styled.div`
	min-width: 1rem;
	min-height: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.5rem;
	padding-inline: 0.5rem;
`;

export const Date = styled(Caption)``;

export const Value = styled(Small)``;

export const Bold = styled(Body).attrs({
	as: "span"
})`
	font-weight: bold;
`;

export const Highlight = styled(Body).attrs({
	as: "span"
})`
	color: ${({ theme }) => theme.colors.purpleSunset400};
`;

export const WrapperToolTip = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Point = styled.div`
	position: absolute;
	margin-block-start: 5.625rem;
	width: 0.625rem;
	height: 0.625rem;
	background-color: ${({ theme }) => theme.colors.purpleSunset400};
	border: 2px solid ${({ theme }) => theme.colors.white};
	border-radius: 0.625rem;
`;
