import { Small } from "../Typography";
import styled, { css } from "styled-components";

import { $BreadcrumbsLinkProps } from "./types";

export const BreadcrumbLink = styled(Small).attrs({
	as: "a"
})<$BreadcrumbsLinkProps>`
	display: inline-block;
	max-width: 10rem;
	overflow: hidden;
	color: ${({ $current, theme }) => ($current ? theme.colors.neutral900 : theme.colors.neutral600)};
	white-space: nowrap;
	text-decoration: none;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: 0.2s ease-in-out;
	&:hover {
		color: ${({ theme }) => theme.colors.neutral900};
		text-decoration: underline;
		text-underline-offset: 0.125rem;
	}
`;

export const Container = styled.div`
	display: flex;
	margin-top: -2.2rem;
	margin-bottom: 1rem;
`;

export const BreadcrumbItem = styled.div<$BreadcrumbsLinkProps>`
	display: flex;
	&::after {
		color: ${({ theme }) => theme.colors.neutral600};
		text-decoration: none;
		content: " / ";
		padding-inline: 0.5625rem;
		${({ $current }) => $current && `display: none;`}
	}
	${({ $current }) =>
		$current &&
		css`
			pointer-events: none;
		`}
`;
